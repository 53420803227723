import React from "react";
import "./confirmationpage.css";
import { Image } from "@fluentui/react-northstar";

const ConfirmationPage = (props) => {
  return (
    <div className="confirmationpage-container">
      <div className="confirmationpage-wrapper">
        <div className="confirmationpage-heading">{props?.heading}</div>
        <div className="confirmationpage-image">
          <Image alt="" class="image-content" src={props?.image} />
        </div>
        <div className="confirmationpage-content">{props?.content}</div>
        <div className="confirmationpage-content2">{props?.content2}</div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
