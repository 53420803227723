import { DefaultButton } from "@fluentui/react/lib/Button";
import "./button.css";

function Button(props) {
  return (
    <div className="teamsbtn" id={props?.id}>
      <DefaultButton text={props?.text} onClick={props.onClick} disabled={props.disabled} className={props?.className} />
    </div>
  );
}

export default Button;
