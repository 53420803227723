import React, { useEffect } from 'react';
import "./appsDetails.css";
import CommonChild from '../../../commonui/commonlayout/commonChild';
import { SingleTypeDataContainer } from '../../cardscontainer/singleTypeDataContainer';
import { BackButton } from '../../../commonui/backbutton/backButton';
import { useHamburgerMenuContext } from '../../../context/menucontext/menuContext';

function AppsDetails() {
    const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();

    let cardFetchObj = {
        all: {
            isFetching: false,
            hasMoreData: true,
            page: 2,
            pageLength: 6,
        },
        // Number of tiles per page
        contentType: 13,
    };

    useEffect(() => {
        window?.scroll(0, 0);
        if (!toggleHamburger && window.innerWidth > 900) {
            handleHamburgerChange();
        }
    }, []);
    return (
        <CommonChild id="/appsDetails" subMenuName="Apps" contentTypeId={13} appsDetails={true}>
            <BackButton
                allowRedirection={true}
                pathToBeRedirected={"/apps?loadFromCache=true&backButton=true"}
                isCloseClicked={true}
                className="close-btn appDetailsCloseBtn"
            />
            <SingleTypeDataContainer menuLevel={true} id="/appsDetails" name="AppsDetails" cardFetchObj={cardFetchObj} contentTypeId={13} appsDetails={true} />
        </CommonChild>
    );
}

export default AppsDetails;