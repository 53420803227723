import React, { useState, useEffect } from "react";
import { MdLink, MdMailOutline } from "react-icons/md";
import { checkIfDeviceIsMob } from "../../../utility/utility";
import Chat from "../../chat/chat";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import { app } from "@microsoft/teams-js";
import "./shareLink.css";

const ShareLink = (props) => {
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const [loggedInM365Disable, setloggedInM365Disable] = useState(false);

  useEffect(() => {
    app.getContext().then((context) => {
      if (
        context?.app?.host?.name === "Outlook" ||
        context?.app?.host?.name === "Office"
      ) {
        setloggedInM365Disable(true);
      }
    });
  }, []);

  return (
    <div className="startShare">
      <div className="actionBox">
        <div
          tabIndex={0}
          className="IconCircleContainer"
          onClick={props.showCopyLinkPopUpModal}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.showCopyLinkPopUpModal();
            }
          }}
        >
          <MdLink className="modalActionIcon" data-testid="copyLinkIcon" />
        </div>
        <span>Copy link</span>
      </div>
      {!userInfo.loggedInWeb && !loggedInM365Disable && (
        <div className="actionBox">
          <div
            tabIndex={0}
            className="IconCircleContainer"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.showSendLinkPopUpModal();
              }
            }}
            onClick={props.showSendLinkPopUpModal}
          >
            <MdMailOutline
              className="modalActionIcon"
              data-testid="sendLinkIcon"
            />
          </div>
          <span>Mail</span>
        </div>
      )}
      {!checkIfDeviceIsMob() && props?.config?.chat?.chatDisplay && (
        <>
          <Chat fromShareLink={true} {...props} />
        </>
      )}
      {/* <div className="actionBox disabled">
        <div className="IconCircleContainer">
          <MdMessage className="modalActionIcon" />
        </div>
        <span>Text Message</span>
      </div>  */}
    </div>
  );
};

export default ShareLink;
