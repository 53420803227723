import axios from './axiosJWTdecorator';
import { getBaseUrl } from '../utility/utility';

//TO-DO:uncomment all the url variable in each method when we get actual end point

export const  baseAxiosUrl = getBaseUrl() + '/api';

export const getData = async (endpoint: string): Promise<any> => {
    let url = baseAxiosUrl + endpoint;
    //TO-Do chnage the mock data
    // return await axios.mockAsyncTaxData();
    return await axios.get(url);
};

export const getDataById = async (id: string, endpoint: string): Promise<any> => {
    let url = baseAxiosUrl + endpoint + id;
    return await axios.get(url);
};

export const putData = async (payload: {}, endpoint: string, isMultiForm?: boolean): Promise<any> => {
    let url = baseAxiosUrl + endpoint;
    return await axios.put(url, payload, isMultiForm);
};

export const postData = async (payload: {}, endpoint: string, isMultiForm?: boolean, isRequestToken:boolean=true, shouldTimeout:boolean=false,needAuth:boolean = true): Promise<any> => {
    let url = baseAxiosUrl + endpoint;
    return await axios.post(url, payload, isMultiForm,isRequestToken,shouldTimeout,true,needAuth);
};

export const searchUsers = async (query: string, endpoint: string): Promise<[]> => {
    let url = baseAxiosUrl + endpoint + query;
    //TO-Do chnage the mock data
    // return await axios.mockAsync(query);
    return await axios.get(url);
};

export const deleteData = async (id: number, endpoint: string): Promise<any> => {
    let url = baseAxiosUrl + endpoint + id;
    return await axios.delete(url);
};

export const deleteDataByPayload = async (endpoint: string, payload: any): Promise<any> => {
    let url = baseAxiosUrl + endpoint;
    return await axios.delete(url, payload);
};

export const getDataWithoutAuth = async (endpoint: string): Promise<any> => {
    let url = baseAxiosUrl + endpoint;
    //TO-Do chnage the mock data
    // return await axios.mockAsyncTaxData();
    return await axios.get(url,true,false);
};
