//import React, { useEffect, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import Constant from "../utility/constants"
import { getInstrumetationKey } from '../utility/utility';
import { createBrowserHistory } from "history";

const  InstrumentationKey = getInstrumetationKey();
const browserHistory = createBrowserHistory();

var reactPlugin = new ReactPlugin();
// Add the Click Analytics plug-in.
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: InstrumentationKey,
        enableAutoRouteTracking: true,

        // If you're adding the Click Analytics plug-in, delete the next line.
        extensions: [reactPlugin],
        // Add the Click Analytics plug-in.
        // extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        //     // Add the Click Analytics plug-in.
        //     // [clickPluginInstance.identifier]: clickPluginConfig
         }
    }
});
appInsights.loadAppInsights();

// export function initUserData(customMetrics) {
//     appInsights.trackEvent({ name: "Init_User_requests" }, customMetrics)
// }
export function setTelemetryUserContext(userEmailId : string) {
    //console.log("telemetry Service setTelemetryUserContext called");
    appInsights.setAuthenticatedUserContext(userEmailId);
}

export function clearUserId() {
    appInsights.clearAuthenticatedUserContext();
}

export const TrackEvent = (eventName: string, defaultUserInfo:any, customProperties: {[key: string]: any}) => {
    //Mandatory function for Usage Statistics
    // let consolidateTelemetry = consolidateCustomDimensions(eventName, defaultUserInfo, customProperties);
     //console.log("Track event => event name: " + eventName + "=> Consolidated Data:  ", customProperties);
    // appInsights.trackEvent({ name: eventName, properties: consolidateTelemetry });
    appInsights.trackEvent({ name: eventName, properties: consolidateCustomDimensions(eventName, defaultUserInfo, customProperties) });
}

export const TrackPageView = (pageName: string, defaultUserInfo:any, customProperties: {[key: string]: any}, pageType?: string) => {
    //Mandatory function for Usage Statistics
    // let consolidateTelemetry = consolidateCustomDimensions(pageName, defaultUserInfo, customProperties);
    // console.log("Track Pageview => page name: " + pageName + "=> Consolidated Data: ", consolidateTelemetry);
    // appInsights.trackPageView({ name: pageName, properties: consolidateTelemetry });
    appInsights.trackPageView({ name: pageName, pageType: pageType, properties: consolidateCustomDimensions(pageName, defaultUserInfo, customProperties) });
}

function consolidateCustomDimensions(pageName: string, defaultUserInfo: any, customProperties: {[key: string]: any}) : {[key: string]: any} {
    let dafaultDimentions : {[key: string]: any} = 
    {
        "TrackingAppName": "CATALYST UI",
        "UserEmail": defaultUserInfo.email ?? "",
        "UserName": defaultUserInfo.displayName ?? "",
        "CompanyId": defaultUserInfo?.teams?.companyId ?? "",
        "CompanyName": defaultUserInfo?.teams?.companyName ?? "",
        "Ucc-feature-name": pageName,
    };
    return {...dafaultDimentions, ...customProperties};
}

export { reactPlugin, appInsights };