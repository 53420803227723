import React, { useState, createContext, useContext } from "react";

const notificationObject = {
  requestObj: {
    companyId: null,
    latest: null,
    unReadOnly: null,
    page: 1,
    pageSize: 5,
  },
  list: [],
  count: 0,
  isPanelOpen: false,
  isFullPageNotification: false,
  isFetching: false,
  fetchOnDelete: false,
  reloadTheNotification: false,
  errorApiCall: false,
  accessDenied: false
};
const notificationContext = createContext({
  notifications: notificationObject,
  handleNotifications: (obj) => { },
});

export function UseNotificationContext() {
  return useContext(notificationContext);
}

const NotificationContext = ({ children }) => {
  const [notifications, setNotifications] = useState(notificationObject);
  const handleNotifications = (obj) => {
    setNotifications((prevData) => {
      return {
        ...prevData,
        ...obj,
      };
    });
  };
  // useEffect(() => {
  //   handleNotifications({
  //     count: notifications.list.filter((i) => !i.wasRead).length,
  //   });
  // }, [notifications.list]);

  return (
    <notificationContext.Provider
      value={{ notifications, handleNotifications }}
    >
      {children}
    </notificationContext.Provider>
  );
};

export default NotificationContext;
