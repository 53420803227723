import React from 'react';
import { Box } from '@fluentui/react-northstar';

function PollsResult(props) {

  const PollResultOption = ({ label, option }) => {
    const percentage = option?.optionMetrics?.votePercent;
    const voteCount = option?.optionMetrics?.voteCount;
    const hasVoted = option?.optionMetrics?.isVoted;
    return (
      <Box styles={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className={`${hasVoted ? "has-voted-for" : ""}`} style={{ flex: 1 }}>
          <p style={{ padding: '0px 0px 4px 0px', fontSize:'0.8125rem', wordBreak:'break-all' }}>{label}</p>
          <div style={{ backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
            <div
              style={percentage === 0 ? {
                height: '7px',
                borderRadius: '4px',
                padding: '1px 1px 1px 10px',
                fontSize: '0.8125rem'
              } : {
                width: `${percentage}%`,
                height: '7px',
                backgroundColor: '#ffe600',
                borderRadius: '4px',
                padding: '1px 1px 1px 10px',
                fontSize: '0.8125rem'
              }} 
            ></div>
          </div>
        </div>
        <Box styles={{ marginLeft: '8px', minWidth:'39px', textAlign:'end', width:'55px', fontSize: '0.8125rem' }}>{`${percentage}%`} {`${"("+ voteCount + ")"}`}</Box>
      </Box>
    );
  };

  return (
    <>
      {
        <Box>
          {props?.pollOptions?.map((option, index) => {
            return (<PollResultOption key={index} label={option.label} option={option?.data} />)
          })}
        </Box>
      }
    </>
  )
};


export default PollsResult;
