import React from "react";
import { Label as FormLabel } from "@fluentui/react-northstar";
import "./Label.css";
import { CiCircleInfo } from "react-icons/ci";
function Label(props) {
  return (
    <div className="formLabelStyles">
      <label>{props?.content}</label>
      {props?.displayField && <label>{`: ${props?.displayText}`}</label>}
      {props?.checkIfRequired && <span className="mandatoryField">*</span>}
      {props?.checkForAdditionalInfo && <span ><CiCircleInfo className="additionalInfoIcon"/></span>}
      {props?.checkForAdditionalText && <span className={props?.className}>{props?.checkForAdditionalText}</span>}  
    
    </div>
  );
}

export default Label;
