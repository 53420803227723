import React from "react";
import "./comingSoon.css";

function ComingSoon() {
  return (
    <div className="commingsoon_container">
      <img src={require("../../assets/icon.png")} alt="" />
      <p className="commingsoon_containerHeader">Great things coming soon</p>
      <p className="commingsoon_containerSubtext">
        There are no records to display at the moment.
      </p>
    </div>
  );
}

export default ComingSoon;
