/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { postData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";

export const ReportsContext = React.createContext();

export function ReportsProvider(props) {
  const [openStepItems, setOpenStepItems] = useState(["1"]);
  const [checkedFirst, setCheckedFirst] = useState({ id: "0" });
  const [checkedReport, setCheckedReport] = useState({ id: "0" });
  const [checkedSecond, setCheckedSecond] = useState({ id: "0" });
  const [finalReports, setFinalReports] = useState([]);
  const [stepCeroSelectedItem, setStepCeroSelectedItem] = useState();
  const [stepOneSelectedItem, setStepOneSelectedItem] = useState();
  const [stepTwoSelectedItem, setStepTwoSelectedItem] = useState();
  const [stepOneSelectedVersion, setStepOneSelectedVersion] = useState();
  const [stepTwoSelectedVersion, setStepTwoSelectedVersion] = useState();
  const [allReportsIds, setAllReportsIds] = useState(["1"]);
  const [allFinalReports, setAllFinalReports] = useState([]);
  const [yearCarrousellItems, setYearCarrousellItems] = useState(undefined);
  const [selectedReportsType, setSelectedReportsType] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectedList, setSelectedValues] = useState([]);

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    if (!checkAll) {
      setSelectedReportsType(allReportsIds);
      setFinalReports(allFinalReports);
    } else {
      setSelectedReportsType([]);
      setFinalReports([]);
    }

    let err = errors.filter((v) => v !== 3);
    checkAll ? setErrors([3, ...errors]) : setErrors(err);
  };

  useEffect(() => {
    setCheckAll(allReportsIds.every((id) => selectedReportsType.includes(id)));
  }, [selectedReportsType]);


  const fetchYearCarrousellItems = async (fileType, companyId) => {
    await postData(
      {
        page: 0,
        pageSize: 0,
        filter: fileType,
      },
      APIEndPoints.EFILE_DETAILS(companyId)
    )
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          if (b.taxYear !== a.taxYear) {
            return b.taxYear - a.taxYear;
          } else {
            return b.returnVersion.localeCompare(a.returnVersion);
          }
        });
        setYearCarrousellItems(sortedData);
      })
      .catch((error) => console.log(error));
  };

  const resetContext = () => {
    setOpenStepItems(["1"]);
    setCheckedFirst({ id: "0" });
    setCheckedReport({ id: "0" });
    setCheckedSecond({ id: "0" });
    setFinalReports([]);
    setStepOneSelectedItem();
    setStepTwoSelectedItem();
    setStepOneSelectedVersion();
    setStepTwoSelectedVersion();
    setAllReportsIds(["1"]);
    setAllFinalReports([]);
    setYearCarrousellItems(undefined);
    setSelectedReportsType([]);
    setCheckAll(false);
    setErrors([]);
    setSelectedValues([]);
  };

  const cancelContext = () => {
    setOpenStepItems(["1"]);
    setCheckedFirst({ id: "0" });
    setCheckedReport({ id: "0" });
    setCheckedSecond({ id: "0" });
    setFinalReports([]);
    setStepOneSelectedItem();
    setStepTwoSelectedItem();
    setStepOneSelectedVersion();
    setStepTwoSelectedVersion();
    setSelectedReportsType([]);
    setCheckAll(false);
    setStepCeroSelectedItem(0);
    setErrors([]);
    setSelectedValues([]);
  };

  return (
    <ReportsContext.Provider
      value={{
        selectedReportsType,
        setSelectedReportsType,
        openStepItems,
        setOpenStepItems,
        checkedFirst,
        setCheckedFirst,
        checkedSecond,
        setCheckedSecond,
        checkedReport,
        setCheckedReport,
        checkAll,
        setCheckAll,
        handleCheckAll,
        fetchYearCarrousellItems,
        yearCarrousellItems,
        setAllReportsIds,
        finalReports,
        setFinalReports,
        setAllFinalReports,
        allFinalReports,
        stepCeroSelectedItem,
        setStepCeroSelectedItem,
        stepOneSelectedItem,
        setStepOneSelectedItem,
        stepTwoSelectedItem,
        setStepTwoSelectedItem,
        stepOneSelectedVersion,
        setStepOneSelectedVersion,
        stepTwoSelectedVersion,
        setStepTwoSelectedVersion,
        resetContext,
        cancelContext,
        errors,
        setErrors,
        setSelectedValues,
        selectedList
      }}
      {...props}
    />
  );
}
