import React, { useState, useEffect } from "react";
import APIEndPoints from "../../utility/apiendpoints";
import { deleteDataByPayload, postData } from "../../services/customApis";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import { GrClose } from "react-icons/gr";
import Constant from "../../utility/constants";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { UseMyShortcutsContext } from "../../context/myshortcutcontext/myShortcutsContext";

const MyShortcuts = (props) => {
  const [openDialog, setOpenDialog] = useState(true);
  const [loader, setLoader] = useState(false);
  const { myShortcutsData, handleMyShortcutsData } = UseMyShortcutsContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  const onClickDeleteMyShortcuts = async () => {
    setLoader(true);
    deleteDataByPayload(APIEndPoints.REMOVE_USER_SHORTCUTS(props?.shortcutsId))
      .then((response) => {
        setLoader(false);
        setOpenDialog(false);

        if (response.status === 200) {
          const updatedShortcuts = myShortcutsData.shortcutsList?.filter(
            (shortcut) => shortcut.userShortcutId !== props?.shortcutsId
          );
          handleMyShortcutsData({
            ...myShortcutsData,
            shortcutsList: updatedShortcuts,
          });
          setTimeout(() => {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: Constant.MY_SHORTCUTS_REMOVAL_MESSAGE(
                props?.subMenuItemName
              ),
              type: "Success",
              isFailed: false,
            });
          }, 1000);
        } else {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: response?.Errors[0],
            type: "Fail",
            isFailed: true,
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        setOpenDialog(false);
        let errorText = error?.response?.data?.Errors?.[0];
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: errorText
            ? errorText
            : Constant.MY_SHORTCUTS_REMOVE_FAILURE_MESSAGE,
          type: "fail",
          isFailed: false,
        });
      });
  };

  const onClickAddToShortcuts = async () => {
    setLoader(true);
    let obj = {
      subMenuItemId: props?.subMenuItemId,
    };
    postData(obj, APIEndPoints.ADD_USER_SHORTCUTS(props?.subMenuItemId))
      .then((response) => {
        setLoader(false);
        setOpenDialog(false);

        if (response.status === 200) {       

          let updatedShortcuts = [...myShortcutsData.shortcutsList];
          // updatedShortcuts.push([...myShortcutsData]);
          //let updatedShortcuts = [];
          updatedShortcuts.push({          
            
            subMenuItemId: response.data.subMenuItemId,
            subMenuItemName:
              response.data.subMenuItemName !== null
                ? response.data.subMenuItemName
                : props?.subMenuItemName,
            userProfileId: response.data.userProfileId,
            userShortcutId: response.data.userShortcutId

          });
          handleMyShortcutsData({
            ...myShortcutsData,
            shortcutsList: updatedShortcuts,
          });
          setTimeout(() => {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.MY_SHORTCUTS_SUCCESS_MESSAGE(props?.subMenuItemName),
            type: "Success",
            isFailed: false,
          });
        }, 1000);
        } else {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: response?.Errors[0],
            type: "Fail",
            isFailed: true,
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        setOpenDialog(false);
        let errorText = error?.response?.data?.Errors?.[0];
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: errorText
            ? errorText
            : Constant.MY_SHORTCUTS_ADD_FAILURE_MESSAGE,
          type: "fail",
          isFailed: false,
        });
      });
  };
//console.log("MyShortcuts myShortcutsData", myShortcutsData)
  const handleCancel = () => {
    setOpenDialog(false);
    props.setIsMyShortcutsOpen(!props.isMyShortcutsOpen);
  };

  const handleConfirm = () =>
    props.isAddToShortcuts
      ? onClickAddToShortcuts()
      : props.isRemoveFromShortcuts && onClickDeleteMyShortcuts();

  const dialogContent = (
    <p>
      Do you want to {props.isAddToShortcuts ? "add" : "remove"}{" "}
      {props.subMenuItemName} page {props.isAddToShortcuts ? "to" : "from"} My
      Shortcuts?
    </p>
  );

  return (
    <>
      <DialogBox
        cancelButton={"Cancel"}
        onCancelMethod={handleCancel}
        closeOnOutsideClick={false}
        confirmButton={props?.isAddToShortcuts ? "Add" : "Remove"}
        onConfirmMethod={handleConfirm}
        content={
          loader === true ? (
            <div className={loader && "loaderLayover"}>
              <ReusableLoader
                isOverlay={true}
                label={dialogContent}
                labelPosition={"start"}
              />
            </div>
          ) : (
            dialogContent
          )
        }
        headerName={
          props?.isAddToShortcuts ? "Add Shortcut" : "Remove Shortcut"
        }
        headerActionName={{
          icon: <GrClose />,
          title: "Close",
          onClick: handleCancel,
        }}
        backdrop={true}
        openDialog={openDialog}
        className={`dialogDeleteStyles pinnedContent ${
          loader && "dialogLayover"
        }`}
      />
    </>
  );
};
export default MyShortcuts;
