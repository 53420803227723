import React from "react";
import uuid from "react-uuid";

// JSON format to understand the parent children relation

// [
//   {
//     section {
//       [
//         menu{
//           submenu:[]
//         }
//       ]
//     }

//   }
// ]

const sideNavSettingsData = [
  {
    //section
    id: uuid(),
    title: "",
    divider: false,
    menu: [
      {
          id: uuid(),
    
          title: "Profile",
          badge: "",
          to: "/settings",
      },
      {
        id: uuid(),
    
        title: "My Experience",
        badge: "",
        to: "/settings/myexperience",
      },
      {
        id: uuid(),
    
        title: "Subscription",
        badge: "",
        to: "/settings/subscription",
      },
      {
        id: uuid(),
    
        title: "Billing Details",
        badge: "",
        to: "/settings/billingdetails",
      },
      {
        id: uuid(),
    
        title: "User Groups",
        badge: "",
        to: "/settings/customusergroups",
      }
    ]
  }
]

export default sideNavSettingsData;
