import React, { useEffect } from "react";
import ComingSoon from "../../commonui/comminsoon/comingSoon";
import CommonChild from "../../commonui/commonlayout/commonChild";
import { SingleTypeDataContainer } from "../cardscontainer/singleTypeDataContainer";
import { useHamburgerMenuContext, useMenuContext } from "../../context/menucontext/menuContext";
import sideNavMainMenuData from "../../data/menu/sideNavMainMenuData";

function Apps() {
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const { handleChangeNav, menuState } = useMenuContext();
  const setAppsBreadCrumbValue = () => {
    menuState?.menu?.name == "Apps" && handleChangeNav({
      section: {
        id: sideNavMainMenuData[3]?.id,
        name: "",
      },
      menu: {
        id: sideNavMainMenuData[3]?.menu[0]?.id,
        name: "Apps",
      },
      submenu: {
        id: "",
        name: "",
      },
      data: "",
      to: "/apps",
    });
  };

  let cardFetchObj = {
    all: {
      isFetching: false,
      hasMoreData: true,
      page: 2,
      pageLength: 6,
    },
    // Number of tiles per page
    contentType: 13,
  };
  useEffect(() => {
    setAppsBreadCrumbValue();
    window.history.pushState('Apps', 'Apps', '/apps');
    window?.scroll(0, 0);    
    if (toggleHamburger) {      
      handleHamburgerChange();
    }

  }, []);
  return (
    <CommonChild id="/apps" subMenuName="Apps" contentTypeId={13} subMenuItemId={26}>
      <SingleTypeDataContainer menuLevel={true} id="/apps" name="Apps" cardFetchObj={cardFetchObj} contentTypeId={13} subMenuItemId={26}/>
    </CommonChild>
  );
}

export default Apps;
