import React, { useState, createContext, useContext } from "react";

const popupDataContext = createContext({
  formInput: {},
  handleFormDataIuput: (e) => {},
});

export const FormContext = ({ children }) => {
  const [formInput, setFormInput] = useState({});

  const handleFormDataIuput = (obj) => {
    if (Object.entries(obj).length !== 0) {
      // let userDetails = {
      //   sender: {
      //     userId: userInfo.email,
      //   },
      // };
      let updatedObj = {};
      if (typeof obj[Object.keys(obj)[0]] === "object") {
        let keyName = Object.keys(obj)[0];
        updatedObj = {
          ...formInput,
          [keyName]: {
            ...formInput[keyName],
            ...obj[keyName],
          },
        };
      } else {
        updatedObj = { ...formInput, ...obj };
      }

      let finalObj = { ...updatedObj };
      setFormInput(finalObj);
    } else {
      setFormInput({});
    }
  };

  return (
    <popupDataContext.Provider value={{ formInput, handleFormDataIuput }}>
      {children}
    </popupDataContext.Provider>
  );
};

export function UseformDataContext() {
  return useContext(popupDataContext);
}
