import { useState, useRef, useEffect } from "react";
import React from "react";
import "./showMoreLessText.css";
import { useHamburgerMenuContext } from "../../context/menucontext/menuContext";

const ShowMoreLessText = (props) => {
  const { children } = props;
  const [largText, setLargText] = useState(false);
  const [toggleButton, setToggleButton] = useState(false);
  const [additionalClass, setAdditionalClass] = useState("");
  const { toggleHamburger } = useHamburgerMenuContext();

  const triggerRef = useRef(null);

  const getSizeOfText = () => {
    if (triggerRef.current) {
      if (!props.lineNumber) {
        if (
          triggerRef.current.scrollHeight != triggerRef.current.clientHeight
        ) {
          setLargText(
            triggerRef.current.scrollHeight > triggerRef.current.clientHeight
          );
          setAdditionalClass("additionalClass");
        } else {
          // setLargText(
          //   triggerRef.current.scrollHeight == triggerRef.current.clientHeight
          // );      
          setAdditionalClass("");    
        }
      } else if (props.lineNumber) {
        setLargText(
          triggerRef.current.scrollHeight - 1 > triggerRef.current.clientHeight
        );
      }
    }
  };

  useEffect(() => {
    getSizeOfText();

    const handleResize = () => {
      getSizeOfText();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getSizeOfText();
    }, 1000);
  }, [toggleHamburger]);

  const toggleText = () => {
    setToggleButton((prevState) => !prevState);
  };

  return (
    <>
      <div>
        {React.cloneElement(children, {
          ref: triggerRef,
          className: `comment-two-line-text ${
            largText ? (toggleButton ? "showMoreClass" : "showLessClass") : ""
          } ${additionalClass != "" ? additionalClass : ""} `,
        })}
        {largText && (
          <>
            <hr className="comment-divider" />
            {toggleButton ? (
              <div className="showMoreLessText" onClick={toggleText}>
                Show less
              </div>
            ) : (
              <div className="showMoreLessText" onClick={toggleText}>
                Show more
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ShowMoreLessText;
