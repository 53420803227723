import React, { useState, useEffect } from "react";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import Menu from "./menu/menu";
import "./sideBar.css";

function Section({ section }) {
  const [showSection, setShowSection] = useState(false);
  const { userInfo } = UseUserInfoContext();

  useEffect(() => {
    let count = 0;
    section?.menu?.map((menu) => {
      if (menu?.submenu?.length > 0) {
        menu?.submenu.map((submenu) => {
          if (userInfo?.teams?.featureFlagsObj[submenu?.title]?.isEnabled) {
            count++;
          };
        });
      }
      else {
        if (userInfo?.teams?.featureFlagsObj[menu?.title]?.isEnabled) {
          count++;
        };
      }
    });
    setShowSection(count > 0);

  }, [userInfo?.teams?.featureFlagsObj]);
  return (
    <>
      {showSection && section?.title?.length > 0 && (
        <div className="menu_section">
          {section?.title && (
            <div className="menu_section__name">
              {section?.title.toUpperCase()}
            </div>
          )}
          {section?.divider !== false && (
            <div>
              <hr className="hr" />
            </div>
          )}
        </div>
      )}
      <div className="menu_component">
        {section?.menu?.map((menu) => {
          return (
            <Menu
              section={section}
              key={menu.id}
              menu={menu}
              setShowSection={setShowSection}
            />
          );
        })}
      </div>
    </>
  );
}

export default Section;
