import React, { useState, createContext, useContext } from "react";
// contentPaginateConfigContexnt.jsx
const contentPaginateConfigContextObj = {
  limit: 1,
};
const contentPaginateConfigContext = createContext({
  contentPaginateConfig: contentPaginateConfigContextObj,
  handleContentPaginateConfig: (obj) => {},
});

export function UseContentPaginateConfigContext() {
  return useContext(contentPaginateConfigContext);
}

const ContentPaginateConfigContext = ({ children }) => {
  const [contentPaginateConfig, setContentPaginateConfig] = useState(
    contentPaginateConfigContextObj
  );
  const handleContentPaginateConfig = (obj) => {
    setContentPaginateConfig((prevData) => {
      return {
        ...prevData,
        ...obj,
      };
    });
  };

  return (
    <contentPaginateConfigContext.Provider
      value={{ contentPaginateConfig, handleContentPaginateConfig }}
    >
      {children}
    </contentPaginateConfigContext.Provider>
  );
};

export default ContentPaginateConfigContext;
