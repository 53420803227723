import React from "react";
import CommonChild from "../../commonui/commonlayout/commonChild";
import CommonLayout from "../../commonui/commonlayout/commonLayout";
import { MultiTypeDataContainer } from "../cardscontainer/multiTypeDataContainer";

function Events() {
  let cardFetchObj = {
    new: {
      isFetching: false,
      hasMoreData: true,
      page: 2,
      subContentType: "New",
      config: [],
      pageLength: 6,
      subContentTypeDisplayName: "",
    },
    topprevious: {
      isFetching: false,
      hasMoreData: true,
      page: 2,
      subContentType: "Top Previous",
      config: [],
      pageLength: 6,
      subContentTypeDisplayName: "",
    },
  };

  return (
    <CommonLayout>
      <CommonChild id="/conferences" subMenuName="Conferences" contentTypeId={5} subMenuItemId={5}>
        <MultiTypeDataContainer id="/conferences" name="Conferences" cardFetchObj = {cardFetchObj} contentTypeId={5} subMenuItemId={5}/>
      </CommonChild>
      <CommonChild id="/webcasts" subMenuName="Webcasts" contentTypeId={6} subMenuItemId={6}>
        <MultiTypeDataContainer id='/webcasts' name='Webcasts' cardFetchObj = {cardFetchObj} contentTypeId={6} subMenuItemId={6}/>
      </CommonChild>
      <CommonChild id="/podcasts" subMenuName="Podcasts" contentTypeId={10} subMenuItemId={7}>
        <MultiTypeDataContainer id="/podcasts" name="Podcasts" cardFetchObj = {cardFetchObj} contentTypeId={10} subMenuItemId={7}/>
      </CommonChild>
    </CommonLayout>
  );
}

export default Events;
