import React from "react";
import MenuContext from "./menucontext/menuContext";
import { TaxAlertProvider } from "./taxalerts/taxAlertsContext";
import { ReportsProvider } from "./reports/reportsContext";
import UserContext from "./usercontext/userContext";
import BreadCrumbProvider from "./breadcrumbcontext/BreadCrumbContext";
import NotificationContext from "./notificationcontext/notificationContext";
import ToasterNotificationsContext from "./toasternotificationscontext/toasterNotificationsContext";
import { PinLibraryProvider } from "./pinlibrarycontext/pinLibraryContext";
import ContentCurationContext from "./contentcurationcontext/contentCurationContext";
import ContentPaginateConfigContext from "./contentpaginateconfigcontext/contentPaginateConfigContext";
// import ContentPaginateConfigContext from "./contentpaginateconfigcontext/ContentPaginateConfigContext";
import ContactUsContext from "./contactuscontext/contactUsContext";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/appInsights";
import UserManagementContext from "./usermanagementcontext/userManagementContext";
import ChannelManagementContext from "./channelmanagementcontext/channelManagementContext";
import { TooltipProvider } from "./tooltipcontext/tooltipContext";
import  MyShortcutsContext  from "./myshortcutcontext/myShortcutsContext";
import LibrarySearchFormContext from "./librarysearchformcontext/librarySearchFormContext";
import SearchAndFilterColumnContext from "./searchandfiltercolumncontext/searchAndFilterColumnContext";
const ContextProvider = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <TooltipProvider>
        <UserManagementContext>
          <ChannelManagementContext>
          <BreadCrumbProvider>
            <NotificationContext>
              <ToasterNotificationsContext>
                <ContentCurationContext>
                  <ContentPaginateConfigContext>
                    <ReportsProvider>
                      <PinLibraryProvider>
                        <UserContext>
                          <ContactUsContext>
                          <MyShortcutsContext>
                          <LibrarySearchFormContext>
                          <SearchAndFilterColumnContext>
                          <MenuContext>{children}</MenuContext>
                          </SearchAndFilterColumnContext>
                          </LibrarySearchFormContext>
                            </MyShortcutsContext>
                        </ContactUsContext>
                        </UserContext>
                      </PinLibraryProvider>
                    </ReportsProvider>
                  </ContentPaginateConfigContext>
                </ContentCurationContext>
              </ToasterNotificationsContext>
            </NotificationContext>
          </BreadCrumbProvider>
          </ChannelManagementContext>
        </UserManagementContext>
      </TooltipProvider>
    </AppInsightsContext.Provider>
  );
};

export default withAITracking(reactPlugin, ContextProvider);
