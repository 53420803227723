import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { app, authentication } from "@microsoft/teams-js";
import { getData } from "./customApis";
import APIEndPoints from "../utility/apiendpoints";
import { createRoot } from "react-dom/client";
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import ResponseStatusHandler from "../commonui/responseErrorhandler/responseStatusHandler";
import ConfirmationPage from "../commonui/confirmationpage/confirmationpage";
import * as Instance from "../index";
import Constant from "../utility/constants";

export class AxiosJWTDecorator {
  private static loggedInIndex = -1;
  public async get<T = any, R = AxiosResponse<T>>(
    url: string,
    handleError: boolean = true,
    // needAuthorizationHeader: boolean = true, we have made it false for local api calls
    needAuthorizationHeader: boolean = true,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const requestFunc = async (): Promise<R> => {
      if (needAuthorizationHeader) {
        config = await this.setupAuthorizationHeader(config, false, false, url);
      }
      return await axios.get(url, config);
    };
    try {
      return await this.retryRequest(requestFunc);
    } catch (error) {
      if (handleError) {
        this.handleError(error);
        throw error;
      } else {
        throw error;
      }
    }
  }

  public async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    payload?: any,
    handleError: boolean = true,
    config?: AxiosRequestConfig
  ): Promise<R> {
    try {
      config = await this.setupAuthorizationHeader(config, true, false, url);
      if (payload) {
        config.data = payload;
      }
      return await axios.delete(url, config);
    } catch (error) {
      if (handleError) {
        this.handleError(error);
        throw error;
      } else {
        throw error;
      }
    }
  }

  public async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    isMultiForm?: boolean,
    isRequestToken: boolean = true,
    shouldTimeout: boolean = false,
    handleError: boolean = true,
    needAuthorizationHeader: boolean = true,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const requestFunc = async (): Promise<R> => {
      if (needAuthorizationHeader) {
        config = await this.setupAuthorizationHeader(
          config,
          isRequestToken,
          // byPassUrlArr.includes(url) ? false : true,
          isMultiForm,
          url,
          needAuthorizationHeader
        );
      }
      // Set timeout if shouldTimeout is true
      if (shouldTimeout && config) {
        config["timeout"] = Constant.PROMPTTIMEOUT; // 45 seconds
      }
      return await axios.post(url, data, config);
    };

    try {
      return await this.retryRequest(requestFunc);
    } catch (error) {
      if (handleError) {
        this.handleError(error);
        throw error;
      } else {
        throw error;
      }
    }
  }

  public async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    isMultiForm?: boolean,
    isRequestToken: boolean = true,
    handleError: boolean = true,
    needAuthorizationHeader: boolean = true,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const requestFunc = async (): Promise<R> => {
      if (needAuthorizationHeader) {
        config = await this.setupAuthorizationHeader(
          config,
          isRequestToken,
          // byPassUrlArr.includes(url) ? false : true,
          isMultiForm,
          url
        );
      }
      return await axios.put(url, data, config);
    };

    try {
      return await this.retryRequest(requestFunc);
    } catch (error) {
      if (handleError) {
        this.handleError(error);
        throw error;
      } else {
        throw error;
      }
    }
  }

  public handleError(error: any): void {
    try {
      if (error.hasOwnProperty("response")) {
        const errorStatus = error.response.status;
        console.log({ error, errorStatus });

        if (errorStatus === 412) {
          if (
            error?.response?.data?.Errors[0].toLowerCase() ===
            "application deployment"
          ) {
            if (typeof localStorage !== "undefined") {
              localStorage.setItem("downtime", "true");
            }
            if (
              typeof window !== "undefined" &&
              window.location.href.indexOf("downtime") === -1
            ) {
              window.location.href = "/downtime";
            }
          } else {
            const rootElement = document.getElementById("commonError");
            if (rootElement) {
              const root = createRoot(rootElement);
              root.render(
                <Provider theme={teamsTheme}>
                  <ResponseStatusHandler />
                </Provider>
              );
            } else {
              console.error("Root element for common error not found");
            }
          }
        } else if (errorStatus === 401) {
          if (error?.response?.data?.Message?.indexOf("Token") > -1) {
            if (typeof localStorage !== "undefined") {
              localStorage.removeItem("EyToken");
            }
            this.refreshTokenAndReload();
          } else {
            throw error;
          }
        } else if (errorStatus === 419 || errorStatus === 410) {
          if (typeof localStorage !== "undefined") {
            localStorage.removeItem("EyToken");
          }
          this.refreshTokenAndReload();
        } else {
          throw error;
        }
      }
    } catch (err) {
      console.log("some error in handle error method", err);
    }
  }

  private refreshTokenAndReload(): void {
    const instance = Instance.msalInstance;
    if (
      instance?.getActiveAccount() &&
      instance?.getAllAccounts()?.length > 0
    ) {
      const keyToKeep = "curUserSelectedCompany";
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (key !== keyToKeep) {
          localStorage.removeItem(key);
        }
      });
      instance.logoutRedirect();
      AxiosJWTDecorator.loggedInIndex++;
    } else {
      if (AxiosJWTDecorator.loggedInIndex === -1) {
        window.location.replace(window.location.origin);
      }
    }
  }

  public async acquireMSALToken() {
    const instance = Instance.msalInstance;
    const accounts = instance.getAllAccounts();

    if (AxiosJWTDecorator.loggedInIndex === -1) {
      try {
        const response = await instance.acquireTokenSilent({
          account: accounts[0],
          scopes: [Constant.GetScope()],
        });
        return response.accessToken;
      } catch (error) {
        const response = await instance.acquireTokenRedirect({
          scopes: [Constant.GetScope()],
        });
        return response.accessToken;
      }
    }
  }

  private async retryRequest<T>(
    requestFunc: () => Promise<T>,
    retries: number = 4,
    delay: number = 1000
  ): Promise<T> {
    try {
      return await requestFunc();
    } catch (error: any) {
      if (error?.hasOwnProperty("response")) {
        const errorStatus = error.response.status;
        if (errorStatus === 401) {
          if (error?.response?.data === "") {
            if (retries > 0) {
              // console.log(`Retrying... ${retries} attempts left`);
              await new Promise((resolve) => setTimeout(resolve, delay));
              return this.retryRequest(requestFunc, retries - 1, delay);
            } else {
              throw error;
            }
          } else throw error;
        } else throw error;
      } else {
        throw error;
      }
    }
  }

  private async setupAuthorizationHeader(
    config?: any,
    isRequestToken = false,
    isMultiForm?: boolean,
    url?: string,
    needAuthorizationHeader: boolean = true
  ): Promise<AxiosRequestConfig> {
    //Uncomment the below app.init method
    app.initialize();
    let _requestToken = { data: { requestToken: "", requestId: "" } };
    if (isRequestToken) {
      _requestToken = await getData(APIEndPoints.REQUEST_TOKEN);
    }

    return new Promise<AxiosRequestConfig>(async (resolve, reject) => {
      if (!app.isInitialized()) {
        let acc_token = "";
        let newObj = {
          headers: {
            common: {
              Authorization: "",
            },
          },
        };
        if (needAuthorizationHeader) {
          let token = await this.acquireMSALToken();
          acc_token = this.getToken(token, url);
          newObj["headers"]["common"]["Authorization"] =
            "Bearer " + acc_token + "";
        }

        if (isRequestToken) {
          newObj["headers"]["X-RequestToken"] =
            _requestToken.data.requestToken +
            "_" +
            _requestToken.data.requestId;
        }
        if (isMultiForm) {
          newObj["headers"]["Content-Type"] = "multipart/form-data";
        } else {
          if (newObj["headers"]["Content-Type"]) {
            delete newObj["headers"]["Content-Type"];
          }
          newObj["headers"]["Content-Type"] = "application/json";
        }
        config = newObj;
        resolve(config);
      }
      //uncomment line 163- 177 as this is production code that needs to be deployed
      else {
        const authTokenRequest = {
          successCallback: (token: string) => {
            let newToken = this.getToken(token, url);
            if (!config) {
              config = axios.defaults;
            }
            config["headers"]["common"]["Authorization"] = `Bearer ${newToken}`;
            if (isMultiForm) {
              config["headers"]["Content-Type"] = "multipart/form-data";
            } else {
              if (config["headers"]["Content-Type"]) {
                delete config["headers"]["Content-Type"];
              }
              config["headers"]["Content-Type"] = "application/json";
            }

            if (isRequestToken) {
              config["headers"]["X-RequestToken"] =
                _requestToken.data.requestToken +
                "_" +
                _requestToken.data.requestId;
            } else {
              if (config["headers"]["X-RequestToken"])
                delete config["headers"]["X-RequestToken"];
            }

            resolve(config);
          },
          failureCallback: (error: string) => {
            if (error === "unknownAuthError") {
              let appName = "";
              if (
                window.location.origin.indexOf("https://catalyst-uat.ey.com") >
                -1
              ) {
                appName = "EY Catalyst Connect (Beta)";
              } else if (
                window.location.origin.indexOf("https://catalyst.ey.com") > -1
              ) {
                appName = "EY Catalyst Connect";
              }

              const rootElement = document.getElementById("root")!;
              const root = createRoot(rootElement);
              root.render(
                <Provider theme={teamsTheme}>
                  <ConfirmationPage
                    heading="Oops..."
                    image={require("../assets/unauthorizedAccess.PNG")}
                    content={
                      "You need 'access_as_user' permission on your " +
                      appName +
                      " application. Please contact an administrator to grant admin consent. For further assistance please reach out to us at eytaxcatalystsupport@ey.com"
                    }
                  />
                </Provider>
              );
            } else if (error === "CancelledByUser" || error === "cancelled") {
              const rootElement = document.getElementById("root")!;
              const root = createRoot(rootElement);
              root.render(
                <Provider theme={teamsTheme}>
                  <ConfirmationPage
                    heading="Oops..."
                    image={require("../assets/unauthorizedAccess.PNG")}
                    content="We did not receive your consent to proceed with application usage. For further assistance please reach out to us at eytaxcatalystsupport@ey.com"
                  />
                </Provider>
              );
            } else {
              const rootElement = document.getElementById("root")!;
              const root = createRoot(rootElement);
              root.render(
                <Provider theme={teamsTheme}>
                  <ConfirmationPage
                    heading="Oops..."
                    image={require("../assets/unauthorizedAccess.PNG")}
                    content="We did not receive your consent to proceed with application usage. For further assistance please reach out to us at eytaxcatalystsupport@ey.com"
                  />
                </Provider>
              );
            }
            console.error("Error from getAuthToken: ", error);
          },
          resources: [],
        };
        authentication.getAuthToken(authTokenRequest);
      }
    });
  }

  private getToken(msalToken, currentUrl): string {
    const urlListsToBeExcluded = ["/token"];

    // Check if the current URL contains any of the URLs in urlListsToBeExcluded
    if (
      urlListsToBeExcluded.some((url) =>
        currentUrl?.toLowerCase().endsWith(url?.toLowerCase())
      )
    ) {
      // If it does, return msalToken;before retrurning save it in local storage
      localStorage.setItem("msal.teamsIdToken", msalToken);
      return msalToken;
    } else {
      // Otherwise, get the EYToken from local storage and return it
      const EYToken = localStorage.getItem("EyToken")!;
      return EYToken;
    }
  }
}
const axiosJWTDecoratorInstance = new AxiosJWTDecorator();
export default axiosJWTDecoratorInstance;
