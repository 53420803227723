import React, { useEffect, useState } from "react";
import "./pageNotFound.css";
import turtleImage from "../../assets/pagenotfoundturtle.png";

function PageNotFound() {
  const [isDowntime, setIsDowntime] = useState(false);

  useEffect(() => {
    const downtime = localStorage.getItem("downtime");
    setIsDowntime(downtime === "true");
  }, []);

  const refreshPage = () => {
    window.location.reload(false);
  };

  if (isDowntime) {
    return null;
  } else {
    return (
      <div className="pagenotfound_container">
        <img src={turtleImage} alt="Page Not Found" />
        <p className="pagenotfound_containerHeader">Something went wrong</p>
        <p className="pagenotfound_containerSubtext">
          Your content can't be shown right now. Try refreshing this page or
          start over if that
          <br />
          doesn't do the trick.
        </p>
        <button onClick={refreshPage} className="button_pagenotfound">
          Refresh
        </button>
      </div>
    );
  }
}

export default PageNotFound;
