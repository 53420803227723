import React, { useState, createContext, useContext, useEffect } from "react";
import sideNavMainMenuData from "../../data/menu/sideNavMainMenuData";
import sideNavAboutData from "../../data/menu/sideNavAboutData";
import sideNavSettingsData from "../../data/menu/sideNavSettingsData";
// import * as fs from "fs";

//create contexts

const hamburgerMenuContext = createContext({
  toggleHamburger: false,
  handleHamburgerChange: () => {},
});

const sideNavbarDataContext = createContext({
  sideNavbarData: sideNavMainMenuData,
  handleSideNavbarDataChange: (name) => {},
});

export const menuContext = createContext({
  menuState: {
    section: { id: "", name: "" },
    menu: { id: "", name: "" },
    submenu: { id: "", name: "" },
    data: "",
    to: "",
    currentTab: "",
  },

  handleChangeNav: (obj) => {},
});

//context provider and states
const MenuContext = ({ children }) => {
  // hamburgerMenuContext state
  const path = window.location.pathname.split("/")[1].toLowerCase();

  const [toggleHamburger, setToggleHamburger] = useState(
    window.innerWidth > 900 //&& path !== "search"
  );

  const [sideNavbarData, setSideNavbarData] = useState(sideNavMainMenuData);

  //sidenav menu state
  const [menu, setMenu] = useState({
    section: {
      id: sideNavMainMenuData[0].id,
      name: sideNavMainMenuData[0].title,
    },
    menu: {
      id: sideNavMainMenuData[0].menu[0].id,
      name: sideNavMainMenuData[0].menu[0].title,
    },
    submenu: {
      id: "",
      name: "",
    },
    data: "",
    to: "",
    currentTab: "Home",
  });
  //change event handler functions

  const handleHamburgerChange = () => {
    setToggleHamburger(!toggleHamburger);
  };

  const handleSideNavbarDataChange = async (name) => {
    // console.log(name);
    switch (name) {
      case "menu":
        setSideNavbarData(sideNavMainMenuData);
        setMenu({
          section: {
            id: sideNavMainMenuData[0].id,
            name: sideNavMainMenuData[0].title,
          },
          menu: {
            id: sideNavMainMenuData[0].menu[0].id,
            name: sideNavMainMenuData[0].menu[0].title,
          },
          submenu: {
            id: "",
            name: "",
          },
          data: "",
          to: "",
          currentTab: "Home",
        });
        break;
      // case "eycatalyst":
      //   setSideNavbarData(sideNavMainMenuData);
      //   setMenu({
      //     section: {
      //       id: sideNavMainMenuData[0].id,
      //       name: sideNavMainMenuData[0].title,
      //     },
      //     menu: {
      //       id: sideNavMainMenuData[0].menu[0].id,
      //       name: sideNavMainMenuData[0].menu[0].title,
      //     },
      //     submenu: {
      //       id: "",
      //       name: "",
      //     },
      //     data: "",
      //     to: "",
      //     currentTab: "Home",
      //   });
      //   break;
      case "settings":
        setSideNavbarData(sideNavSettingsData);
        setMenu({
          section: {
            id: sideNavSettingsData[0].id,
            name: "",
          },
          menu: {
            id: sideNavSettingsData[0].menu[0].id,
            name: "Profile",
          },
          submenu: {
            id: "",
            name: "",
          },
          data: "",
          to: "/settings/profile",
          currentTab: "Settings",
        });
        break;
      default:
        setSideNavbarData(sideNavMainMenuData);
        setMenu({
          section: {
            id: sideNavMainMenuData[0].id,
            name: sideNavMainMenuData[0].title,
          },
          menu: {
            id: sideNavMainMenuData[0].menu[0].id,
            name: sideNavMainMenuData[0].menu[0].title,
          },
          submenu: {
            id: "",
            name: "",
          },
          data: "",
          to: "",
          currentTab: "Home",
        });
    }
  };

  const handleChangeNav = (obj) => {
    //console.log(obj)
    setMenu({
      section: { id: obj.section.id, name: obj.section.name },
      menu: { id: obj.menu.id, name: obj.menu.name },
      submenu: { id: obj.submenu.id, name: obj.submenu.name },
      data: obj.data,
      to: obj.to,
      currentTab: obj?.currentTab,
    });
  };

  useEffect(() => {
    if (path === "eycatalyst" || path === "settings" || path === "") {
      handleSideNavbarDataChange(path);
    }

    if (path === "search") {
      setMenu({
        section: {
          id: sideNavMainMenuData[0].id,
          name: sideNavMainMenuData[0].title,
        },
        menu: {
          id: "Search",
          name: "Search libraries",
        },
        submenu: {
          id: "",
          name: "",
        },
        data: "",
        to: "/search",
        currentTab: "Home",
      });
    }
  }, []);

  return (
    <hamburgerMenuContext.Provider
      value={{
        toggleHamburger: toggleHamburger,
        handleHamburgerChange: handleHamburgerChange,
      }}
    >
      <sideNavbarDataContext.Provider
        value={{
          sideNavbarData,
          handleSideNavbarDataChange,
        }}
      >
        <menuContext.Provider
          value={{ menuState: menu, handleChangeNav: handleChangeNav }}
        >
          {children}
        </menuContext.Provider>
      </sideNavbarDataContext.Provider>
    </hamburgerMenuContext.Provider>
  );
};

export default MenuContext;

//create and export hook contexts
export function useMenuContext() {
  return useContext(menuContext);
}

export function useSideNavbarDataContext() {
  return useContext(sideNavbarDataContext);
}

export function useHamburgerMenuContext() {
  return useContext(hamburgerMenuContext);
}
