import React from "react";
import "./welcomeScreen.css";
import Button from "../../../commonui/button/button";
import Constant from "../../../utility/constants";
import { app, pages } from "@microsoft/teams-js";
import { useNavigate } from "react-router-dom";
import { useMenuContext } from "../../../context/menucontext/menuContext";
import { findMenuItem } from "../../../utility/utility";
import sideNavMainMenuData from "../../../data/menu/sideNavMainMenuData";

const WelcomeScreen = () => {
    const navigate = useNavigate();
    const { handleChangeNav } = useMenuContext();

    const navToPage = () => {
        handleChangeNav(findMenuItem(sideNavMainMenuData, "/conferences"));
        navigate("/conferences");
    };

    return <>
        <div className="wlcm-container">

            <div className="wlcm-img">
                <img src={require("../../../assets/bulletinboard/welcomescreen.png")} />
            </div>

            <div className="wlcm-txt">
                <p className="wlcm-txt-1">{`Welcome to ${Constant.APPLICATION_NAME}!`}</p>
                <p className="wlcm-txt-1">We're glad you're here.</p>
            </div>

            <div className="logo-details-outer">
                <div className="BB-detail-div">
                    <p className="wlcm-txt-2">A winning formula:</p>
                </div>

                <div className="BB-detail-div">
                    <img className="BB-detail-logo" alt="" src={require("../../../assets/MSTeamsTechnology.jpg")} />
                    <span className="BB-detail-text">Microsoft Teams platform effect</span>
                </div>
                <div className="BB-detail-div">
                    <img className="BB-detail-logo" alt="" src={require("../../../assets/EYContentBuilding.jpg")} />
                    <span className="BB-detail-text">EY content and data analytics libraries</span>
                </div>
                <div className="BB-detail-div">
                    <img className="BB-detail-logo" alt="" src={require("../../../assets/NetworkTechnology.jpg")} />
                    <span className="BB-detail-text">Your team and your EY team</span>
                </div>
            </div>

            <Button className="bb-content-btn"
                text="Begin your content experience"
                onClick={navToPage} />
        </div>
    </>;
};

export default WelcomeScreen;