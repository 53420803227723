import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
  $insertNodes,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  CLEAR_EDITOR_COMMAND,
  CLEAR_HISTORY_COMMAND
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd,
  $patchStyleText,
  $getSelectionStyleValueForProperty
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { createPortal } from "react-dom";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode
} from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages
} from "@lexical/code";
import { MdDeleteOutline, MdEdit, MdKeyboardArrowDown, MdOutlineCode, MdOutlineEmojiEmotions, MdOutlineFormatQuote, MdOutlineFormatUnderlined, MdOutlineInsertLink, MdRedo, MdStrikethroughS, MdUndo } from "react-icons/md";
import { BsTextParagraph } from "react-icons/bs";
// import { LuHeading1, LuHeading2 } from "react-icons/lu";
import React from "react";
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { BoldIcon, BulletsIcon, CodeSnippetIcon, HorizontalRuleIcon, IndentIcon, ItalicIcon, NumberListIcon, OutdentIcon, QuoteIcon, StrikeIcon, UnderlineIcon, UndoIcon, RedoIcon, Toolbar, SplitButton, Dropdown } from "@fluentui/react-northstar";
import { BsFillTrash3Fill } from "react-icons/bs";
import { CiTextAlignCenter, CiTextAlignJustify, CiTextAlignLeft, CiTextAlignRight } from "react-icons/ci";
import { $generateNodesFromDOM } from '@lexical/html';


const LowPriority = 1;




const fontSizeIcons = {
  paragraph: <BsTextParagraph className="icon paragraph" />,
  // "Small Heading": <LuHeading2 className="icon small-heading" />,
  // "Large Heading": <LuHeading1 className="icon large-heading" />,
};

;


function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
      }px`;
  }
}

function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);



  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className="link-input" >
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </a>
            <MdEdit
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function Select({ onChange, className, options, value }) {
  return (
    <select className={className} onChange={onChange} value={value}>
      <option hidden={true} value="" />
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      <MdDeleteOutline />
    </select>
  );
}


function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}



export default function ToolbarPlugin(props) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(
    false
  );
  const [codeLanguage, setCodeLanguage] = useState("");
  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [fontSize, setFontSize] = useState('15px');
  const [fontSizeName, setFontSizeName] = useState('paragraph');

  const { getHtmlString } = props;
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '15px'),
      );
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
    // setIsLink((val)=>!val)
  }, [editor, isLink]);

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  function BlockOptionsDropdownList({
    editor,
    blockType,
    toolbarRef,
    setShowBlockOptionsDropDown
  }) {
    const dropDownRef = useRef(null);

    useEffect(() => {
      const toolbar = toolbarRef.current;
      const dropDown = dropDownRef.current;

      if (toolbar !== null && dropDown !== null) {
        // const { top, left } = toolbar.getBoundingClientRect();
        dropDown.style.top = `40px`;
        dropDown.style.left = `55px`;
      }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
      const dropDown = dropDownRef.current;
      const toolbar = toolbarRef.current;

      if (dropDown !== null && toolbar !== null) {
        const handle = (event) => {
          const target = event.target;

          // if (!dropDown.contains(target) && !toolbar.contains(target)) {
          //   setShowBlockOptionsDropDown(false);
          // }
        };
        document.addEventListener("click", handle);

        return () => {
          document.removeEventListener("click", handle);
        };
      }
    }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);




    const handleClick = useCallback(
      (option) => {
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            $patchStyleText(selection, {
              "font-size": option,
            });
          }
        });
        setShowBlockOptionsDropDown(false);
      },
      [editor],
    );
    return (
      <div className="dropdown" ref={dropDownRef}>
        <button className="item" onClick={() => { handleClick("15px"); setFontSizeName("paragraph"); }}>
          <BsTextParagraph className="icon paragraph" />
          <span className="text">Paragraph</span>
          {blockType === "paragraph" && <span className="active" />}
        </button>
        <button className="item" onClick={() => { handleClick("30px"); setFontSizeName("Large Heading"); }}>
          {/* <LuHeading1 className="icon large-heading" /> */}
          <span className="text">Large Heading</span>
          {blockType === "h1" && <span className="active" />}
        </button>
        <button className="item" onClick={() => { handleClick("25px"); setFontSizeName("Small Heading"); }}>
          {/* <LuHeading2 className="icon small-heading" /> */}
          <span className="text">Small Heading</span>
          {blockType === "h2" && <span className="active" />}
        </button>

      </div>
    );
  }
  // useEffect(()=>{
  //   const htmlString = $generateHtmlFromNodes(editor,  null);
  //   props?.setHtml(htmlString);
  // },[])

  const [overflowOpen, setOverflowOpen] = React.useState(false);

  useEffect(() => {
    resetEditor();
  }, [props.clearEditor]);

  const resetEditor = () => {
    const editorState = editor.parseEditorState(
      JSON.stringify({
        root: {
          children: [
            {
              children: [],
              direction: null,
              format: "",
              indent: 0,
              type: "paragraph",
              version: 1
            }
          ],
          direction: null,
          format: "",
          indent: 0,
          type: "root",
          version: 1
        }
      })
    );
    editor.setEditorState(editorState);
    editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
    editor.focus();
  };


  const itemData = [
    {
      key: 1,
      // content: `${iconsNames[i % iconsNames.length]} #${i}`,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        className={"toolbar-item spaced " + (isBold ? "active" : "")}
        aria-label="Format Bold"
      >
        <BoldIcon className="format bold" />
      </button>,
      title: "Bold",
    },

    {
      key: 2,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        className={"toolbar-item spaced " + (isItalic ? "active" : "")}
        aria-label="Format Italics"
      >
        <ItalicIcon className="format italic" />
      </button>,
      title: "Italics",
    },
    {
      key: 3,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
        className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
        aria-label="Format Underline"
      >
        <UnderlineIcon className="format underline" />
      </button>,
      title: "Underline",
    },
    {
      key: 4,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        }}
        className={
          "toolbar-item spaced " + (isStrikethrough ? "active" : "")
        }
        aria-label="Format Strikethrough"
      >
        <StrikeIcon className="format strikethrough" />
      </button>,
      title: "Strikethrough",
    },
    {
      key: 5,
      kind: 'divider',
    },
    {
      key: 'custom-split-button',
      kind: 'custom',
      // content: <ToolbarSplitButton />,
      content: <><button
        className="toolbar-item block-controls"
        onClick={() =>
          setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
        }
        aria-label="Formatting Options"
      >
        {fontSizeIcons?.[fontSizeName]}
        <div className="text">{fontSizeName}</div>
        <MdKeyboardArrowDown className="chevron-down" />
      </button>
      </>,
      fitted: 'horizontally',
    },
    {
      key: 6,
      kind: 'divider',
    },
    {
      key: 7,
      icon: <button
        className={`toolbar-item spaced ${blockType === "ul" && "active"}`}
        onClick={formatBulletList} >
        <BulletsIcon />
      </button>,
      title: "Bullet List",
    },
    {
      key: 8,
      icon: <button
        className={`toolbar-item spaced ${blockType === "ol" && "active"}`}
        onClick={formatNumberedList} >
        <NumberListIcon />
      </button>,
      title: "Number List",
    },

    {
      key: 9,
      icon: <button
        className={`toolbar-item spaced ${blockType === "quote" && "active"}`}
        onClick={formatQuote}
      ><QuoteIcon /></button>,
      title: "Quote",
    },
    {
      key: 10,
      icon: <button
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
        className={"toolbar-item spaced " + (isCode ? "active" : "")}
        aria-label="Insert Code"
      >
        <CodeSnippetIcon className="format code" />
      </button>,
      title: "Code",
    },

    {
      key: 11,
      kind: 'divider',
    },

    {
      key: 12,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        aria-label="Right Align"
      >
        <OutdentIcon className="format right-align" />
      </button>,
      title: "Outdent",
    },
    {
      key: 13,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        aria-label="Left Align"
      >
        <IndentIcon className="format left-align" />
      </button>,
      title: "Indent",
    },

    {
      key: 14,
      kind: 'divider',
    },
    {
      key: 15,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }}
        className="toolbar-item spaced"
        aria-label="Left Align"
      >
        <CiTextAlignLeft className="format left-align" />
      </button>,
      title: "Left Align",
    },
    {
      key: 16,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }}
        className="toolbar-item spaced"
        aria-label="Center Align"
      >
        <CiTextAlignCenter className="format center-align" />
      </button>,
      title: "Center Align",
    },
    {
      key: 17,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }}
        className="toolbar-item spaced"
        aria-label="Right Align"
      >
        <CiTextAlignRight className="format right-align" />
      </button>,
      title: "Right Align",
    },
    {
      key: 18,
      icon: <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        }}
        className="toolbar-item"
        aria-label="Justify Align"
      >
        <CiTextAlignJustify className="format justify-align" />
      </button>,
      title: "Justify Align",
    },
    {
      key: 19,
      kind: 'divider',
    },
    {
      key: 20,
      icon: <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND);
        }}
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <UndoIcon className="format undo" />

      </button>,
      title: "Undo",
    },
    {
      key: 21,
      icon: <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND);
        }}
        className="toolbar-item"
        aria-label="Redo"
      >
        <RedoIcon className="format redo" />
      </button>,
      title: "Redo",
    },

  ];

  useEffect(() => {

    props?.edit && editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(props?.htmlString, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      $getRoot().select();
      $insertNodes(nodes);
    });
  }, []);

  return (<>
    <OnChangePlugin
      onChange={(editorState) => {
        editorState.read(() => {
          getHtmlString($generateHtmlFromNodes(editor));
        });
      }} />
    {props?.showToolbarTop ? <div className="toolbar" ref={toolbarRef}>

      <div style={{ width: "80%" }}>
        <Toolbar
          aria-label="Toolbar overflow menu"
          items={itemData}
          overflow
          overflowOpen={overflowOpen}
          overflowItem={{
            title: 'More',
          }}
          onOverflowOpenChange={(e, { overflowOpen }) => {
            setOverflowOpen(overflowOpen);
          }}
          getOverflowItems={startIndex => itemData.slice(startIndex)}
        />
      </div>
      {showBlockOptionsDropDown && <BlockOptionsDropdownList
        editor={editor}
        blockType={blockType}
        toolbarRef={toolbarRef}
        setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
      />}
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}

      <BsFillTrash3Fill onClick={resetEditor} className="toolbar-item spaced trash-icon" />

    </div> : <></>}
  </>

  );
}
