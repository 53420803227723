import React from "react";
import "./unauthorized.css";

function UnauthorizedPage() {
  return (
    <div className="unauthorized_container">
      <img
        src={require("../../assets/unauthorizedAccess.PNG")}
        alt=""
        className="unauthorized-img"
      />
      <p className="unauthorized_containerHeader">Unauthorized</p>
      <p className="unauthorized_containerSubtext">
        Sorry, you don't have permission to access. Please contact an
        administrator.
      </p>
    </div>
  );
}

export default UnauthorizedPage;
