import React, { useState, createContext, useContext, useEffect } from "react";
import { app } from "@microsoft/teams-js";
import  Constant  from "../../utility/constants";
import { UseUserInfoContext } from "../usercontext/userContext";

export const deeplinkContext = createContext({
  deeplink: "",
});

export const TaxDetailDeepLink = ({ children }) => {
  const [deeplinkUrl, setDeeplinkUrl] = useState("");
  const [teamslinkUrl, setTeamsLink] = useState("");
  const [weblinkUrl, setWeblinkUrl] = useState("");
  let { userInfo } = UseUserInfoContext();

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        app.getContext().then((item) => {
          const deeplink = `https://teams.microsoft.com/l/entity/${Constant.GetUserAppId(
            item.user.userPrincipalName
          )}/${Constant.HomeTab}?&context=SUBENTITY_ID`;
          //const html = "<a href=" + deeplink + ">" + deeplink + "</a>";
          setDeeplinkUrl(deeplink);
          setTeamsLink(deeplink);
          setWeblinkUrl(Constant.GetClientEnvironment()+"/");
        });
      })
      .catch((error) => {
        setDeeplinkUrl(Constant.GetClientEnvironment()+"/");
        const deeplink = `https://teams.microsoft.com/l/entity/${Constant.GetUserAppId(
         userInfo.email
        )}/${Constant.HomeTab}?&context=SUBENTITY_ID`;
        setTeamsLink(deeplink);
        setWeblinkUrl(Constant.GetClientEnvironment()+"/");
      });
  }, []);
  return (
    <deeplinkContext.Provider value={{ deeplinkUrl , teamslinkUrl , weblinkUrl }}>
      {children}
    </deeplinkContext.Provider>
  );
};

export function UseDeeplinkContext() {
  return useContext(deeplinkContext);
}
