import React, { useState, createContext, useContext } from "react";
const myShortcutsObj = {
  shortcutsList: [],
};

export const myShortcutsContext = createContext({
  myShortcutsData: myShortcutsObj,
  handleMyShortcutsData: (props) => {},
});

export function UseMyShortcutsContext() {
  return useContext(myShortcutsContext);
}

const MyShortcutsContext = ({ children }) => {
  const [myShortcutsData, setmyShortcutsData] = useState(myShortcutsObj);

  const handleMyShortcutsData = (props) => {
    setmyShortcutsData((prevData) => {
      return { ...prevData, ...props };
    });
  };

  return (
    <myShortcutsContext.Provider
      value={{ myShortcutsData, handleMyShortcutsData }}
    >
      {children}
    </myShortcutsContext.Provider>
  );
};

export default MyShortcutsContext;
