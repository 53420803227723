import { Loader } from "@fluentui/react-northstar/dist/es/components/Loader/Loader";
import React from "react";
import "./laodmore.css";
function LoadMore(props) {
  let { isFetching, hasMoreData, onClick } = props;
  return isFetching ? (
    <Loader style={{ marginBottom: "60px" }} />
  ) : hasMoreData ? (
    <button className="loadMoreBtn" onClick={onClick}>
      Load more
    </button>
  ) : (
    <></>
  );
}

export default LoadMore;
