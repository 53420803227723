import React, { useState, createContext, useContext } from "react";

export const contentCurationContextData = createContext({
  contentCurationData: {},
  handleContentCurationData: (props) => { },
});

export function UseContentCurationContext() {
  return useContext(contentCurationContextData);
}

const ContentCurationContext = ({ children }) => {
  const [contentCurationData, setContentCurationData] = useState({
    formTitle: "Conferences",
    pageId: -1,
    contentTypeId: 5,
    metaDataId: 0,
    section: "",
    isPanelOpen: false
  });

  const handleContentCurationData = (props) => {
    setContentCurationData((prevData) => {
      return { ...prevData, ...props };
    });
  };

  return (
    <contentCurationContextData.Provider
      value={{ contentCurationData, handleContentCurationData }}
    >
      {children}
    </contentCurationContextData.Provider>
  );
};

export default ContentCurationContext;
