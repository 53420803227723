/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Card,
  Provider,
  teamsTheme,
  Image,
} from "@fluentui/react-northstar";
import { DefaultButton } from "@fluentui/react/lib/Button";
import {
  MdEdit,
  MdDelete,
  MdSettings,
  MdDisabledVisible,
  MdOutlinePushPin,
  MdGroups2,
} from "react-icons/md";
import { RiGroupFill } from "react-icons/ri";
import { FaFireFlameCurved } from "react-icons/fa6";
import { BsBookmark, BsBookmarkFill, BsPinAngleFill } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { GoLinkExternal } from "react-icons/go";
import { v4 as uuidv4 } from "uuid";
import ActionBar from "../actionbar/ActionBar";
import CustomizedToolTip from "../tooltip/toolTip";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import MenuButton from "../../commonui/menubutton/menuButton";
import RadioButtons from "../../commonui/commonformcomponents/radiobuttons/radioButtons";
import CheckboxComponent from "../../commonui/commonformcomponents/checkbox/checkbox";
import PollsResult from "../../commonui/commonformcomponents/pollsresult/pollsResult";
import AvatarUsersList from "../../components/avataruserslist/avatarUsersList";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { UseContentCurationContext } from "../../context/contentcurationcontext/contentCurationContext";
import { TaxDetailDeepLink } from "../../context/deeplincontext/deeplinkContext";
import { postData, deleteDataByPayload } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import { formatDate, formatDate5, getAttachment, getContentTypeNamesById2, getContentTypePathsById } from "../../utility/utility";
import { getDateFromUTC } from "../../utility/timefunctions";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../services/appInsights";
import { useNavigate } from "react-router-dom";
import "./Card.css";

const CardExampleSize = (props) => {
  let userLevel = props?.item?.customData?.userLevel;
  let config = props.config;
  let bulletinBoard = props?.bulletinBoard;
  let pinInfo = props?.item?.customData?.pinInfo;
  let updatePinInfoByMetaDataId = props?.updatePinInfoByMetaDataId;
  const [isBookmarkActive, setBookmark] = useState(userLevel?.bookmarked);
  const [cardImageTitle, setCardImageTitle] = useState("");
  const [cardCategory, setCardCategory] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openPinnedDialog, setOpenPinnedDialog] = useState(false);
  const [openDialogClose, setOpenDialogClose] = useState(false);
  const [openPinDialog, setPinOpenDialog] = useState(false);
  let contentType = props.contentType;
  let isCustomUserGroup = props?.isCustomUserGroup;
  const [imageUrl, setImageUrl] = useState("");
  let { userInfo } = UseUserInfoContext();
  const uniqueId = "ui-flex-header-banner" + uuidv4();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState("");
  const [pollCreationDate, setPollCreationDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [pinedInfo, setPinInfo] = useState({
    pinnedBy: pinInfo?.pinnedBy,
    pinnedDate: pinInfo?.pinnedDate,
    pinnedStatus: pinInfo?.pinnedStatus,
  });
  const [editClicked, setEditClicked] = useState(false);
  const [selectedPollRadioOption, setSelectedPollRadioOption] = useState("");
  const [checkedPollOptions, setCheckedPollOptions] = useState([]);
  // const [dummyFlag, setDummyFlag] = useState("");
  const [isViewResultsClicked, setIsViewResultsClicked] = useState();
  const [isSubmitClicked, setIsSubmitClicked] = useState();
  const [isBackToQuestionClicked, setIsBackToQuestionClicked] = useState();
  const [isEditResponseClicked, setIsEditResponseClicked] = useState();
  const [oneLineGroupMember, setOneLineGroupMember] = useState(false);

  const { handleContentCurationData } = UseContentCurationContext();
  const navigate = useNavigate();
  const handleBookmarkKeyDown = (e) => {
    if (e.key === "Enter") {
      const event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      handleBookmarkClick(event);
      e.preventDefault();
    }
  };
  const handleBookmarkClick = (e) => {
    e.stopPropagation();
    setBookmark((current) => !current);
    let obj = {
      isBookmarked: !isBookmarkActive,
      metaDataId: props.item.metaDataId,
    };
    postData(
      obj,
      APIEndPoints.CONTENT_BOOKMARK(
        contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        if (response.data.statusCode == "STCS002" || response.data == "") {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.ContentNotAvailable,
            type: "fail",
            isFailed: false,
          });
        } else {
          //TESTED
          TrackEvent(
            obj.isBookmarked
              ? TelemetryEventNames.UCC_CONTENT_BOOKMARK_EVENT
              : TelemetryEventNames.UCC_CONTENT_BOOKMARK_REMOVE_EVENT,
            userInfo,
            {
              SectionName:
                props?.contentType == 4
                  ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                  : TelemetryEventNames.SECTION_TYPE_CONTENT,
              ContentTypeId: props.contentType.toString() ?? "",
              ContentTypeName:
                getContentTypeNamesById2(props.contentType) ?? "",
              MetadataId: props.item.metaDataId.toString(),
              UniqueId: props.item.contentUniqueId ?? "",
              Title: props.item.title ?? "",
              PublishDate: props.item.publishedDate ?? "",
              LatestBookmarkCount:
                response?.data?.resultData?.bookMarkCount.toString() ?? "",
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  const setOneLineGroupMemberVal = (val) => {
    setOneLineGroupMember(val);
  };

  const handlePinClick = (isPinned) => {
    // setPin((current) => !current);
    isPinned &&
      setPinInfo((prev) => {
        return {
          ...prev,
          pinnedBy:
            userInfo.displayName !== undefined
              ? userInfo.displayName
              : userInfo.email,
          pinnedStatus: isPinned,
        };
      });
    postData(
      {
        metaDataId: props?.item?.metaDataId,
        isPinned: isPinned,
      },
      APIEndPoints.PIN_UNPIN_CARD(
        contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId,
        isPinned
      )
    )
      .then((res) => {
        //console.log(res);
        if (!isPinned) {
          setPinOpenDialog(false);
          setLoader(false);
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg:
              res?.data?.status === "success"
                ? contentType == 13
                  ? Constant.APP_UNPINNED_SUCCESSFULLY
                  : Constant.UNPINNED_SUCCESSFULLY
                : Constant.ContentNotAvailable,
            type: res?.data?.status === "success" ? "Success" : "Fail",
            isFailed: false,
          });
          setPinInfo((prev) => {
            return {
              ...prev,
              pinnedBy:
                userInfo.displayName !== undefined
                  ? userInfo.displayName
                  : userInfo.email,
              pinnedStatus: isPinned,
            };
          });
        }
        props?.isBulletinBoard == true && props?.dismissModal();
        TrackEvent(TelemetryEventNames.UCC_BB_PIN_EVENT, userInfo, {
          SectionName:
            props?.contentType == 4
              ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
              : TelemetryEventNames.SECTION_TYPE_CONTENT,
          ContentTypeId: props.contentType?.toString() ?? "",
          ContentTypeName: getContentTypeNamesById2(props.contentType) ?? "",
          MetadataId: props.item.metaDataId?.toString(),
          UniqueId: props.item.contentUniqueId ?? "",
          Title: props.item.title ?? "",
          PublishDate: props.item.publishedDate ?? "",
          PinStatus: "Pinned",
        });
      })
      .catch((err) => {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
        setPinInfo((prev) => {
          return {
            ...prev,
            pinnedStatus: !isPinned,
          };
        });
        if (!isPinned) {
          setPinOpenDialog(false);
          setLoader(false);
        }
      });
  };
  const onClickPinnedContent = (value) => {
    setOpenPinnedDialog(value);
  };
  const navigateToBB = () => {
    //props?.isFromDeeplink == true && props?.setDismissModal();
    navigate("/", {
      state: {
        isGlobal:
          props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
            ? true
            : false,
        companyId: userInfo?.teams?.companyId,
        contentType: contentType && parseInt(contentType),
        metaDataId: props.item.metaDataId,
        contentTypeName: getContentTypeNamesById2(contentType),
        isFromCard: true,
      },
    });
  };
  useEffect(() => {
    if (updatePinInfoByMetaDataId !== undefined) {
      !bulletinBoard &&
        props?.updatePinInfoByMetaDataId(props.item.metaDataId, pinedInfo);
    }
  }, [pinedInfo]);

  let prevViewportWidth = window.innerWidth;
  const handleZoomChange = () => {
    const currentViewportWidth = window.innerWidth;
    const zoomFactor = currentViewportWidth / prevViewportWidth;

    const overlay = document.querySelector(".loaderOnDelete");
    if (overlay) {
      overlay.style.width = parseFloat(overlay.style.width) * zoomFactor + "px";
      overlay.style.height =
        parseFloat(overlay.style.height) * zoomFactor + "px";
    }
    prevViewportWidth = currentViewportWidth;
  };

  window.addEventListener("resize", handleZoomChange);
  useEffect(() => {
    let checkBoxValues = [];
    props?.item?.contentInfo?.[0]?.content?.pollData?.options?.map(
      (option, index) => {
        checkBoxValues.push({ optionId: option?.optionId, checked: false });
      }
    );
    setCheckedPollOptions(checkBoxValues);
    setCardImageTitle(
      props?.item?.author?.length > 0
        ? props?.item?.author[0].name
        : props?.item?.contentCustomData?.tileImage?.title?.length > 0
        ? props?.item?.contentCustomData?.tileImage?.title
        : ""
    );

    setCardCategory(
      Array.isArray(props.item?.category)
        ? props.item?.category.map((c) => c.categoryName).join(", ")
        : props.item?.category
    );

    getDate();
    getPollCreationDate();
    //getExpirationDate();

    // props?.item?.backgroundImage &&
    //   imageUrl === "" &&
    //   getAttachment(
    //     props?.item?.backgroundImage,
    //     props.item.metaDataId,
    //     contentType
    //   );
    if (
      props?.item?.hasOwnProperty("contentCustomData") &&
      props?.item?.contentCustomData.hasOwnProperty("tileImage") &&
      props?.item?.contentCustomData?.tileImage.hasOwnProperty("image") &&
      imageUrl === ""
    ) {
      if (
        props?.item?.contentCustomData?.tileImage?.image?.fileDetail &&
        !window.location.pathname.includes("search")
      ) {
        getAttachment(
          [props?.item?.contentCustomData?.tileImage?.image],
          props.item.metaDataId,
          userInfo,
          contentType,
          props?.item?.contentSecurity?.availability?.domainLevel ==
            Constant.GLOBAL
            ? true
            : false
        )
          .then((item) => {
            setImageUrl(item);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, []);

  const handleRadioChange = (value) => {
    setSelectedPollRadioOption(value);
  };

  const handleCheckboxChange = (
    e,
    value,
    fN,
    isCF,
    name,
    hE,
    eM,
    index,
    option
  ) => {
    let allCheckedValues = [...checkedPollOptions];
    allCheckedValues[index] = {
      optionId: option?.optionId,
      checked: value?.checked,
    };
    setCheckedPollOptions(allCheckedValues);
  };

  const getRadioOptions = (options) => {
    let newRadioItems = options?.map((item, index) => {
      let decodedOption = formatDecodedURLs(item?.optionName);
      let newObj = {
        key: index + 1,
        value: item?.optionId,
        label: decodedOption,
        metrics: item?.optionMetrics,
        data: item,
        disabled: props?.item?.status === "Closed" ? true : false,
        variables: {
          indicatorColorDefault: "black",
          indicatorBackgroundColorChecked: "black",
          indicatorBorderColorDefaultHover: "black",
        },
      };
      return newObj;
    });
    return newRadioItems;
  };

  const formatDecodedURLs = (name) => {
    return props?.isPolls == true
      ? decodeURIComponent(escape(window.atob(name)))
      : name;
  };

  const onClickEditTile = async () => {
    setIsLoading(true);
    setEditClicked(true);
    if (isCustomUserGroup) {
      //navigate("/customUserGroupForm/");
      navigate("/customUserGroupForm", {
        state: {
          isCustomUserGroupEdit: props?.item?.customUserGroupId,
        },
      });
    }
    if (props?.isFromDeeplink == true) {
      props?.setDismissModal();
    }
    let isGlobalFlag =
      props?.item?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
        ? true
        : false;
    let path = await getContentTypePathsById(parseInt(props?.contentType));

    if (props?.contentTypeName === "AppsDetails") {
      handleContentCurationData({
        formTitle: "Apps",
        pageId: window?.location?.pathname,
        contentTypeId: 13,
        metaDataId: props.id,
        section: props.section,
      });
    } else {
      handleContentCurationData({
        formTitle: props.contentTypeName,
        pageId: props.pathname || path,
        contentTypeId: props.contentType,
        metaDataId: props.id,
        section: props.section,
      });
    }
    !isCustomUserGroup &&
      navigate("/contentCuration", {
        state: {
          isGlobal: isGlobalFlag,
        },
      });
  };

  const onClickExtendEndDate = () => {
    handleContentCurationData({
      formTitle: props.contentTypeName,
      pageId: props.pathname,
      contentTypeId: props.contentType,
      metaDataId: props.id,
      section: props.section,
    });

    navigate("/newPollCuration", {
      state: {
        isGlobal:
          props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
            ? true
            : false,
      },
    });
  };

  const onClickDeleteTile = async () => {
    setLoader(true);
    let payload = {
      metaDataId: props?.item?.metaDataId,
    };
    await deleteDataByPayload(
      APIEndPoints.DELETE_CONTENT(
        contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.key
      ),
      payload
    )
      .then((response) => {
        setOpenDialog(false);
        setLoader(false);
        if (response?.data?.statusCode === "STCS001") {
          props?.pathname === "/search"
            ? props?.handleDelete()
            : props?.handleReload();
          //TESTED
          TrackEvent(
            TelemetryEventNames.UCC_CONTENT_DELETE_CONTENT_EVENT,
            userInfo,
            {
              SectionName:
                contentType == 4
                  ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                  : TelemetryEventNames.SECTION_TYPE_CONTENT,
              MetadataId: props?.item?.metaDataId ?? "",
              UniqueId: props?.item?.contentUniqueId ?? "",
              ContentTypeId: contentType.toString() ?? "",
              ContentTypeName: getContentTypeNamesById2(contentType) ?? "",
            }
          );
        }
       // console.log(props?.contentTypeName);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg:
            response?.data?.statusCode === "STCS001"
              ? response?.data?.statusMessage == ""
                ? `${props?.contentTypeName} deleted successfully`
                : response?.data?.statusMessage
              : Constant.ContentNotAvailable,
          type: response?.data?.statusCode === "STCS001" ? "Success" : "Fail",
          isFailed: false,
        });
      })
      .catch((err) => {
        setOpenDialog(false);
        setLoader(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors
            ? err?.response?.data?.Errors
            : Constant.SOMETHING_WENTWRONG,
          type: "fail",
          isFailed: false,
        });
      });
  };
  const onClickDeleteCustomUserGroup = async () => {
    setLoader(true);

    await deleteDataByPayload(
      APIEndPoints.DELETE_CUSTOM_USER_GROUP(
        userInfo?.teams?.companyId,
        props?.item?.customUserGroupId
      )
    )
      .then((response) => {
        setOpenDialog(false);
        setLoader(false);
        if (response?.status === 200) {
          props?.handleReload();
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.CUSTOM_USER_GROUPS.DELETE_USER_GROUP_SUCCESS_MESSAGE,
            type: "Success",
            isFailed: false,
          });
          //TESTED
          TrackEvent(
            TelemetryEventNames.UCC_CONTENT_DELETE_CONTENT_EVENT,
            userInfo,
            {}
          );
        } else {
          props?.handleReload();
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: response?.Errors[0],
            type: "Fail",
            isFailed: true,
          });
        }
      })

      .catch((error) => {
        let errorText = error?.response?.data?.Errors?.[0];
        setOpenDialog(false);
        setLoader(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: errorText
            ? errorText
            : Constant.CUSTOM_USER_GROUPS.DELETE_USER_GROUP_FAILURE_MESSAGE,
          type: "fail",
          isFailed: false,
        });
      });
  };
  const getDate = () => {
    setDate(formatDate(props?.item?.publishedDate));
  };

  const getPollCreationDate = () => {
    setPollCreationDate(formatDate5(props?.item?.publishedDate));
  };

  const getExpirationDate = () => {
    if (props?.item?.contentCustomData?.tileCustomData?.closedDate?.length > 0)
      return formatDate(
        props?.item?.contentCustomData?.tileCustomData?.closedDate
      );
    else if (
      props?.item?.contentCustomData?.tileCustomData?.expirationDate?.length > 0
    )
      return formatDate(
        props?.item?.contentCustomData?.tileCustomData?.expirationDate
      );
    else return "";
  };

  const renderPollOptions = (cardOptions, selectionType) => {
    let checkBoxValue = [];
    if (selectionType === "Multiple") {
      return cardOptions?.map((pollOption, index) => {
        let decodedOption = formatDecodedURLs(pollOption?.optionName);
        checkBoxValue.push(false);
        return (
          <div tabIndex={0} key={index} className="pollOptionCheckmark">
            <CheckboxComponent
              label={<span>{decodedOption}</span>}
              checked={checkedPollOptions[index]?.checked}
              onChange={handleCheckboxChange}
              index={index}
              option={pollOption}
              disabled={props?.item?.status === "Closed" ? true : false}
              className="checkboxOptions"
              contentType={contentType}
            />
          </div>
        );
      });
    } else {
      return (
        <div tabIndex={0}>
          <RadioButtons
            items={getRadioOptions(cardOptions)}
            onCheckedValueChange={handleRadioChange}
            errMessage=""
            vertical={true}
            value={selectedPollRadioOption}
            className={"radioButtonsOptions"}
            defaultCheckedValue={selectedPollRadioOption}
          />
        </div>
      );
    }
  };
  const renderPollResult = (options, title) => {
    return (
      <PollsResult pollOptions={getRadioOptions(options)} pollTitle={title} />
    );
  };

  const renderUnansweredClosedPollImage = () => {
    return (
      <>
        <div className="unanswered-poll-image-outer">
          <img
            className="unanswered-poll-image"
            src={require("../../assets/UnansweredPoll.svg").default}
            alt=""
          />
          <p className="unanswered-poll-txt">Why did this poll feel lonely?</p>
          <p className="unanswered-poll-txt">Because it got ghosted by you!</p>
        </div>
      </>
    );
  };

  const checkIfSubmitDisabled = (selectionType) => {
    let isDisabled = true;
    if (selectionType === "Single") {
      Object.keys(selectedPollRadioOption)?.length != 0 &&
      selectedPollRadioOption != undefined &&
      selectedPollRadioOption != null &&
      selectedPollRadioOption != ""
        ? (isDisabled = false)
        : (isDisabled = true);
    } else {
      checkedPollOptions?.length > 0 &&
      checkedPollOptions?.filter((option) => {
        return option?.checked == true;
      })?.length > 0
        ? (isDisabled = false)
        : (isDisabled = true);
    }
    return isDisabled;
  };
  // const renderSubmitViewResults = (status, hasVoted, selectionType) => {
  //   let isDisabled = checkIfSubmitDisabled(selectionType);
  //   let isViewOrBackToQuestion;
  //   if (status === "Active" || status?.toLowerCase() === "Closing Soon"?.toLowerCase()) {
  //     isViewOrBackToQuestion = isViewResultsClicked === true ? "Back to Question" : isBackToQuestionClicked === true ? "View Results" : hasVoted ? "Edit Response" : "View Results";
  //   }
  //   else if (status == "Closed") {
  //     isViewOrBackToQuestion = isViewResultsClicked === true ? "Back to Question" : isBackToQuestionClicked === true ? "View Results" : hasVoted ? "Back to Question" : "View Results";
  //   }
  //   return (
  //     <>
  //       {
  //         <div className="contentAuthorPrivilegesBtns">
  //           {(status === "Active" || status?.toLowerCase() === "Closing Soon"?.toLowerCase()) && !isSubmitClicked && !isViewResultsClicked && isViewOrBackToQuestion !== "Back to Question" && isViewOrBackToQuestion !== "Edit Response" &&
  //             <DefaultButton
  //               text={"Submit"}
  //               onClick={() => { onClickSubmit1(); }}
  //               disabled={config?.submitResponse?.submitResponseEnabled && isDisabled === false ? false : true}
  //               className={(config?.submitResponse?.submitResponseEnabled === true && isDisabled === false) ? `poll-btn-submit enabled` : "poll-btn-submit disabled"}
  //             />
  //           }
  //           <DefaultButton
  //             text={
  //               dummyFlag === true ? "Edit Response" : isViewOrBackToQuestion
  //             }
  //             className={"poll-btn-view-result"}
  //             disabled={!config?.viewResult?.viewResultEnabled}
  //             onClick={() => {
  //               onClickViewOrBackToResponse(
  //                 dummyFlag === true ? "Edit Response" : isViewOrBackToQuestion
  //               );
  //             }}
  //           />
  //         </div>
  //       }
  //     </>
  //   );
  // };
  const renderSubmitOrEdit = (hasVoted, selectionType) => {
    let isDisabled = checkIfSubmitDisabled(selectionType);
    let isSubmitOrEdit =
      isSubmitClicked === true
        ? "Edit"
        : //? "Edit Response"
        isEditResponseClicked === true
        ? "Submit"
        : hasVoted
        ? // ? "Edit Response"
          "Edit"
        : "Submit";
    return (
      <div className="allPrivilegesBtns">
        {
          <DefaultButton
            text={isSubmitOrEdit}
            data-testid={"data-submit"}
            onClick={() => {
              onClickSubmitOrEdit(isSubmitOrEdit);
            }}
            disabled={
              isSubmitOrEdit === "Submit"
                ? config?.submitResponse?.submitResponseEnabled === true &&
                  isDisabled === false
                  ? false
                  : true
                : false
            }
            className={
              isSubmitOrEdit === "Submit"
                ? config?.submitResponse?.submitResponseEnabled === true &&
                  isDisabled === false
                  ? `poll-btn-submit enabled`
                  : "poll-btn-submit disabled"
                : "poll-btn-view-result enabled"
            }
          />
        }
      </div>
    );
  };
  const onClickClosePoll = async () => {
    setLoader(true);
    //setIsLoading(true);
    let obj = {
      metaDataId: props.item.metaDataId,
      contentStatus: 3,
    };
    await postData(
      obj,
      APIEndPoints.UPDATE_POLL_CONTENT_STATUS(
        contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        setOpenDialogClose(false);
        if (response.data?.[0].statusCode == "STCS001" || response.data == "") {
         // console.log(response);
          if (props?.isFromDeeplink == true) {
            props?.updatePopupData(response.data[0].resultData);
            props?.updateMultiContainerData(
              response.data[0].resultData,
              props?.id,
              props?.sectionName,
              props?.item?.customData?.userLevel?.voted ? true : false
            );
          } else {
            props?.updateCardData(
              response.data[0].resultData,
              props?.id,
              props?.sectionName,
              props?.item?.customData?.userLevel?.voted ? true : false
            );
          }
          props?.item?.contentCustomData?.tileCustomData?.selectionType ==
          "Multiple"
            ? handleResetCheckboxes()
            : handleResetRadioOptions();

          //TESTED
          TrackEvent(
            TelemetryEventNames.UCC_CONTENT_CLOSE_POLL_CONTENT_EVENT,
            userInfo,
            {
              SectionName:
                contentType == 4
                  ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                  : TelemetryEventNames.SECTION_TYPE_CONTENT,
              MetadataId: props?.item?.metaDataId ?? "",
              UniqueId: props?.item?.contentUniqueId ?? "",
              ContentTypeId: contentType.toString() ?? "",
              ContentTypeName: getContentTypeNamesById2(contentType) ?? "",
            }
          );

          setLoader(false);
          //setIsLoading(false);
        } else if (
          response.data?.[0].statusCode == "STCS002" ||
          response.data?.[0].statusCode == "STCS003"
        ) {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg:
              response.data?.[0].statusCode == "STCS002"
                ? Constant.ContentNotAvailable
                : Constant.CONCURRENT_CLOSE_POLL,
            type: "fail",
            isFailed: false,
          });
          setLoader(false);
          //setIsLoading(false);
        }
      })
      .catch((err) => {
        setOpenDialogClose(false);
        console.log(err);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors
            ? err?.response?.data?.Errors
            : Constant.SOMETHING_WENTWRONG,
          type: "fail",
          isFailed: false,
        });
        setLoader(false);
        //setIsLoading(false);
      });
  };
  const savePollResponse = async () => {
    setIsLoading(true);
    let options = checkedPollOptions
      ?.filter((item) => {
        return item?.checked != false;
      })
      ?.map((option) => {
        return option?.optionId;
      });

    let obj = {
      metaDataId: props.item.metaDataId,
      selectedOptionIds:
        props?.item?.contentCustomData?.tileCustomData?.selectionType ==
        "Single"
          ? Object.keys(selectedPollRadioOption)?.length != 0 &&
            selectedPollRadioOption != undefined &&
            selectedPollRadioOption != null && [selectedPollRadioOption]
          : options,
    };
    return await postData(
      obj,
      APIEndPoints.SAVE_POLL_RESPONSE(
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        if (response.data?.[0].statusCode == "STCS001" || response.data == "") {
          //console.log(response);
          if (props?.isFromDeeplink == true) {
            props?.updatePopupData(response.data[0].resultData);
            if (!props?.isBulletinBoard) {
              props?.updateMultiContainerData(
                response.data[0].resultData,
                props?.id,
                props?.sectionName
              );
            }
          } else {
            props?.updateCardData(
              response.data[0].resultData,
              props?.id,
              props?.sectionName
            );
          }

          //TESTED
          TrackEvent(
            props?.item?.customData?.userLevel?.voted
              ? TelemetryEventNames.UCC_CONTENT_EDIT_POLL_CONTENT_EVENT
              : TelemetryEventNames.UCC_CONTENT_SUBMIT_POLL_CONTENT_EVENT,
            userInfo,
            {
              SectionName:
                contentType == 4
                  ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                  : TelemetryEventNames.SECTION_TYPE_CONTENT,
              MetadataId: props?.item?.metaDataId ?? "",
              UniqueId: props?.item?.contentUniqueId ?? "",
              PollType:
                props?.item?.contentCustomData?.tileCustomData?.selectionType ==
                "Single"
                  ? "Single Selection"
                  : "Multiple Selection",
              ContentTypeId: contentType.toString() ?? "",
              ContentTypeName: getContentTypeNamesById2(contentType) ?? "",
            }
          );

          return true;
        } else if (
          response.data?.[0].statusCode == "STCS002" ||
          response.data?.[0].statusCode == "STCS003"
        ) {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg:
              response.data?.[0].statusCode == "STCS002"
                ? Constant.ContentNotAvailable
                : Constant.CONCURRENT_CLOSE_POLL,
            type: "fail",
            isFailed: false,
          });
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors
            ? err?.response?.data?.Errors
            : Constant.POLLS_SUBMIT_FAILURE_MESSAGE,
          type: "fail",
          isFailed: false,
        });
      });
  };
  // const onClickSubmit1 = async () => {
  //   let value = await savePollResponse();
  //   if (value == true) {
  //     props?.item?.contentCustomData?.tileCustomData?.selectionType == "Multiple" ? handleResetCheckboxes() : handleResetRadioOptions();
  //     setIsLoading(false);
  //     setIsSubmitClicked(true);
  //     setDummyFlag(true);
  //   }
  //   else {
  //     setIsLoading(false);
  //   }
  // };
  const onClickSubmitOrEdit = async (isSubmitOrEdit) => {
    if (isSubmitOrEdit === "Submit") {
      let value = await savePollResponse();
      if (value == true) {
        props?.item?.contentCustomData?.tileCustomData?.selectionType ==
        "Multiple"
          ? handleResetCheckboxes()
          : handleResetRadioOptions();
        setIsLoading(false);
        setIsSubmitClicked(true);
        setIsEditResponseClicked(false);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsSubmitClicked(false);
      setIsEditResponseClicked(true);
    }
  };
  // const onClickViewOrBackToResponse = (isViewOrBackToQuestion) => {
  //   if (isViewOrBackToQuestion === "View Results") {
  //     setIsViewResultsClicked(true);
  //     setIsBackToQuestionClicked(false);
  //   } else {
  //     setIsViewResultsClicked(() => { return false; });
  //     setIsBackToQuestionClicked(() => { return true; });
  //     setIsSubmitClicked(() => { return false; });
  //     setDummyFlag(() => { return false; });
  //   }
  // };
  const handleResetCheckboxes = (e, value, fN, isCF, name, index, option) => {
    let allCheckedValues = [...checkedPollOptions];
    let updatedValues = allCheckedValues?.map((option) => {
      return { ...option, checked: false };
    });
    setCheckedPollOptions(updatedValues);
  };
  const handleResetRadioOptions = () => {
    setSelectedPollRadioOption({});
  };
  const GetCardImage = ({ position }) => {
    if (position === "header") {
      if (bulletinBoard === true) return <></>;
    } else {
      if (!bulletinBoard) return <></>;
    }
    return (
      <>
        <Flex
          hAlign="center"
          vAlign="center"
          className="ui-flex-header-banner"
          id={uniqueId}
          onClick={
            bulletinBoard
              ? props.onClick
              : () => props.onClick(props.item, props?.section)
          }
        >
          {props.isImage ? (
            <Image
              alt=""
              role="button"
              src={`${
                imageUrl !== ""
                  ? imageUrl
                  : require("../../assets/EY_Card_Banner.png")
              }`}
              fit="cover"
              tabIndex={0}
              id="ui-card-banner"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  document.getElementById(uniqueId).click();
                }
              }}
              className={
                props?.item?.contentInfo?.[0]?.contentSourceType ==
                Constant.OTHERS
                  ? "promotional-tile"
                  : ""
              }
            />
          ) : (
            <></>
          )}
          {isCustomUserGroup ? (
            <Image
              alt=""
              role="button"
              src={`${
                imageUrl !== ""
                  ? imageUrl
                  : require("../../assets/CustomUserGroup.png")
              }`}
              fit="cover"
              tabIndex={0}
              style={{ cursor: "default" }}
              id="ui-card-banner"
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     document.getElementById(uniqueId).click();
              //   }
              // }}
              className={
                props?.item?.contentInfo?.[0]?.contentSourceType ==
                Constant.OTHERS
                  ? "promotional-tile"
                  : ""
              }
            />
          ) : (
            <></>
          )}

          {props?.item?.contentInfo?.[0]?.contentSourceType ==
            Constant.OTHERS && (
            <div
              id="ui-card-banner-label"
              className="showEllipses promotionalTileTitle"
            >
              <FaFireFlameCurved
                style={{ paddingBottom: "1px", marginBottom: "2px" }}
              />
              &nbsp;
              <span>
                <b>Announcement&nbsp;|&nbsp;No content</b>
              </span>
            </div>
          )}

          {cardImageTitle?.length > 1 && props?.isImage && (
            <CustomizedToolTip content={cardImageTitle}>
              <div
                id="ui-card-banner-label"
                className="showEllipses imageTitle"
                disabled={!config?.authorName?.authorNameEnabled}
              >
                {cardImageTitle}
              </div>
            </CustomizedToolTip>
          )}
        </Flex>
      </>
    );
  };
  const CardTooltipContent = () => (
    <>
      <br />
      <p>
        <strong
          style={{ wordBreak: "break-word", fontSize: "15px !important" }}
        >
          {contentType === 4
            ? props?.item?.title.replace("_", " ")
            : !isCustomUserGroup
            ? props?.item?.title +
              `${
                props?.item?.type?.toString() === "Group"
                  ? ` (${props?.item?.customData?.groupInfo?.totalMembers})`
                  : ""
              }`
            : props?.item?.customUserGroupName}
        </strong>
      </p>
      <br />
      <p style={{ wordBreak: "break-word" }}>{props?.item?.summary}</p>
      <p style={{ wordBreak: "break-word" }}>{props?.item?.description}</p>
      <br />
    </>
  );

  return (
    <div style={{ position: "relative" }}>
      {isLoading && (
        <div className={"layoverToPollCard"}>
          <ReusableLoader pollsLoader="pollsLoader" />
        </div>
      )}
      <Provider theme={teamsTheme}>
        <Flex gap="gap.small" hAlign="center" vAlign="center">
          <Card
            fluid
            role="button"
            tabIndex={0}
            id="ui-card"
            className={`${
              props?.item?.contentInfo?.[0]?.contentSourceType ==
              Constant.OTHERS
                ? "promotional-tile"
                : ""
            } ${
              props?.fixHeight && !bulletinBoard && contentType != 7
                ? "card_height"
                : ""
            }`}
          >
            {isCustomUserGroup && (
              <Card.Header id="ui-card-header">
                <Flex
                  gap="gap.small"
                  hAlign="space-between"
                  vAlign="center"
                  id="ui-flex-header-row"
                  className={`${
                    bulletinBoard ? "bulletinBoardCardHeader" : ""
                  }`}
                >
                  <Flex
                    gap="gap.small"
                    hAlign="end"
                    vAlign="end"
                    id="ui-flex-header-row"
                  >
                    {/* {userInfo?.teams?.featureFlagsObj["Bulletin Board"]
                        ?.isEnabled === true &&
                        props?.item?.type !== "Group"  */}
                    {isCustomUserGroup && (
                      <Flex column>
                        {config?.viewMembersCustomUserGroup
                          ?.viewMembersCustomUserGroupDisplay ? (
                          <>
                            <AvatarUsersList item={props?.item?.userDetails} />
                          </>
                        ) : (
                          <></>
                        )}
                      </Flex>
                    )}
                  </Flex>
                  <Flex
                    gap="gap.small"
                    hAlign="end"
                    vAlign="end"
                    id="ui-flex-header-row"
                  >
                    <Flex column>
                      {props?.item?.isExtendedToAllChannelUsers === true ? (
                        <CustomizedToolTip
                          tabIndex={0}
                          normalTooltip={true}
                          position="below"
                          align="center"
                          content={"Shared group"}
                        >
                          {<MdGroups2 className="sharedgroup" size={25} />}
                        </CustomizedToolTip>
                      ) : (
                        <CustomizedToolTip
                          tabIndex={0}
                          normalTooltip={true}
                          position="below"
                          align="center"
                          content={"My group"}
                        >
                          <RiGroupFill className="selfgroup" size={19} />
                        </CustomizedToolTip>
                      )}
                    </Flex>
                    <Flex column>
                      {((config?.editSelfCustomUserGroup
                        ?.editSelfCustomUserGroupDisplay &&
                        props?.item?.creatorEmail?.toLowerCase() ===
                          userInfo?.email?.toLowerCase()) ||
                        (config?.editAdminCustomUserGroup
                          ?.editAdminCustomUserGroupDisplay &&
                          props?.item?.isExtendedToAllChannelUsers === true) ||
                        (config?.deleteSelfCustomUserGroup
                          ?.deleteSelfCustomUserGroupDisplay &&
                          props?.item?.creatorEmail?.toLowerCase() ===
                            userInfo?.email?.toLowerCase()) ||
                        (config?.deleteAdminCustomUserGroup
                          ?.deleteAdminCustomUserGroupDisplay &&
                          props?.item?.isExtendedToAllChannelUsers ===
                            true)) && (
                        <MenuButton
                          tabIndex={0}
                          trigger={<MdSettings className="mdSettings" />}
                          menu={[
                            ((config?.editSelfCustomUserGroup
                              ?.editSelfCustomUserGroupDisplay &&
                              props?.item?.creatorEmail?.toLowerCase() ===
                                userInfo?.email?.toLowerCase()) ||
                              (config?.editAdminCustomUserGroup
                                ?.editAdminCustomUserGroupDisplay &&
                                props?.item?.isExtendedToAllChannelUsers ===
                                  true)) && {
                              key: "edit",
                              disabled: !(
                                (config?.editSelfCustomUserGroup
                                  ?.editSelfCustomUserGroupEnabled &&
                                  props?.item?.creatorEmail?.toLowerCase() ===
                                    userInfo?.email?.toLowerCase()) ||
                                (config?.editAdminCustomUserGroup
                                  ?.editAdminCustomUserGroupEnabled &&
                                  props?.item?.isExtendedToAllChannelUsers ===
                                    true)
                              ),
                              onClick: onClickEditTile,
                              content: (
                                <div className="editOption">
                                  <MdEdit />
                                  <p>Edit</p>
                                </div>
                              ),
                            },
                            ((config?.deleteSelfCustomUserGroup
                              ?.deleteSelfCustomUserGroupDisplay &&
                              props?.item?.creatorEmail?.toLowerCase() ===
                                userInfo?.email?.toLowerCase()) ||
                              (config?.deleteAdminCustomUserGroup
                                ?.deleteAdminCustomUserGroupDisplay &&
                                props?.item?.isExtendedToAllChannelUsers ===
                                  true)) && {
                              key: "delete",
                              disabled: !(
                                (config?.deleteSelfCustomUserGroup
                                  ?.deleteSelfCustomUserGroupEnabled &&
                                  props?.item?.creatorEmail?.toLowerCase() ===
                                    userInfo?.email?.toLowerCase()) ||
                                (config?.deleteAdminCustomUserGroup
                                  ?.deleteAdminCustomUserGroupEnabled &&
                                  props?.item?.isExtendedToAllChannelUsers ===
                                    true)
                              ),
                              onClick: () => setOpenDialog(true),
                              content: (
                                <div className="deleteOption">
                                  <MdDelete />
                                  <p>Delete</p>
                                </div>
                              ),
                            },
                          ]}
                          className={`menuList ${
                            editClicked == true ? "hideMenu" : ""
                          } ${
                            props?.item?.contentInfo?.[0]?.contentSourceType ==
                            Constant.OTHERS
                              ? "ui-pin-icon-cursor"
                              : ""
                          }`}
                        />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <GetCardImage position={"header"} />
              </Card.Header>
            )}
            {contentType != 7 && !isCustomUserGroup
              ? !bulletinBoard && (
                  <Card.Header id="ui-card-header">
                    <Flex
                      gap="gap.small"
                      hAlign="space-between"
                      vAlign="center"
                      id="ui-flex-header-row"
                      className={`${
                        bulletinBoard ? "bulletinBoardCardHeader" : ""
                      }`}
                    >
                      <Flex
                        gap="gap.small"
                        hAlign="end"
                        vAlign="end"
                        id="ui-flex-header-row"
                      >
                        {userInfo?.teams?.featureFlagsObj["Bulletin Board"]
                          ?.isEnabled === true &&
                          props?.item?.type !== "Group" && (
                            <Flex column>
                              {pinedInfo?.pinnedStatus ? (
                                <CustomizedToolTip
                                  tabIndex={0}
                                  normalTooltip={true}
                                  position="after"
                                  align="center"
                                  content={
                                    <p className="pinned-status">
                                      <span>Pinned by</span>{" "}
                                      <strong> {pinedInfo?.pinnedBy}</strong>
                                      <br />
                                      <span>
                                        {pinedInfo.pinnedDate !== null &&
                                        pinedInfo.pinnedDate !==
                                          "1900-01-01T00:00:00"
                                          ? getDateFromUTC(
                                              pinedInfo?.pinnedDate
                                            )
                                          : "Just now"}
                                      </span>
                                    </p>
                                  }
                                >
                                  <BsPinAngleFill
                                    tabIndex={0}
                                    className={`ui-pin-icon ${
                                      !bulletinBoard ? "ui-pin-icon-cursor" : ""
                                    } ${
                                      config?.unpin?.unpinEnabled
                                        ? "ui-pin-fill"
                                        : "ui-pin-disable-fill"
                                    }`}
                                    onClick={
                                      !bulletinBoard
                                        ? () => onClickPinnedContent(true)
                                        : undefined
                                    }
                                    style={{ cursor: "text" }}
                                  />
                                </CustomizedToolTip>
                              ) : pinInfo && config?.pin?.pinDisplay ? (
                                <MdOutlinePushPin
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === "Enter" &&
                                      config?.pin?.pinEnabled
                                    ) {
                                      handlePinClick(true);
                                    }
                                  }}
                                  onClick={
                                    config?.pin?.pinEnabled
                                      ? () => handlePinClick(true)
                                      : undefined
                                  }
                                  tabIndex={0}
                                  className={` ${
                                    config?.pin?.pinEnabled
                                      ? "ui-pin-icon"
                                      : "ui-pin-disable-icon"
                                  } ${
                                    !bulletinBoard ? "ui-pin-icon-cursor" : ""
                                  }`}
                                />
                              ) : (
                                <></>
                              )}
                            </Flex>
                          )}
                      </Flex>
                      <Flex
                        gap="gap.small"
                        hAlign="end"
                        vAlign="end"
                        id="ui-flex-header-row"
                      >
                        <Flex column>
                          {config?.forYou?.forYouDisplay &&
                          userLevel?.forYou ? (
                            <label
                              className="ui-fy-label"
                              disabled={!config.forYou.forYouEnabled}
                            >
                              For you
                            </label>
                          ) : (
                            ""
                          )}
                        </Flex>
                        <Flex column>
                          {config?.bookmark?.bookmarkDisplay ? (
                            isBookmarkActive ? (
                              <CustomizedToolTip
                                tabIndex={0}
                                normalTooltip={true}
                                position="below"
                                align="center"
                                content={"Bookmark"}
                              >
                                <BsBookmarkFill
                                  tabIndex={0}
                                  onKeyDown={
                                    config.bookmark.bookmarkEnabled
                                      ? handleBookmarkKeyDown
                                      : undefined
                                  }
                                  onClick={
                                    config.bookmark.bookmarkEnabled
                                      ? handleBookmarkClick
                                      : undefined
                                  }
                                  className={`ui-bookmark-icon ${
                                    config.bookmark.bookmarkEnabled
                                      ? "ui-bookmark-fill"
                                      : "ui-bookmark-disable-fill"
                                  } ${
                                    props?.item?.contentInfo?.[0]
                                      ?.contentSourceType == Constant.OTHERS
                                      ? "ui-pin-icon-cursor"
                                      : ""
                                  }`}
                                />
                              </CustomizedToolTip>
                            ) : (
                              <CustomizedToolTip
                                tabIndex={0}
                                normalTooltip={true}
                                position="below"
                                align="center"
                                content={"Bookmark"}
                              >
                                <BsBookmark
                                  tabIndex={0}
                                  onKeyDown={
                                    config.bookmark.bookmarkEnabled
                                      ? handleBookmarkKeyDown
                                      : undefined
                                  }
                                  onClick={
                                    config.bookmark.bookmarkEnabled
                                      ? handleBookmarkClick
                                      : undefined
                                  }
                                  className={` ${
                                    config.bookmark.bookmarkEnabled
                                      ? "ui-bookmark-icon"
                                      : "ui-bookmark-disable-icon"
                                  } ${
                                    props?.item?.contentInfo?.[0]
                                      ?.contentSourceType == Constant.OTHERS
                                      ? "ui-pin-icon-cursor"
                                      : ""
                                  }`}
                                />
                              </CustomizedToolTip>
                            )
                          ) : (
                            ""
                          )}
                        </Flex>
                        <Flex column>
                          {(config?.delete?.deleteDisplay ||
                            config?.edit?.editDisplay) && (
                            <MenuButton
                              tabIndex={0}
                              trigger={<MdSettings className="mdSettings" />}
                              menu={[
                                config?.edit?.editDisplay &&
                                  props.contentSource == "Catalyst" && {
                                    key: "edit",
                                    disabled: !config?.edit?.editEnabled,
                                    onClick: onClickEditTile,
                                    content: (
                                      <div className="editOption">
                                        <MdEdit />
                                        <p>Edit</p>
                                      </div>
                                    ),
                                  },
                                config?.delete?.deleteDisplay &&
                                  props?.item?.contentInfo?.[0]?.contentSourceType?.toLowerCase() !=
                                    "App"?.toLowerCase() && {
                                    key: "delete",
                                    disabled: !config?.delete?.deleteEnabled,
                                    onClick: () => setOpenDialog(true),
                                    content: (
                                      <div className="deleteOption">
                                        <MdDelete />
                                        <p>Delete</p>
                                      </div>
                                    ),
                                  },
                              ]}
                              className={`menuList ${
                                editClicked == true ? "hideMenu" : ""
                              } ${
                                props?.item?.contentInfo?.[0]
                                  ?.contentSourceType == Constant.OTHERS
                                  ? "ui-pin-icon-cursor"
                                  : ""
                              }`}
                            />
                          )}
                        </Flex>
                        <Flex column>
                          {props?.item?.contentSecurity?.availability
                            ?.domainLevel == Constant.LOCAL && (
                            <CustomizedToolTip
                              tabIndex={0}
                              normalTooltip={true}
                              position="above"
                              align="center"
                              content={userInfo?.teams?.companyName}
                            >
                              <Image
                                alt=""
                                fit="cover"
                                width={21}
                                height={21}
                                src={`${
                                  userInfo?.channelLogo !== ""
                                    ? userInfo?.channelLogo
                                    : require("../../assets/Default_Channelogo.png")
                                }`}
                              />
                            </CustomizedToolTip>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                    <GetCardImage position={"header"} />
                  </Card.Header>
                )
              : !isCustomUserGroup && (
                  <Card.Header
                    id="ui-card-header-poll"
                    className={`poll-card-header poll-card-header-${
                      props?.item?.status?.toLowerCase() != "closed"
                        ? props?.item?.status?.toLowerCase()
                        : "no-border-clr"
                    }`}
                  >
                    <Flex
                      gap="gap.small"
                      hAlign="space-between"
                      vAlign="center"
                      space="between"
                      id="ui-flex-subheader"
                    >
                      <Flex
                        gap="gap.small"
                        hAlign="end"
                        vAlign="end"
                        id="ui-flex-header-row"
                      >
                        {!bulletinBoard &&
                          userInfo?.teams?.featureFlagsObj["Bulletin Board"]
                            ?.isEnabled === true &&
                          props?.item?.type !== "Group" && (
                            <Flex column>
                              {pinedInfo?.pinnedStatus ? (
                                <CustomizedToolTip
                                  normalTooltip={true}
                                  position="after"
                                  align="center"
                                  content={
                                    <p className="pinned-status">
                                      <span>Pinned by</span>{" "}
                                      <strong> {pinedInfo.pinnedBy}</strong>
                                      <br />
                                      <span>
                                        {pinedInfo.pinnedDate !== null &&
                                        pinedInfo.pinnedDate !==
                                          "1900-01-01T00:00:00"
                                          ? getDateFromUTC(pinedInfo.pinnedDate)
                                          : "Just now"}
                                      </span>
                                    </p>
                                  }
                                >
                                  <BsPinAngleFill
                                    className={`ui-pin-icon ${
                                      config?.unpin?.unpinEnabled
                                        ? "ui-pin-fill"
                                        : "ui-pin-disable-fill"
                                    } ${
                                      !bulletinBoard ? "ui-pin-icon-cursor" : ""
                                    }`}
                                    // onClick={
                                    //   undefined
                                    //   // config?.unpin?.unpinEnabled
                                    //   //   ? () => setPinOpenDialog(true)
                                    //   //   : undefined
                                    // }
                                    onClick={
                                      !bulletinBoard
                                        ? () => onClickPinnedContent(true)
                                        : undefined
                                    }
                                    style={{ cursor: "text" }}
                                  />
                                </CustomizedToolTip>
                              ) : pinInfo && config?.pin?.pinDisplay ? (
                                <MdOutlinePushPin
                                  onClick={
                                    config?.pin?.pinEnabled
                                      ? () => handlePinClick(true)
                                      : undefined
                                  }
                                  className={` ${
                                    config?.pin?.pinEnabled
                                      ? "ui-pin-icon"
                                      : "ui-pin-disable-icon"
                                  } ${
                                    !bulletinBoard ? "ui-pin-icon-cursor" : ""
                                  }`}
                                />
                              ) : (
                                <></>
                              )}
                            </Flex>
                          )}

                        <Text
                          className={`poll-status-text poll-status-${props?.item?.status?.toLowerCase()}`}
                          content={props?.item?.status}
                          size="small"
                        />
                      </Flex>
                      {!bulletinBoard && (
                        <Flex className="ui-card-poll-right-header">
                          <Flex column>
                            {config?.bookmark?.bookmarkDisplay ? (
                              isBookmarkActive ? (
                                <CustomizedToolTip
                                  tabIndex={0}
                                  normalTooltip={true}
                                  position="below"
                                  align="center"
                                  content={"Bookmark"}
                                >
                                  <BsBookmarkFill
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        //alert(1);
                                      }
                                    }}
                                    onClick={
                                      config.bookmark.bookmarkEnabled
                                        ? handleBookmarkClick
                                        : undefined
                                    }
                                    className={`ui-bookmark-icon ${
                                      config.bookmark.bookmarkEnabled
                                        ? "ui-bookmark-fill"
                                        : "ui-bookmark-disable-fill"
                                    } ${
                                      props?.item?.contentInfo?.[0]
                                        ?.contentSourceType == Constant.OTHERS
                                        ? "ui-pin-icon-cursor"
                                        : ""
                                    }`}
                                  />
                                </CustomizedToolTip>
                              ) : (
                                <CustomizedToolTip
                                  tabIndex={0}
                                  normalTooltip={true}
                                  position="below"
                                  align="center"
                                  content={"Bookmark"}
                                >
                                  <BsBookmark
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        //alert(1);
                                      }
                                    }}
                                    onClick={
                                      config.bookmark.bookmarkEnabled
                                        ? handleBookmarkClick
                                        : undefined
                                    }
                                    className={` ${
                                      config.bookmark.bookmarkEnabled
                                        ? "ui-bookmark-icon"
                                        : "ui-bookmark-disable-icon"
                                    } ${
                                      props?.item?.contentInfo?.[0]
                                        ?.contentSourceType == Constant.OTHERS
                                        ? "ui-pin-icon-cursor"
                                        : ""
                                    }`}
                                  />
                                </CustomizedToolTip>
                              )
                            ) : (
                              ""
                            )}
                          </Flex>
                          <Flex column>
                            {(config?.delete?.deleteDisplay ||
                              config?.close?.closeDisplay ||
                              config?.edit?.editDisplay) && (
                              <MenuButton
                                trigger={<MdSettings className="mdSettings" />}
                                menu={[
                                  config?.edit?.editDisplay &&
                                    (props?.item?.status === "Active" ||
                                      props?.item?.status?.toLowerCase() ===
                                        "Closing"?.toLowerCase()) && {
                                      key: "editdate",
                                      disabled: !config?.edit?.editEnabled,
                                      onClick: () => onClickExtendEndDate(),
                                      content: (
                                        <div className="editOption">
                                          <MdEdit />
                                          <p>Update End Date</p>
                                        </div>
                                      ),
                                    },
                                  config?.close?.closeDisplay &&
                                    (props?.item?.status === "Active" ||
                                      props?.item?.status?.toLowerCase() ===
                                        "Closing"?.toLowerCase()) && {
                                      key: "close",
                                      disabled: !config?.close?.closeEnabled,
                                      onClick: () => {
                                        setOpenDialogClose(true);
                                      },
                                      content: (
                                        <div className="deleteOption">
                                          <MdDisabledVisible />
                                          <p>Close Poll</p>
                                        </div>
                                      ),
                                    },
                                  config?.delete?.deleteDisplay && {
                                    key: "delete",
                                    disabled: !config?.delete?.deleteEnabled,
                                    onClick: () => setOpenDialog(true),
                                    content: (
                                      <div className="deleteOption">
                                        <MdDelete />
                                        <p>Delete Poll</p>
                                      </div>
                                    ),
                                  },
                                ]}
                                className="menuList"
                              />
                            )}
                          </Flex>
                          <Flex column>
                            {props?.item?.contentSecurity?.availability
                              ?.domainLevel == Constant.LOCAL && (
                              <CustomizedToolTip
                                tabIndex={0}
                                normalTooltip={true}
                                position="above"
                                align="center"
                                content={userInfo?.teams?.companyName}
                              >
                                <Image
                                  alt=""
                                  fit="cover"
                                  width={21}
                                  height={21}
                                  src={`${
                                    userInfo?.channelLogo !== ""
                                      ? userInfo?.channelLogo
                                      : require("../../assets/Default_Channelogo.png")
                                  }`}
                                />
                              </CustomizedToolTip>
                            )}
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Card.Header>
                )}
            {isCustomUserGroup && (
              <Card.Body
                id="ui-card-body-usergroup"
                className={`${bulletinBoard ? "bulletinBoardCardBody" : ""}`}
              >
                <Flex column gap="gap.small" id="ui-flex-body">
                  <Flex space="between" id="ui-flex-subheader">
                    <Flex>
                      {props?.item?.modifiedDate ? (
                        <Text
                          content={formatDate(props?.item?.modifiedDate)}
                          size="small"
                          disabled={false}
                        />
                      ) : (
                        <p className="uniqueIdStyles">&nbsp;</p>
                      )}
                    </Flex>
                  </Flex>
                  <div className="titleContainer">
                    {props?.item?.customUserGroupName ? (
                      props?.item?.type?.toString() === "Group" ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            fontWeight: "700",
                            gap: ".5ch",
                          }}
                        >
                          <CustomizedToolTip
                            normalTooltip={true}
                            content={<CardTooltipContent />}
                            setOneLineGroupMemberVal={setOneLineGroupMemberVal}
                            groupMember={true}
                          >
                            <h5
                              className="titleStyles showEllipses grpClass"
                              style={
                                bulletinBoard ? { WebkitLineClamp: 2 } : {}
                              }
                            >
                              {props?.item?.customUserGroupName}
                            </h5>
                          </CustomizedToolTip>
                          {oneLineGroupMember && (
                            <p>
                              {props?.item?.type?.toString() === "Group"
                                ? ` (${props?.item?.customData?.groupInfo?.totalMembers})`
                                : ""}
                            </p>
                          )}
                        </div>
                      ) : (
                        <CustomizedToolTip
                          normalTooltip={true}
                          content={<CardTooltipContent />}
                        >
                          <h5
                            className="titleStyles showEllipses"
                            style={{ WebkitLineClamp: 1 }}
                          >
                            {props?.item?.customUserGroupName}
                          </h5>
                        </CustomizedToolTip>
                      )
                    ) : (
                      <p className="titleStyles">&nbsp;</p>
                    )}
                  </div>
                </Flex>
              </Card.Body>
            )}
            {contentType != 7 && !isCustomUserGroup ? (
              <Card.Body
                id="ui-card-body"
                style={{
                  cursor:
                    props?.item?.contentInfo?.[0]?.contentSourceType ==
                    Constant.OTHERS
                      ? "default"
                      : props.onClick
                          .toString()
                          .includes("handleOnCardClick") ||
                        props?.item?.contentInfo?.[0]?.contentSourceType ==
                          "URL" ||
                        props?.item?.contentInfo?.[0]?.contentSourceType ==
                          "Template"
                      ? "pointer"
                      : "default",
                }}
                onClick={
                  bulletinBoard
                    ? props.onClick
                    : () => props.onClick(props.item, props?.section)
                }
                className={`${bulletinBoard ? "bulletinBoardCardBody" : ""}`}
              >
                <GetCardImage position={"body"} />
                <Flex
                  column
                  gap="gap.small"
                  id="ui-flex-body"
                  className={`${
                    !props?.isImage &&
                    props?.item?.contentInfo?.[0]?.contentSourceType ==
                      Constant.OTHERS &&
                    "search-promotional-tile"
                  }`}
                >
                  <Flex space="between" id="ui-flex-subheader">
                    {/* {config?.uniqueId?.uniqueIdDisplay ? (
                      <Text
                        content={props.item?.contentUniqueId}
                        size="small"
                        disabled={!config.uniqueId.uniqueIdEnabled}
                      />
                    ) : (
                      <p className="uniqueIdStyles">&nbsp;</p>
                    )} */}
                    <Flex>
                      {config?.modifiedDate?.modifiedDateDisplay ? (
                        <Text
                          content={date}
                          size="small"
                          disabled={!config.modifiedDate.modifiedDateEnabled}
                        />
                      ) : (
                        <p className="uniqueIdStyles">&nbsp;</p>
                      )}
                    </Flex>
                  </Flex>
                  <div className="titleContainer">
                    {props?.item?.title ? (
                      props?.item?.type?.toString() === "Group" ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            fontWeight: "700",
                            gap: ".5ch",
                          }}
                        >
                          <CustomizedToolTip
                            normalTooltip={true}
                            content={<CardTooltipContent />}
                            setOneLineGroupMemberVal={setOneLineGroupMemberVal}
                            groupMember={true}
                          >
                            <h5
                              className="titleStyles showEllipses grpClass"
                              style={
                                bulletinBoard ? { WebkitLineClamp: 2 } : {}
                              }
                            >
                              {`${
                                props?.item?.title +
                                " (" +
                                props?.item?.customData?.groupInfo
                                  ?.totalMembers +
                                ")"
                              }`}
                            </h5>
                          </CustomizedToolTip>
                          {oneLineGroupMember && (
                            <p>
                              {props?.item?.type?.toString() === "Group"
                                ? ` (${props?.item?.customData?.groupInfo?.totalMembers})`
                                : ""}
                            </p>
                          )}
                        </div>
                      ) : (
                        <CustomizedToolTip
                          normalTooltip={true}
                          content={<CardTooltipContent />}
                        >
                          <h5
                            className="titleStyles showEllipses"
                            style={bulletinBoard ? { WebkitLineClamp: 2 } : {}}
                          >
                            {contentType === 4
                              ? props?.item?.title.replace("_", " ")
                              : props?.item?.title}
                            {props?.item?.type?.toString() === "Group"
                              ? ` (${props?.item?.customData?.groupInfo?.totalMembers})`
                              : ""}
                          </h5>
                        </CustomizedToolTip>
                      )
                    ) : (
                      <p className="titleStyles">&nbsp;</p>
                    )}
                    {props.item?.contentInfo?.[0]?.contentSourceType ==
                    "URL" ? (
                      <GoLinkExternal
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            document.getElementById(uniqueId).click();
                          }
                        }}
                        title="Go to external site"
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {props?.item?.summary?.length > 1 ? (
                    <p className="summaryStyles showEllipses">&nbsp;</p>
                  ) : contentType === 2 ||
                    contentType === 4 ||
                    contentType === 1 ? (
                    ""
                  ) : (
                    ""
                  )}

                  {props?.item?.description?.length > 1 && contentType === 4 ? (
                    <CustomizedToolTip content={props?.item?.description}>
                      <p className="descriptionStyles showEllipses">
                        {props?.item?.description}
                      </p>
                    </CustomizedToolTip>
                  ) : (
                    ""
                  )}
                  {cardCategory?.length > 1 ? (
                    <>
                      <CustomizedToolTip content={cardCategory}>
                        <p
                          className="cardCategory showEllipses"
                          disabled={!config?.category?.categoryEnabled}
                        >
                          {cardCategory}
                        </p>
                      </CustomizedToolTip>
                    </>
                  ) : contentType === 2 || contentType === 4 ? (
                    ""
                  ) : (
                    ""
                  )}
                </Flex>
              </Card.Body>
            ) : (
              !isCustomUserGroup && (
                <Card.Body
                  tabIndex={0}
                  id="ui-polls-card-body"
                  className={`polls-card-body ${
                    bulletinBoard ? "bulletinBoardPolls" : ""
                  }`}
                  // onClick={() => props.onClick(props.item, props?.section)}
                >
                  {/* <Flex space="between" id="ui-flex-subheader-polls">
                  <Text
                    content="Poll: Names not recorded | Results shared"
                    size="small"
                  />
                </Flex> */}

                  <Flex space="between" id="ui-flex-subheader-dates-polls">
                    {/* <Text
                    content={`Creation date: ${pollCreationDate} 
                              | Closing date: ${getExpirationDate()}
                    `}
                    size="small"
                  /> */}
                    <Text
                      content={`Closing date: ${getExpirationDate()}`}
                      size="small"
                    />
                  </Flex>

                  <Flex column gap="gap.small" id="ui-flex-body-poll">
                    {/* POLLS QUESTION */}
                    <div
                      tabIndex={0}
                      className={`poll-question-option-outer 
                  ${bulletinBoard ? "bulletinBoardPolls" : ""} 
                  ${
                    props?.item?.status === "Closed" &&
                    props?.item?.customData?.userLevel?.voted === false
                      ? "unanswered-poll-outer"
                      : ""
                  } `}
                    >
                      {props?.item?.title ? (
                        <CustomizedToolTip
                          content={formatDecodedURLs(props?.item?.title)}
                        >
                          <p className="polls-titleStyles">
                            {" "}
                            {formatDecodedURLs(props?.item?.title)}{" "}
                          </p>
                        </CustomizedToolTip>
                      ) : (
                        <p className="polls-titleStyles">&nbsp;</p>
                      )}
                      {props?.item?.status === "Closed" &&
                      props?.item?.customData?.userLevel?.voted === true
                        ? renderPollResult(
                            props?.item?.contentInfo[0]?.content?.pollData
                              ?.options,
                            props?.item?.title
                          )
                        : props?.item?.status === "Closed" &&
                          props?.item?.customData?.userLevel?.voted === false
                        ? // renderPollOptions(
                          //   props?.item?.contentInfo[0]?.content?.pollData
                          //     ?.options,
                          //   props?.item?.contentCustomData?.tileCustomData
                          //     ?.selectionType
                          // )
                          renderUnansweredClosedPollImage()
                        : isSubmitClicked === true ||
                          isViewResultsClicked === true
                        ? renderPollResult(
                            props?.item?.contentInfo[0]?.content?.pollData
                              ?.options,
                            props?.item?.title
                          )
                        : isEditResponseClicked === true ||
                          isBackToQuestionClicked === true
                        ? renderPollOptions(
                            props?.item?.contentInfo[0]?.content?.pollData
                              ?.options,
                            props?.item?.contentCustomData?.tileCustomData
                              ?.selectionType
                          )
                        : props?.item?.customData?.userLevel?.voted === true
                        ? renderPollResult(
                            props?.item?.contentInfo[0]?.content?.pollData
                              ?.options,
                            props?.item?.title
                          )
                        : renderPollOptions(
                            props?.item?.contentInfo[0]?.content?.pollData
                              ?.options,
                            props?.item?.contentCustomData?.tileCustomData
                              ?.selectionType
                          )}
                    </div>

                    <div className="poll-button-footer">
                      {config?.submitResponse?.submitResponseDisplay &&
                        (props?.item?.status === "Active" ||
                          props?.item?.status?.toLowerCase() ===
                            "Closing"?.toLowerCase()) &&
                        // !config?.viewResult?.viewResultDisplay &&
                        renderSubmitOrEdit(
                          props?.item?.customData?.userLevel?.voted,
                          props?.item?.contentCustomData?.tileCustomData
                            ?.selectionType
                        )}
                      {/* {config?.viewResult?.viewResultDisplay &&
                      renderSubmitViewResults(
                        props?.item?.status,
                        props?.item?.customData?.userLevel?.voted,
                        props?.item?.contentCustomData?.tileCustomData
                          ?.selectionType
                      )} */}
                    </div>
                  </Flex>
                </Card.Body>
              )
            )}
            {!isCustomUserGroup && (
              <Card.Footer id="ui-card-footer">
                <TaxDetailDeepLink>
                  <ActionBar
                    img={imageUrl}
                    link="https://www.ey.com"
                    title={props?.item?.title}
                    description={props?.item?.summary}
                    {...props}
                    leftAlignActionBarCss={true}
                  />
                </TaxDetailDeepLink>
              </Card.Footer>
            )}
          </Card>
        </Flex>
        {
          <>
            <DialogBox
              cancelButton={"Cancel"}
              onCancelMethod={() => {
                openDialogClose
                  ? setOpenDialogClose(false)
                  : setOpenDialog(false);
              }}
              closeOnOutsideClick={false}
              confirmButton={openDialogClose ? "Close" : "Delete"}
              onConfirmMethod={
                openDialogClose
                  ? onClickClosePoll
                  : isCustomUserGroup
                  ? onClickDeleteCustomUserGroup
                  : onClickDeleteTile
              }
              content={
                loader === true ? (
                  <div className={loader && "loaderLayover"}>
                    <ReusableLoader
                      isOverlay={true}
                      label={
                        <p>
                          {openDialogClose
                            ? Constant.CLOSE_CONTENT
                            : contentType === 7
                            ? Constant.DELETE_POLL
                            : isCustomUserGroup
                            ? Constant.DELETE_CUSTOM_USER_GROUP
                            : Constant.DELETE_CONTENT}
                        </p>
                      }
                      labelPosition={"start"}
                    />
                  </div>
                ) : (
                  <p>
                    {openDialogClose
                      ? Constant.CLOSE_CONTENT
                      : contentType === 7
                      ? Constant.DELETE_POLL
                      : isCustomUserGroup
                      ? Constant.DELETE_CUSTOM_USER_GROUP
                      : contentType === 4
                      ? Constant.DELETE_VISUALIZATION
                      : Constant.DELETE_CONTENT}
                  </p>
                )
              }
              headerName={
                openDialogClose
                  ? "Close Poll"
                  : contentType === 7
                  ? "Delete Poll"
                  : isCustomUserGroup
                  ? "Delete Group"
                  : contentType === 4
                  ? "Delete Visualization"
                  : "Delete Content"
              }
              headerActionName={{
                icon: (
                  <GrClose
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (openDialogClose) {
                          setOpenDialogClose(false);
                        } else {
                          setOpenDialog(false);
                        }
                      }
                    }}
                  />
                ),
                title: "Close",
                onClick: () => {
                  openDialogClose
                    ? setOpenDialogClose(false)
                    : setOpenDialog(false);
                },
              }}
              backdrop={true}
              openDialog={openDialog || openDialogClose}
              className={`dialogDeleteStyles headerStyles ${
                loader && "dialogLayover"
              }`}
            />
            <DialogBox
              cancelButton={"Cancel"}
              onCancelMethod={() => {
                setPinOpenDialog(false);
              }}
              closeOnOutsideClick={false}
              confirmButton={"Unpin"}
              onConfirmMethod={() => {
                handlePinClick(false);
                setLoader(true);
              }}
              content={
                loader === true ? (
                  <div
                    className={
                      loader && "loaderLayover bulletinBoardLoaderLayover"
                    }
                  >
                    <ReusableLoader
                      isOverlay={true}
                      label={
                        <p>
                          The content will be unpinned for everyone who views
                          the bulletin board
                        </p>
                      }
                      labelPosition={"start"}
                    />
                  </div>
                ) : (
                  <p>
                    The content will be unpinned for everyone who views the
                    bulletin board
                  </p>
                )
              }
              headerName={"Unpin this content?"}
              headerActionName={{
                icon: <GrClose />,
                title: "Close",
                onClick: () => {
                  setPinOpenDialog(false);
                },
              }}
              backdrop={true}
              openDialog={openPinDialog}
              className={`dialogDeleteStyles ${loader && "dialogLayover"}`}
            />
            {/* <ModalPopUp
              isOpen={openPinnedDialog}
              isBlocking={false}
              className={"popupModal"}
              popUpTitle={"Content has already been pinned"}
              popUpBody={"Do you want to navigate to the content in the bulletin board page?"}
              footerButtonCancel={"No"}
              footerButtonSubmit={"Yes"}
              onClickPinnedContent={onClickPinnedContent}
              onClickSubmit={navigateToBB}
            /> */}
            <DialogBox
              cancelButton={"No"}
              onCancelMethod={() => {
                onClickPinnedContent(false);
              }}
              closeOnOutsideClick={false}
              confirmButton={"Yes"}
              onConfirmMethod={navigateToBB}
              content={
                "Do you want to navigate to the content in the bulletin board page?"
              }
              headerName={"Content has already been pinned"}
              headerActionName={{
                icon: <GrClose />,
                title: "Close",
                onClick: () => {
                  onClickPinnedContent(false);
                },
              }}
              backdrop={true}
              openDialog={openPinnedDialog}
              className={`dialogDeleteStyles pinnedContent ${
                loader && "dialogLayover"
              }`}
            />
          </>
        }
      </Provider>
    </div>
  );
};

export default CardExampleSize;
