import {
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  SerializedLexicalNode,
  LexicalEditor,
  DecoratorNode,
} from "lexical";

import LinkPreviewComponent from "../component/LinkPreviewComponent";

export type SerializedLinkPreviewNode = {
  title?: string;
  link?: string;
  img?: string;
  contentType?: number;
} & SerializedLexicalNode;

/**
 * Custom LinkPreview node
 */
export class LinkPreviewNode extends DecoratorNode<JSX.Element> {
  __title?: string;
  __link?: string;
  __img?: string;
  __contentType?: number;

  constructor(
    title?: string,
    link?: string,
    img?: string,
    contentType?: number,
    key?: NodeKey
  ) {
    super(key);
    this.__title = title;
    this.__link = link;
    this.__img = img;
    this.__contentType = contentType;
  }

  static getType(): string {
    return "linkpreview";
  }

  setTitle(title: string): void {
    super.getWritable().__title = title;
  }

  getTitle(): string | undefined {
    return super.getLatest().__title;
  }

  setLink(link?: string): void {
    super.getWritable().__link = link;
  }

  getLink(): string | undefined {
    return super.getLatest().__link;
  }

  setImg(img?: string): void {
    super.getWritable().__img = img;
  }

  getImg(): string | undefined {
    return super.getLatest().__img;
  }

  setContentType(contentType?: number): void {
    super.getWritable().__contentType = contentType;
  }

  getContentType(): number | undefined {
    return super.getLatest().__contentType;
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <LinkPreviewComponent
        title={this.__title}
        link={this.__link}
        contentType={this.__contentType}
        img={this.__img}
        linkPreviewNode={this}
      />
    );
  }

  static clone(node: LinkPreviewNode): LinkPreviewNode {
    return new LinkPreviewNode(
      node.__title,
      node.__link,
      node.__img,
      node.__contentType,
      node.__key
    );
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement("div");
    element.className = config.theme.linkPreview;
    return element;
  }

  updateDOM(
    _prevNode: unknown,
    _dom: HTMLElement,
    _config: EditorConfig
  ): boolean {
    return false;
  }

  exportJSON(): SerializedLinkPreviewNode {
    return {
      title: this.getTitle(),
      link: this.getLink(),
      img: this.getImg(),
      contentType: this.getContentType(),
      type: this.getType(),
      version: 1,
    };
  }

  static importJSON(jsonNode: SerializedLinkPreviewNode): LinkPreviewNode {
    const node = $createLinkPreviewNode(jsonNode.title,jsonNode.link,jsonNode.img,jsonNode.contentType);
    return node;
  }
}

export function $createLinkPreviewNode(title?:string,link?:string,img?:string,contentType?:number): LinkPreviewNode {
  return new LinkPreviewNode(title,link,img,contentType);
}

export function $isLinkPreviewNode(node: LexicalNode): node is LinkPreviewNode {
  return node instanceof LinkPreviewNode;
}
