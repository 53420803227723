import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdGroup, MdOutlineSearch, MdTipsAndUpdates, MdCloudUpload } from "react-icons/md";
import { MoreIcon } from "@fluentui/react-northstar";
import MenuButton from "../../commonui/menubutton/menuButton";
import AddLeaveTeamDialogBoxes from "../usermanagement/addremovesearchuser/AddLeaveComponents/AddLeaveTeamDialogBoxes";
import NotificationIcon from "../notification/notificationIcon";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { UseUserManagementContext } from "../../context/usermanagementcontext/userManagementContext";
import { useMenuContext } from "../../context/menucontext/menuContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../services/appInsights";
import { checkIfDeviceIsMob } from "../../utility/utility";
import "./contactUsIcon.css";

function ContactUsIcon() {
  const { handleChangeNav, menuState } = useMenuContext();
  const navigate = useNavigate();
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const [contactUsFlag, setContactUsFlag] = useState();
  const [channelCreationFlag, setChannelCreationFlag] = useState();
  const [pendingApprovalsFlag, setPendingApprovalsFlag] = useState();

  const contactUsKeyDown = (event) => {
    if (event.keyCode === 13) {
      navigate("/contactUs");
    }
  };
  useEffect(() => {
    //console.log(userInfo);
    let contactusDetails = userInfo.teams.companyFeatureFlags.find((value) => {
      return (
        value?.featureFlagName.toLowerCase() === "Contact Us".toLowerCase()
      );
    });
    setContactUsFlag(contactusDetails);
    let channelCreationDetails = userInfo.teams.companyFeatureFlags.find(
      (value) => {
        return (
          value?.featureFlagName.toLowerCase() ===
          "Channel Management".toLowerCase()
        );
      }
    );
    setChannelCreationFlag(channelCreationDetails);
    let pendingApprovalsDetails = userInfo.teams.companyFeatureFlags.find(
      (value) => {
        return (
          value?.featureFlagName.toLowerCase() ===
          "Pending Approvals".toLowerCase()
        );
      }
    );
    setPendingApprovalsFlag(pendingApprovalsDetails);
  });

  const { usersData, handleDataChange } = UseUserManagementContext();
  // const { channelsData, handleDataChange } = UseChannelManagementContext();

  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { addMemberDialogOpen, leaveCompanyDialogOpen } = usersData;
  const [loader, setLoader] = useState(false);

  const handleDialogToggle = (name, val) => {
    handleDataChange({ [name]: val });
  };

  const handleTakeaTourDialogToggle = () => {
    handleUserInfoChange({ takethetour: !userInfo.takethetour });
  };

  const handleChannelLogoDialogToggle = () => {
    handleUserInfoChange({ uploadchannellogo: !userInfo.uploadchannellogo });
  };

  const handleChannelManagementChange = () => {
    let filterCompany = userInfo.allCompanies.filter((item) => {
      return item.companyName === "EY Global Only";
    });
    handleUserInfoChange({
      teams: filterCompany[0],
    });
    window.localStorage.setItem(
      "curUserSelectedCompany",
      JSON.stringify(filterCompany[0])
    );
    navigate(`/channelManagement?prevPath=${window.location.pathname}`);
  };

  const getTeamLink = () => {
    TrackEvent(TelemetryEventNames.UCC_UMM_COPY_TEAM_LINK, userInfo, {
      SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
    });
  };
  const menu = [
    userInfo?.teams?.featureFlagsObj["User Management"]?.isEnabled &&
      checkIfDeviceIsMob() && {
        key: "User Management",
        onClick: () => {
          navigate(`/yourchannel?prevPath=${window.location.pathname}`);
        },
        content: (
          <div className="userManagementMenu">
            <MdGroup />
            <p>User Management</p>
          </div>
        ),
      },
    {
      key: "Search",
      onClick: () => {
        handleChangeNav({
          section: {
            id: "Searchlibraries",
            name: "",
          },
          menu: {
            id: "Searchlibraries",
            name: "Search libraries",
          },
          submenu: {
            id: "",
            name: "",
          },
          data: "",
          to: "/",
        });

        navigate(`/search?prevPath=${window.location.pathname}`);
      },

      content: (
        <div className="li-item-search">
          <MdOutlineSearch />
          <p>Search Libraries</p>
        </div>
      ),
    },
    userInfo.teams.featureFlagsObj["Notification"]?.isEnabled &&
      checkIfDeviceIsMob() && {
        key: "Notification",
        disabled: !contactUsFlag?.isEnabled,
        onClick: () => {
          document.getElementById("notificationOption").click();
        },
        content: (
          <div className="li-item-NotificationOption">
            <NotificationIcon />
          </div>
        ),
      },
    {
      key: "whatsCatalyst",
      disabled: !contactUsFlag?.isEnabled,
      onClick: () => {
        handleChangeNav({
          section: {
            id: "",
            name: "",
          },
          menu: {
            id: "",
            name: "What's Catalyst",
          },
          submenu: {
            id: "",
            name: "Overview",
          },
          data: "",
          to: "/",
        });

        navigate(`/eycatalyst?prevPath=${window.location.pathname}`);
      },
      content: (
        <div className="li-item-whatsCatalyst">
          <img
            className="contactus_icon"
            src={require("../../assets/whatscatalysticon.svg").default}
            alt="whatscatalyst img"
          />
          <p>What's Catalyst</p>
        </div>
      ),
    },
    contactUsFlag?.isEnabled &&
      userInfo?.isInternal && {
        key: "channelCreation",
        //disabled: !contactUsFlag?.isEnabled,
        onClick: () => {
          //navigate("/channelCreation");
          // const companyId = userInfo?.teams?.companyId;
          if (userInfo?.teams?.companyName === "EY Global Only") {
            navigate(`/channelManagement?prevPath=${window.location.pathname}`);
          } else {
            handleChannelManagementChange();
          }
        },
        content: (
          <div className="contactUsOption">
            <img
              className="contactus_icon"
              src={
                require("../../assets/ic_fluent_channel_48_filled.svg").default
              }
              alt="Channel Management img"
            />
            {/* <AiFillQuestionCircle /> */}
            <p>Channel Management</p>
          </div>
        ),
      },
    contactUsFlag?.isEnabled &&
      pendingApprovalsFlag?.isEnabled && {
        key: "pendingApprovals",
        //disabled: !contactUsFlag?.isEnabled,
        onClick: () => {
          //navigate("/channelCreation");
          navigate(`/pendingApprovals?prevPath=${window.location.pathname}`);
        },
        content: (
          <div className="contactUsOption">
            <img
              className="contactus_icon"
              src={require("../../assets/pending.svg").default}
              alt="pending approvals img"
            />
            {/* <AiFillQuestionCircle /> */}
            <p>Pending Approvals</p>
          </div>
        ),
      },
    contactUsFlag?.isEnabled && {
      key: "contactUs",
      disabled: !contactUsFlag?.isEnabled,
      onClick: () => {
        navigate(`/contactUs?prevPath=${window.location.pathname}`);
      },
      content: (
        <div onKeyDown={contactUsKeyDown} className="contactUsOption">
          <img
            className="contactus_icon"
            src={require("../../assets/contactus.svg").default}
            alt="contact us img"
          />
          {/* <AiFillQuestionCircle /> */}
          <p>Contact Us</p>
        </div>
      ),
    },
    {
      key: "Tips Wizard",
      onClick: () => {
        handleTakeaTourDialogToggle();
      },
      content: (
        <div className="userManagementMenu">
          <MdTipsAndUpdates />
          <p>Tips Wizard</p>
        </div>
      ),
    },
    userInfo?.teams?.featureFlagsObj["Upload Company Logo"]?.isEnabled && {
      key: "Upload Channel Logo",
      onClick: () => {
        handleChannelLogoDialogToggle();
      },
      content: (
        <div className="userManagementMenu">
          <MdCloudUpload />
          <p>Upload Channel Logo</p>
        </div>
      ),
    },

    // userInfo?.teams?.featureFlagsObj["Get Channel Link"]?.isEnabled && {
    //     key: "Get link to team",
    //     onClick: getTeamLink,
    //     content: (
    //         <div className="userManagementMenu">
    //             <MdOutlineLink />
    //             <p>Get link to team</p>
    //         </div>
    //     ),
    // },
  ];
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          document.getElementById("contactus_icon").click();
        }
      }}
    >
      {/* {contactUsFlag?.isEnabled && ( */}
      {menu?.filter((i) => i !== false)?.length > 0 && (
        <MenuButton
          trigger={
            <MoreIcon
              style={{ padding: "5px 0", cursor: "pointer" }}
              id="contactus_icon"
            />
          }
          menu={menu}
          className="menuList"
        />
      )}
      {/* )} */}

      <AddLeaveTeamDialogBoxes />
    </div>
  );
}

export default ContactUsIcon;
