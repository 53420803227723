import React, { useState, createContext, useContext } from "react";

export const contactUsContextInfo = createContext({
    menuInfo: {},
    handleMenuInfo: (props) => {}
});

export function UseContactUsContext() {
    return useContext(contactUsContextInfo);
}

const ContactUsContext = ({ children }) => {
    const [menuInfo, setMenuInfo] = useState({
        menuName:"",
        subMenuName:"",
        originTab:"",
        resourceType:{contentId:5, label:"Conferences", value:"Conferences"},
        isClickedClose:false,
        //channel management changes
        issueDescription: {
            clientId: "",
            clientName: "",
            dunsNumber: ""
        },
        sourceComponent: ""
    });

    const handleMenuInfo = (props) => {
        setMenuInfo((prevData) => {
            return { ...prevData, ...props };
        });
    };

    return (
        <contactUsContextInfo.Provider value={{ menuInfo, handleMenuInfo }}>
            {children}
        </contactUsContextInfo.Provider>
    );
};

export default ContactUsContext;
