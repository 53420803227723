import React, { useState, createContext, useContext } from "react";

const searchContextData = createContext({
  searchData: {},
  handleSearchChange: (props) => {},
  // contentType: null,
  handleSelectionChange: (props) => {},
  handleSelectedItemMenu:(props) =>{}
});

//create hook contexts
export function UseSearchContext() {
  return useContext(searchContextData);
}

//context provider and states
const SearchContext = ({ children }) => {
  // userinfo state
  const [searchData, setSearchData] = useState({

    pageLength: 10,
    pageNumber: 1,
    sortBy: {
      type: "",
      order: "desc",
    },
    filters: {
      searchText: "",
      categories: [],
      startDate: null,
      endDate: null,
      isBookmarked: false,
      isForYou: false,
    },
    contentType: null
  });

  const [contentType, setContentType] = useState(1)
  const [selectItemMenu, setSelectItemMenu] = useState('Conferences')
  const [loadingContentType, setLoadingContentType] = useState(false)

  //change event handler functions

  const handleSearchChange = (props) => {
    setSearchData((prevData) => {
      return { ...prevData, ...props };
    });
  };

  const handleSelectedItemMenu =(item)=>{
    setSelectItemMenu(item)
  }

  const handleSelectionChange = (props) => {
    setContentType(props)
  }

  return (
    <searchContextData.Provider value={{ searchData, handleSearchChange, contentType, handleSelectionChange, selectItemMenu, handleSelectedItemMenu, loadingContentType, setLoadingContentType}}>
      {children}
    </searchContextData.Provider>
  );
};

export default SearchContext;
