import React, { useState, useRef, useEffect } from 'react';
import "./booleanExpressionEditor.css";
function BooleanExpressionEditor({ expression, textBoxChange, className, setUpdateEditorValue, updateEditorValue }) {

  const lastKeyPress = useRef("");
  const editorRef = useRef(null);
  const selectionPointsEditor = useRef({
    startingPoint: 0,
    endingPoint: 0
  });
  useEffect(() => {
  if (updateEditorValue?.updateEditor && editorRef.current) {
    if (expression?.length > 0) {
      editorRef.current.innerText = expression;
      const content = editorRef?.current?.innerHTML;

      const andRegex = /AND/g;
      const orRegex = /OR/g;
      const notRegex = /NOT/g;

      const newContent = content
        .replace(andRegex, `<span style="color: green;font-weight:bold;">AND</span>`)
        .replace(orRegex, `<span style="color: orange;font-weight:bold;">OR</span>`)
        .replace(notRegex, `<span style="color: red;font-weight:bold;">NOT</span>`);

      editorRef.current.innerHTML = newContent;
      let position = selectionPointsEditor.current?.startingPoint; //by default the starting point is taken for cursor position
      if ((lastKeyPress.current === "(" || lastKeyPress.current === ")" || lastKeyPress.current === " ")
        && updateEditorValue?.inputSuccess) {
        position = selectionPointsEditor.current?.startingPoint + 1;//cursor is moved 1 position to right once text is inserted        
      }
      else if (lastKeyPress.current?.toLowerCase() == "backspace"
        && selectionPointsEditor.current.startingPoint == selectionPointsEditor.current.endingPoint
        && updateEditorValue?.inputSuccess) {
        position = selectionPointsEditor.current?.startingPoint - 1; //cursor is moved 1 position to left when a single character is deleted using backspace
      }
      const range = document.createRange();
      const selection = window.getSelection();
      const node = editorRef.current;
      let charCount = 0, foundStart = false;
      function traverseNodes(node) {
        if (node.nodeType === 3) { // Text node
          const nextCharCount = charCount + node.length;
          if (!foundStart && position >= charCount && position <= nextCharCount) {
            range.setStart(node, position - charCount);
            foundStart = true;
          }
          charCount = nextCharCount;
        } else {
          for (let i = 0; i < node.childNodes.length; i++) {
            traverseNodes(node.childNodes[i]);
            if (foundStart) break;
          }
        }
      }
      traverseNodes(node);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    else
       editorRef.current.innerHTML = "";
    setUpdateEditorValue({ updateEditor: false, inputSuccess: false })
  }
}, [expression, updateEditorValue]);
  const handleKeyDown = (event) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(editorRef.current);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      const start = preCaretRange.toString().length;
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      const end = preCaretRange.toString().length;
      selectionPointsEditor.current = {
        startingPoint: start,
        endingPoint: end
      }
    }
    lastKeyPress.current = event.key;
  };

  const handleInput = (e) => {
    textBoxChange(lastKeyPress.current, selectionPointsEditor.current, editorRef.current.innerText);
  };

  return (
    <div>
      <div
        ref={editorRef}
        contentEditable
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        className={`${className} booleanExpressionEditor`}
      />

    </div>
  );
};
export default BooleanExpressionEditor;