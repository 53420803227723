import React from "react";
import { ThemeProvider, createTheme } from "@fluentui/react";
import CompanySelector from "./CompanySelector";

export const CompanySelectorThemeProvider = () => {
  const theme = createTheme({
    palette: {
      themePrimary: "#f5f5f5", // Set your desired focus color here
    },
  });
  return (

      <CompanySelector />

  );
}; 
