import React from "react";
import "./tab.css";
const Tab = (props) => {
  const { onClick, tabName, active } = props;
  return (
    <div
      onClick={!active ? onClick : undefined}
      className={`navBarTabs__right-section__tabs__tab ${
        active ? "navBarTabs__right-section__tabs__tab__active" : ""
      }`}
    >
      {tabName}
    </div>
  );
};

export default Tab;
