import React from "react";
import PopupBox from "../../commonui/popup/popup";
import ShareLink from "./sharelink/shareLink";
import "./sharePopup.css";

function SharePopup(props) {
  return (
    <PopupBox
      title="Share link"
      isFooter={false}
      isBorderNeeded={true}
      isModalOpen={props.isModalOpen}
      hideModal={props.hideModal}
      className="sharePopupStyle"
    >
      <ShareLink
        showCopyLinkPopUpModal={props.showCopyLinkPopUpModal}
        showSendLinkPopUpModal={props.showSendLinkPopUpModal}
        {...props}
      />
    </PopupBox>
  );
}

export default SharePopup;
