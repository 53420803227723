import React, { useEffect, useState } from "react";
import APIEndPoints from "../../utility/apiendpoints";
import { getDataById } from "../../services/customApis";
import serviceReportData from "../../data/pinlibrary/reportContent.json";

export const PinLibraryContext = React.createContext();

export function PinLibraryProvider(props) {
  const [pinLibraryData, setPinLibraryData] = useState([]);
  const [taxnewalertConfig, setNewTaxAlertConfig] = useState([]);
  const [taxtopalertConfig, setTopTaxAlertConfig] = useState([]);
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [cardConfig, setCardConfig] = useState([]);
  const [allPowerBiReportsItems, setPowerBiReportsItems] = useState([]);
  const [powerBiReportActionConfig, setReportActionConfig] = useState({});
  // const params = useParams();

  const addReportItems = (newReportItems) => {
    setPowerBiReportsItems([...newReportItems]);
  };

  const getConfigForReport = (reportConfig) => {
    setReportActionConfig(reportConfig);
  };

  // console.log(serviceReportData);
  useEffect(() => {
    const getTaxDataConfig = async () => {
      const response = await getDataById(4, APIEndPoints.CONTENT_CONFIG);
      //checking for pin library
      if (response["data"].content[0].contentTypeId === 4) {
        setNewTaxAlertConfig(
          {...response["data"].content[0].subContentTypes[0]?.contentCategories?.[0]?.featureFlagsDetails, ...response["data"].content[0].subContentTypes[0]?.contentCategories?.[1]?.featureFlagsDetails}
        );
      }
    };
  }, []);

  return (
    <PinLibraryContext.Provider
      value={{
        data,
        isFetching,
        pinLibraryData,
        taxnewalertConfig,
        taxtopalertConfig,
        setCardConfig,
        cardConfig,
        setPinLibraryData,
        setTopTaxAlertConfig,
        setData,
        setIsFetching,
        allPowerBiReportsItems,
        addReportItems,
        powerBiReportActionConfig,
        setReportActionConfig,
      }}
      {...props}
    />
  );
}
