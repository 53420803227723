import React, { useRef, useEffect, useState } from 'react';
import APIEndPoints from '../../../utility/apiendpoints';
import { postData } from '../../../services/customApis';

function CustomReport({ updateLoader, openItems, htmlString, setHtmlString, customReportPayload }) {
    const containerRef = useRef(null);
    const [plotlyLoaded, setPlotlyLoaded] = useState(false);
    const [htmlStrings, setHtmlStrings] = useState("");

    const scriptIdentifier = "custom_report_dynamic_script";

    useEffect(() => {
        if (htmlString) {
            containerRef.current.innerHTML = htmlString;
            const scriptTags = containerRef.current.querySelectorAll("script");
            scriptTags.forEach((script) => {
                const newScript = document.createElement("script");
                newScript.textContent = script.textContent;
                newScript.setAttribute("data-id", scriptIdentifier);
                document.body.appendChild(newScript);
                document.body.removeChild(newScript);
            });
            updateLoader();
        }
    }, [htmlString, plotlyLoaded]);

    useEffect(() => {
        if (!document.querySelector('script[src="https://cdn.plot.ly/plotly-latest.min.js"]')) {
            const script = document.createElement('script');
            script.src = "https://cdn.plot.ly/plotly-latest.min.js";
            script.async = true;
            script.onload = () => {
                setPlotlyLoaded(true);
            };
            script.onerror = () => {
                setPlotlyLoaded(false);
            };
            document.body.appendChild(script);
        } else {
            setPlotlyLoaded(true);
        }
        return () => {
        };
    }, []);


    async function myCustomFunction(chartId, orgName, multi_org = null) {


        let chartIdDiv = "";
        if (multi_org) {
            chartIdDiv = multi_org;
        }
        else {
            chartIdDiv = chartId + '_single_org';
        }
        let chartDiv = document.getElementById(chartIdDiv);

        if (chartDiv) {
            chartDiv.innerHTML = ' <div style=" width: 100%; height: 300px;margin-bottom:50px;"><div class="custom_report_loader"></div> </div>';
        }
        let formData = new FormData();
        let payload = {
            filters: {
                chartFilters: {
                    chartType: chartId,
                    organizationName: orgName
                },
                fields: {
                    P_0037: [btoa(orgName)]
                }
            },
            customData: {},
            environment: window.location.hostname.includes("dev") ? "Dev" : window.location.hostname.includes("qa") ? "QA" : ""
        };

        let formDataObj = customReportPayload.formData;
        formData.append("appId", formDataObj.appId);
        formData.append("templateId", formDataObj.templateId);
        formData.append("requestJson", JSON.stringify(payload));
        formData.append("searchQuery", formDataObj.searchQuery);
        formData.append("visualizationReportId", formDataObj.visualizationReportId);
        const response = await postData(
            formData,
            APIEndPoints.GET_CUSTOM_REPORTS(
                customReportPayload?.contentTypeId,
                customReportPayload.companyId
            ),
            true
        ).then((response) => {
            console.log("Response: ", response);
            let res = atob(response.data);


            // chartIdDiv = chartId + chartIdDiv;

            if (chartDiv) {
                chartDiv.innerHTML = res;
                const scriptTags = chartDiv.querySelectorAll("script");
                scriptTags.forEach((script) => {
                    const newScript = document.createElement("script");
                    newScript.textContent = script.textContent;
                    newScript.setAttribute("data-id", scriptIdentifier);
                    document.body.appendChild(newScript);
                    document.body.removeChild(newScript);
                });
            }
            setTimeout(() => {
                setHtmlStrings(res);
            }, 200);
        });

    }
    const onDrillThrough = (chartId, data) => {
        var organizationName = data;
        console.log("Organization Name: ", organizationName);
        document.getElementById(chartId + '_btn')?.click();
        myCustomFunction(chartId, organizationName);
        addDropdownEventListner();

    };

    function handlePlotlyClick(data, plotDiv) {
        data.event.preventDefault();
        var organizationName = data.points[0].text;
        // myCustomFunction(organizationName);
        const contextMenu = document.getElementById("context-menu");
        const orgNameId = document.getElementById("orgName");
        orgNameId.innerText = organizationName;

        // event.preventDefault(); // Prevent default right-click menu

        // Retrieve all <li> elements inside the context menu
        const listItems = contextMenu.querySelectorAll('li');

        // Array to store the `data-action` values
        const actions = [];

        // Iterate over each <li> and fetch the data-action attribute
        listItems.forEach((li) => {
            li.setAttribute('data-action', organizationName);
        });
        // Set the position of the menu
        contextMenu.style.left = `${data.event.pageX}px`;
        contextMenu.style.top = `${data.event.pageY - 250}px`;
        setTimeout(() => { contextMenu.style.display = "block"; }, 200);
        // contextMenu.style.display = "block";

    }

    useEffect(() => {

        setTimeout(() => {
            var plotDivs = document.getElementsByClassName('plotly-graph-div');
            let plotDiv;
            if (plotDivs.length !== 0) {
                plotDiv = plotDivs[0];
                // Add click event listener
                plotDiv.removeEventListener("plotly_click", handlePlotlyClick);
                plotDiv.on('plotly_click', (data) => handlePlotlyClick(data, plotDiv));

            }
        }, 2000);

        return () => {
            const plotDivs = document.getElementsByClassName("plotly-graph-div");
            if (plotDivs.length !== 0) {
                let plotDiv = plotDivs[0];

                plotDiv.removeEventListener("plotly_click", handlePlotlyClick);

            }
        };

    }, [htmlString, containerRef.current, plotlyLoaded]);

    useEffect(() => {

        if (plotlyLoaded) {
            const script2 = document.createElement('script');
            const topbar = document.getElementById("topbar");
            const topbarHeight = topbar?.getBoundingClientRect()?.height; // Height of the topbar
            const viewportHeight = window?.innerHeight; // Total visible height of the viewport
            const remainingHeight = viewportHeight - topbarHeight - 120; // Remaining visible height

            console.log("Remaining visible height:", remainingHeight);
            script2.textContent = `
            function resizePlotlyCharts() {
                setTimeout(() => {
                    let parentDiv = document.getElementById("customReportContainerId")
              
                    document.querySelectorAll('.js-plotly-plot').forEach(function (chart) {
                         Plotly.relayout(chart, { height: `+ remainingHeight + `,width:parentDiv.offsetWidth-30 }); 
                    });
                }, 1000);
            }
                window.addEventListener('resize', resizePlotlyCharts);
                 resizePlotlyCharts() 
                `;
            script2.setAttribute("data-id", scriptIdentifier);
            document.body.appendChild(script2);

            setTimeout(() => {
                const contextMenu = document.getElementById('context-menu');
                if (contextMenu) {
                    contextMenu.addEventListener('click', (event) => {
                        // Check if the clicked element is an <li>
                        const clickedItem = event.target;
                        if (clickedItem.tagName === 'LI') {

                            const actionType = clickedItem.getAttribute('action-type');
                            const dataAction = clickedItem.getAttribute('data-action');

                            // Call the custom function with the extracted attributes
                            onDrillThrough(actionType, dataAction);
                            contextMenu.style.display = "none";
                        }
                    });

                    document.addEventListener("click", () => {
                        contextMenu.style.display = "none";
                        // addDropdownEventListner();
                    });

                    const containers = document.querySelectorAll('.custom_report_container');

                    containers.forEach((container) => {
                        container.addEventListener('click', () => {
                            contextMenu.style.display = "none";
                        });
                    });

                }
                addDropdownEventListner();
            }, 2000);



        }
    }, [plotlyLoaded, containerRef.current, htmlString, htmlStrings]);

    const addDropdownEventListner = () => {

        const dropdowns = document.querySelectorAll(".custom_report_dropdown_class");
        if (dropdowns) {
            dropdowns.forEach((dropdown) => {
                dropdown.addEventListener("change", function () {
                    const selectedValue = JSON.parse(this.value); // Parse the JSON value
                    myCustomFunction(selectedValue.tab_id, selectedValue.val, selectedValue.id);

                });
            });
        }
    };
    useEffect(() => {

        setTimeout(() => {
            if (openItems?.includes("2") && containerRef) {
                if (!containerRef.current.innerHTML) {
                    containerRef.current.innerHTML = htmlString;
                    const scriptTags = containerRef.current.querySelectorAll("script");
                    scriptTags.forEach((script) => {
                        const newScript = document.createElement("script");
                        newScript.textContent = script.textContent;
                        newScript.setAttribute("data-id", scriptIdentifier);
                        document.body.appendChild(newScript);
                        document.body.removeChild(newScript);
                    });
                }
            }
        }, [300]);

        return () => {
            const scriptsToRemove = document.querySelectorAll(
                `script[data-id="${scriptIdentifier}"]`
            );
            scriptsToRemove.forEach((script) => {
                document.body.removeChild(script);
            });
        };
    }, [openItems, containerRef.current]);

    useEffect(() => {
        const topbar = document.getElementById("topbar");
        const footnote = document.getElementById("footnotesAccordionBody"); // Access by id

        if (footnote) {
            const topbarBottom = topbar.getBoundingClientRect().bottom + window.scrollY;
            const footnotePosition = footnote.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
                top: footnotePosition - topbarBottom + 50,
                behavior: "smooth", // Smooth scrolling animation
            });
        } else {
            console.error("Element with id 'footnotesAccordionBody' not found.");
        }
    }, [htmlString]);
    return (
        <div
            ref={containerRef}
            onClick={e => e.stopPropagation()}
            id="customReportContainerId"
        >
        </div>
    );
}

export default CustomReport;