import React, { useState, useEffect } from "react";
import DataGrid from "../../../commonui/datagrid/dataGrid";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import { utils } from "../../../commonui/datagrid/utils";
import { useMenuContext, useHamburgerMenuContext, } from "../../../context/menucontext/menuContext";
import sideNavMainMenuData from "../../../data/menu/sideNavMainMenuData";
import APIEndPoints from "../../../utility/apiendpoints";
import { postData } from "../../../services/customApis";
import PageNotFound from "../../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../../commonui/unauthorizedpage/unauthorized";
import { useLocation, Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Constant from "../../../utility/constants";
import { getPrivilegeValue, checkIfDeviceIsMob } from "../../../utility/utility";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import "./databaseGrid.css";

const PAGE_SIZE = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.PAGE_SIZE;
const EDIT_CONFIG_LIMIT = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.EDIT_LIMIT;
const DOWNLOAD_CONFIG_LIMIT = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.DOWNLOAD_LIMIT;
const DatabaseGrid = () => {
     const location = useLocation();
     let internalAppDetails = location.state?.appDetails;
     let privilagesForInternalApp = location.state?.privilagesPowerKApp ?? {};
     let templateDetails = location.state?.templateDetails ?? {};
     let { userInfo } = UseUserInfoContext();
     const [loading, setLoading] = useState(false);
     const [hasMoreData, sethasMoreData] = useState(true);
     const [pageNo, setPageNo] = useState(1);
     const [gridData, setGridData] = useState(undefined);
     const { menuState, handleChangeNav } = useMenuContext();
     const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
     const [currentUser, setCurrentUser] = useState(userInfo);
     const [errorAccessDenied, setAccessDenied] = useState(false);
     const [errorApiCall, setErrorApiCall] = useState(false);
     const [disableDownload, setDisableDownload] = useState(false);
     const [editConfigLimit, setEditConfigLimit] = useState(EDIT_CONFIG_LIMIT);
     const [searchFilterObjectForPayload, setSearchFilterObjectForPayload] = useState({})
     const { handleNotificationMsgsData } = UseToasterNotificationsContext();

     const loadMoreData = (pageNo_) => {
          setPageNo(pageNo_ + 1);
     };

     const setBreadCrumbValue = () => {
          menuState?.menu?.name === "Apps" && handleChangeNav({
               section: {
                    id: sideNavMainMenuData[3]?.id,
                    name: "",
               },
               menu: {
                    id: sideNavMainMenuData[3]?.menu[0]?.id,
                    name: "Apps",
               },
               submenu: {
                    id: sideNavMainMenuData[3]?.menu[0]?.submenu[0]?.id,
                    name: internalAppDetails?.breadCrumb,
               },
               data: "",
               to: "/apps/DatabaseGrid",
          });
     };
     const toggleHamburgerMenu = () => {
          handleHamburgerChange();
     }
     const getGridData = async (reloadTheGrid = false, pgNo) => {
          let searchFiltersString = JSON.stringify(searchFilterObjectForPayload);
          let searchFiltersEncoded = JSON.parse(searchFiltersString)
          Object.entries(searchFiltersEncoded).forEach(([key, values]) => {
               if (Array.isArray(values)) {
                    searchFiltersEncoded[key] = values.map(value => btoa(unescape(encodeURIComponent(value))));
               }
             });
          if (!internalAppDetails) {
               setLoading(true);
               return;
          }

          let pNo = reloadTheGrid ? pgNo : pageNo;
          let requestJson = {
               pageLength: PAGE_SIZE,
               pageNumber: pNo,
               sortBy: {
                    type: "",
                    order: "",
               },
               filters: {
                    fileDetail: {},
                    fields: searchFiltersEncoded,
               }
          };
          let params = {
               appId: internalAppDetails.appID,
               templateId: templateDetails?.templateId?.toString() ?? "",
               requestJson: JSON.stringify(requestJson),
               attachments:[]
          };
          try {
               const resultInternalAppData = await postData(
                    params,
                    APIEndPoints.CONTENT_DATASOURCE_POWERK(internalAppDetails?.contentTypeId, userInfo?.teams?.companyId)
               );

               let allSections = resultInternalAppData?.data[0]?.resultData?.sections?.all;
               let resultData = allSections?.length > 0 ? allSections : [];
               if (resultData && resultData.length > 0) {
                    const lastRow = resultData[resultData.length - 1];
                    const pageInfo = lastRow?.customData?.pageinfo;
                    if (pageInfo && pageInfo.rowIndex === pageInfo.totalCount) {
                         sethasMoreData(false);
                    }
               }
               return resultData;
          } catch (error) {
               console.log(error);
               setLoading(false);
               if (error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied.") {
                    setAccessDenied(true);
               } else {
                    setErrorApiCall(true);
               }
          }
     };
     //whenever the below method gets called with true as param e.g getInitialData(true) ,the grid will reload from the begining
     const getInitialData = async (reloadTheGrid = false) => {
          if (userInfo?.teams?.key) {
               setLoading(true);
               let data = await getGridData(reloadTheGrid, 1);
               reloadTheGrid
                    ? setGridData([...data])
                    : setGridData([...gridData, ...data]);
               setLoading(false);
          }
     };
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     useEffect(() => {
          if (toggleHamburger) {
               toggleHamburgerMenu();
          }
          setBreadCrumbValue();
          if (pageNo !== 1) {
               getInitialData();
          }
     }, [pageNo]);

     useEffect(() => {
          setTimeout(() => {
               if (menuState.submenu.name === internalAppDetails?.breadCrumb) {
                    getInitialData(true);
                    setCurrentUser(userInfo);
               } else {
                    if (currentUser.teams.key !== userInfo.teams.key
                    ) {
                         resetGrid();
                         setCurrentUser(userInfo);
                    }
               }
          }, 1000);
     }, [menuState.submenu.name]);

     const contextualMenuOptions = {
          OrganizationName: [
               { label: "A to Z", sortOrder: "ASC", hasIcons: true, },
               { label: "Z to A", sortOrder: "DESC", hasIcons: true, },
          ],
          AgreementType: [
               { label: "A to Z", sortOrder: "ASC", hasIcons: true, },
               { label: "Z to A", sortOrder: "DESC", hasIcons: true, },
          ],
     };
     //reset the grid
     const resetGrid = () => {
          setPageNo(1);
          setGridData([]);
          sethasMoreData(true);
          getInitialData(true);
     };

     const createColumn = (key, name, fieldName, minWidth, maxWidth, type = null) => ({
          key,
          name,
          fieldName,
          ariaLabel: name,
          minWidth: window.innerWidth >= 900 ? minWidth : maxWidth,
          maxWidth: window.innerWidth >= 900 ? minWidth : maxWidth,
          isResizable: true,
          isMultilineEnable: true,
          type,
          isFiltered: true
     });

     let columnsForPowerDatabaseGrid = [
          createColumn("organizationName", "Organization Name", "organizationName", 200, 200),
          createColumn("organizationAddress", "Organization Address", "organizationAddress", 200, 200),
          createColumn("accessionNumber", "Accession Number", "accessionNumber", 150, 200),
          createColumn("type", "Document Type", "documentType", 120, 120),
          createColumn("filingType", "Filing Type", "filingType", 100, 100),
          createColumn("fileName", "File Name", "fileName", 150, 150),
          createColumn("fileNumber", "File Number", "fileNumber", 100, 200),
          createColumn("filingDate", "Filing Date", "filingDate", 150, 200, "Date"),
          createColumn("status", "Status", "status", 150, 150, "Status"),
          createColumn("category", "Category", "category", 150, 200),
          createColumn("subCategory", "Contract Sub-Category", "subCategory", 150, 200),
          createColumn("agreementType", "Agreement Type", "type", 150, 200),
          createColumn("explanation", "Explanation", "explanation", 150, 200),
     ];

     let columnsForTPAlertsGrid = [
          createColumn("title", "Title", "title", 200, 200),
          createColumn("publishedDate", "Published Date", "publishedDate", 200, 200, "Date"),
          createColumn("modifiedDate", "Modified Date", "modifiedDate", 200, 200, "Date"),
          createColumn("category", "Category", "category", 200, 200),
          createColumn("status", "Status", "status", 200, 200, "Status"),
     ];

     let columnsDBGrid;
     let textForBackButton;
     let routeForBackButton;
     const contentTypeConfigDatabasePageDatabase = {
          [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: {
            columnsDBGrid: columnsForPowerDatabaseGrid,
            textForBackButton: Constant.INTERNALAPP_CONSTANTS.DATABASE_BACK_TO_LIBRARY,
            routeForBackButton: "/apps/LibrarySearchPage",
          },
          [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: {
            columnsDBGrid: columnsForTPAlertsGrid,
            textForBackButton: Constant.BACK_TO_LIBRARY_LABEL,
            routeForBackButton: "/apps/LibraryGrid",
          },
          [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: {
               columnsDBGrid: columnsForPowerDatabaseGrid,
               textForBackButton: Constant.INTERNALAPP_CONSTANTS.DATABASE_BACK_TO_LIBRARY,
               routeForBackButton: "/apps/LibrarySearchPage",
          },
          // Add other contentTypeIDs and their corresponding configurations here
     };
     const contentTypeId = internalAppDetails?.contentTypeId;
     const config = contentTypeConfigDatabasePageDatabase[contentTypeId];
     if (config) {
          columnsDBGrid = config.columnsDBGrid;
          textForBackButton = config.textForBackButton;
          routeForBackButton = config.routeForBackButton;
     } else {
          console.error("Unsupported contentTypeID:", contentTypeId);
     }

     const STATUS_CLASSES = {
          [Constant.STATUS_CONSTANTS.STATUS_NEW]: "status_pill_new",
          [Constant.STATUS_CONSTANTS.STATUS_IN_PROCESS]: "status_pill_in_progress",
          [Constant.STATUS_CONSTANTS.STATUS_PROCESSED]: "status_pill_processed",
          [Constant.STATUS_CONSTANTS.STATUS_PROMPTED]: "status_pill_prompted",
          [Constant.STATUS_CONSTANTS.STATUS_PUBLISHED]: "status_pill_published",
          [Constant.STATUS_CONSTANTS.STATUS_COMPLETED]: "status_pill_completed",
          [Constant.STATUS_CONSTANTS.STATUS_SKIPPED]: "status_pill_skipped",
          [Constant.STATUS_CONSTANTS.STATUS_FAILED]: "status_pill_failed",
     };
     const handleSelection = (items, selectionCount) => {
          const isMaxSelection = selectionCount === editConfigLimit;
          if (isMaxSelection) {
               handleNotificationMsgsData({
                    showMsgBar: true,
                    started: false,
                    completed: true,
                    msg: `Maximum ${editConfigLimit} files allowed.`,
                    type: "info",
                    isFailed: false,
               });
          } else {
               setDisableDownload(selectionCount <= DOWNLOAD_CONFIG_LIMIT);
          }
     };
     const downloadFileFromBase64Url = (base64Url, filename) => {
          try {
               // Convert base64 to array buffer
               const binaryString = window.atob(base64Url);
               const bytes = new Uint8Array(binaryString.length);
               for (let i = 0; i < binaryString.length; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
               }
               const arrayBuffer = bytes.buffer;

               // Create blob obj and download file
               const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
               if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, filename);
               } else {
                    const link = document.createElement("a");
                    if (link.download !== undefined) {
                         const url = URL.createObjectURL(blob);
                         link.setAttribute("href", url);
                         link.setAttribute("download", filename);
                         link.style.visibility = "hidden";
                         document.body.appendChild(link);
                         link.click();
                         document.body.removeChild(link);
                    }
               }
               return true;
          } catch (error) {
               console.error('Error downloading file:', error);
               showNotification(Constant.INTERNALAPP_CONSTANTS.DATABASE_DOWNLOAD_FAILED_MSG, "Fail");
               return false;
          }
     }

     const showNotification = (msg, type) => {
          handleNotificationMsgsData({
               showMsgBar: true,
               started: false,
               completed: true,
               msg,
               type,
               isFailed: false,
          });
     };
     const contentTypeHandlers = {
          [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: (item, baseData) => ({
              ...baseData,
              filingNumber: item?.fileNumber,
              documentType: item?.documentType,
              filingType: item?.filingType,
              fileDetail: JSON.parse(item.fileDetail)?.fileDetail,
          }),
          [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: (item, baseData) => ({
              ...baseData,
              fileDetail: item?.attachments?.documentInfo?.[0]?.fileDetail ?? item?.fileDetail?.fileDetail,
          }),
          [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: (item, baseData) => ({
               ...baseData,
               filingNumber: item?.fileNumber,
               documentType: item?.documentType,
               filingType: item?.filingType,
               fileDetail: JSON.parse(item.fileDetail)?.fileDetail,
          }),
          // Add more handlers here as needed
     };
     
     const mapItemToFileData = (item, contentType) => {
          const baseData = {
              metaDataId: item?.documentMetaDataId,
          };
          const handler = contentTypeHandlers[contentType];
          if (handler) {
              return handler(item, baseData);
          }
          return baseData;
     };

     const handleDownload = async (items) => {
          showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_INIT_MSG, "Info");
          let selectedItemsFileData = items?.map((item) =>
               mapItemToFileData(item, internalAppDetails?.contentTypeId)
          );

          const params = {
               appId: internalAppDetails?.appID,
               fileData: selectedItemsFileData,
          };

          try {
               const response = await postData(
                    params,
                    APIEndPoints.DOWNLOAD_DATABASE_FILES(
                         internalAppDetails?.contentTypeId,
                         userInfo?.teams?.companyId
                    )
               );

               if (response.status !== 200) {
                    showNotification(
                         Constant.INTERNALAPP_CONSTANTS.DATABASE_DOWNLOAD_FAILED_MSG,
                         "Fail"
                    );
                    return;
               }

               const item = response?.data;
               if (!item) {
                    showNotification(
                         Constant.INTERNALAPP_CONSTANTS.DATABASE_DOWNLOAD_FAILED_MSG,
                         "Fail"
                    );
                    return;
               }

               const isDownload = downloadFileFromBase64Url(
                    item.fileStream,
                    item.fileName
               );
               const message = isDownload
                    ? item?.responseMessage
                    : Constant.INTERNALAPP_CONSTANTS.DATABASE_DOWNLOAD_FAILED_MSG;
               const type = isDownload ? "Success" : "Fail";
               showNotification(message, type);
          } catch (error) {
               console.error(error);
               const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
               const message =
                    errorMessage ||
                    Constant.INTERNALAPP_CONSTANTS.DATABASE_DOWNLOAD_FAILED_MSG;
               if (errorMessage === "access denied.") {
                    setAccessDenied(true);
               } else {
                    showNotification(message, "Fail");
               }
          }
     };
     const renderColumns = (fieldContent, column, item) => {
          switch (column.type) {
               case "Status":
                    const statusClass = STATUS_CLASSES[fieldContent] || "status_pill_new";
                    return (
                         <span
                              data-selection-disabled={true}
                              className={`status_pill ${statusClass}`}
                         >
                              {fieldContent}
                         </span>
                    );
               case "Date":
                    return <span>{utils.convertDate(fieldContent, false)}</span>;
               case "LetterCase":
                    return <span>{fieldContent?.toLowerCase()}</span>;
               default:
                    return <span>{fieldContent}</span>;
          }
     };
     const columnFilteringUpdate = (fieldName, fieldNameFilters, applyOrClearFilter,fieldType) => {
          setLoading(true);
          let createOrUpdateValues;
          if (fieldNameFilters.length > 0) {
               if (searchFilterObjectForPayload.hasOwnProperty(fieldName)) {
                    createOrUpdateValues = searchFilterObjectForPayload;
                  } else {
                    createOrUpdateValues = searchFilterObjectForPayload;
                  }
                  
                  if (fieldType === Constant.INTERNALAPP_CONSTANTS.DATABASE_FIELD_TYPE_DATE) {
                    createOrUpdateValues[fieldName] = fieldNameFilters.map((item) => utils.formatDate(item.name,item.format));
                  } else {
                    createOrUpdateValues[fieldName] = fieldNameFilters.map((item) => item.name);
                  }
                  
                  setSearchFilterObjectForPayload(() => createOrUpdateValues);
          }
          else {
               delete searchFilterObjectForPayload[fieldName];
               createOrUpdateValues = searchFilterObjectForPayload;
               setSearchFilterObjectForPayload(() => { return searchFilterObjectForPayload });

          }
          setPageNo(1);
          getInitialData(true);
     }
     if (errorApiCall) return <PageNotFound />;
     if (errorAccessDenied) return <UnauthorizedPage />;
     return (
          <div className="powerKDatabase_container ">
               <div className={`powerKDatabase_cover ${!loading && "hide_cover_powerKDB"}`}>
                    <ReusableLoader />
               </div>
               <div className="powerKDatabase_content">
                    {gridData && (<div className="container_powerkDataSource common_container_subtitle_powerKDB">
                         <div className="common_container_rightMenu_powerKDB">
                              <div className="divTxtSubmenu_powerKDB">
                                   <header style={{ maxWidth: "100%" }}><p style={{ display: "flex" }}>
                                        {
                                             <>
                                                  <span className="commonTitleEllipses_powerKDB showEllipses_powerKDB">
                                                       {Constant.INTERNALAPP_CONSTANTS.DATABASE_GRID_NAME}
                                                  </span>
                                             </>
                                        }
                                   </p>
                                   </header>
                              </div>
                              <div className="backToLibraryLink_powerKDB">
                                   <Link to={routeForBackButton} state={{ appDetails: internalAppDetails, privilagesPowerKApp: privilagesForInternalApp, templateDetails: templateDetails }} className="linkStyleDetails_powerKDB"> <IoArrowBack /> {textForBackButton}</Link>
                              </div>
                         </div>
                    </div>)
                    }
                    {gridData && (
                         <DataGrid
                              _columnsFromProps={columnsDBGrid}
                              _data={gridData}
                              _contextualMenuOptionsFromProps={contextualMenuOptions}
                              handleDownload={handleDownload}
                              loadMoreData={loadMoreData}
                              hasMoreData={hasMoreData}
                              pageNo={pageNo}
                              renderColumns={renderColumns}
                              handleSelection={(items, selectionCount) => {
                                   handleSelection(items, selectionCount);
                              }}
                              appDetails={internalAppDetails}
                              disableDownload={!disableDownload}
                              displayDownload={!checkIfDeviceIsMob() ? getPrivilegeValue(privilagesForInternalApp, 'download', 'display'): false}
                              configLimit={editConfigLimit}
                              privilagesInternalApp={privilagesForInternalApp}
                              fileID="documentMetaDataId"
                              pageRoute="Database"
                              templateDetails={templateDetails}
                              columnFilteringUpdateMethodDataGrid={(fieldName, fieldNameFilters, applyOrClearFilter,fieldType) => {
                                   columnFilteringUpdate(fieldName, fieldNameFilters, applyOrClearFilter,fieldType)
                              }}
                              searchFilters={searchFilterObjectForPayload}
                              appSearchQueryValue={""}
                         />
                    )}
               </div>
          </div>
     );
};

export default DatabaseGrid;
