import React, { useEffect, useState, useRef } from "react";
import { RadioGroup } from "@fluentui/react-northstar";
import "./radioButtons.css";
import { MdError } from "react-icons/md";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";

let svgIcon = `<svg stroke="currentColor" 
              fill="currentColor" stroke-width="0" viewBox="0 0 24 24" color="rgb(196, 49, 75)" 
              height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" 
              style="color: rgb(196, 49, 75);"><path fill="none" d="M0 0h24v24H0z">
              </path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z">
              </path></svg>`;

function RadioButtons(props) {
  const [currentValue, setCurrentValue] = useState(props?.defaultCheckedValue);
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const [items, setSetItems] = useState(props?.items);
  const [isGlobal, setIsGlobal] = useState(props?.isGlobalContentCompany);
  const allValidations = props?.validations;
  const radioRef = useRef(null);
  useEffect(() => {
    if (props.editMode === true) {
      setCurrentValue(props?.value);
      props.updateValidationState(
        props?.name,
        props?.value,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });

    }
  }, [props?.value]);

  useEffect(() => {
    setCurrentValue(props?.value);
  }, [props?.value]);

  useEffect(() => {
    if (props?.setDisabled) {
      let newItems = items?.map((item) => {
        return {
          ...item,
          disabled: props?.isInternalApp == true ? true : props?.value === "Tiles" ? item?.value !== props?.value : item?.value === "Tiles"
        };
      });
      setSetItems(newItems);

    }
    else if (props?.name === "userLevel") {
      let newItems = props?.items;
      if (props?.disabled) {
        newItems = props?.items?.map((item) => {
          return {
            ...item,
            disabled: props?.isInternalApp == true ? true : false
          };
        });
      }
      props?.edit ? setSetItems(props?.domainLevel === "Global" ? newItems?.slice(0, 3) : newItems?.slice(3)) : setSetItems(isGlobal ? newItems?.slice(0, 3) : newItems?.slice(3));
      props.updateValidationState(
        props?.name,
        currentValue,
        false,
        props?.errMessage
      );
    }

    else {
      setSetItems(props?.items);
    }
  }, [currentValue, props?.items?.[0]?.label]);
  useEffect(() => {
    setError(props.hasAnyError);
    if (typeof props.errMessage == "string") {
      setErrorMessage(props.errMessage);
    } else {
      setErrorMessage("");
      let nodeIndex = props?.errMessage?.Key;
      let allNodes = radioRef.current.childNodes;
      let leftPix = allNodes?.[nodeIndex]?.getBoundingClientRect();
      const newElement = document.createElement("div");
      newElement.id = "diverror-" + props?.items[nodeIndex]?.value;
      newElement.className = "diverrorClass";
      newElement.style.position = "relative";
      newElement.style.left = leftPix?.left - 42 + "px";
      newElement.innerHTML =
        svgIcon +
        "<span class='spnError'>" +
        props.errMessage.ErrorMessage +
        "</span>";
      //allNodes[nodeIndex].appendChild(newElement);
      document.querySelectorAll(".srcLocation")[0].append(newElement);
      allNodes[nodeIndex].classList.add("errorindicator");
    }
  }, [props?.hasAnyError]);

  const handleRadiobuttonCheck = (
    ev,
    val,
    fieldName,
    name,
    isContentData,
    isCustomData,
    parentName
  ) => {
    if (allValidations != undefined && allValidations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        allValidations,
        val.value
      );
      setError(hasError);
      setErrorMessage(errMessage);
      props?.onCheckedValueChange(
        val.value,
        fieldName,
        name,
        isContentData,
        hasError,
        errorMessage,
        isCustomData,
        parentName,
        val.data
      );
    } else {
      props?.onCheckedValueChange(
        val.value,
        fieldName,
        name,
        isContentData,
        false,
        "",
        isCustomData,
        parentName,
        val.data
      );
    }
    setCurrentValue(val.value);
    if (document.getElementsByClassName("diverrorClass").length > 0) {
      document.getElementsByClassName("diverrorClass")[0].remove();
    }
  };

  useEffect(() => {
    props?.onCheckedValueChange(
      currentValue,
      props?.fieldsName,
      props?.name,
      props?.isContentData,
      false,
      "",
      props?.isCustomdata,
      props?.parentName
    );
  }, []);

  return (
    <>
      <RadioGroup
        tabIndex={0}
        defaultCheckedValue={props?.defaultCheckedValue}
        items={items}
        ref={radioRef}
        data-testid={props?.name}
        styles={{ width: props?.items?.length > 2 ? "100%" : "50%" }}
        onCheckedValueChange={(ev, val) => {
          handleRadiobuttonCheck(
            ev,
            val,
            props?.fieldsName,
            props?.name,
            props?.isContentData,
            props?.isCustomdata,
            props?.parentName
          );
        }}
        vertical={props?.name === "userLevel" ? true : props?.vertical}
        className={props?.className}
        checkedValue={currentValue}
        id={props?.id}
      />

      {hasAnyError && errorMessage != "" && typeof errorMessage != "object" && (
        <>
          <div>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </div>
        </>
      )}
    </>
  );
}

export default RadioButtons;
