import React, { useState } from "react";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import {
  useSideNavbarDataContext,
  useHamburgerMenuContext,
} from "../../context/menucontext/menuContext";
import { UseNotificationContext } from "../../context/notificationcontext/notificationContext";
import Menu from "./menu/menu";
import "./sideBar.css";
import Section from "./section";

function Sidebar() {
  const { userInfo } = UseUserInfoContext();
  const { toggleHamburger } = useHamburgerMenuContext();
  const { sideNavbarData } = useSideNavbarDataContext();
  const { notifications } = UseNotificationContext();
  const [activeSectionIndex, setActiveSectionIndex] = useState(null);

  const toggleOpenSection = (index) => {
    setActiveSectionIndex(index === activeSectionIndex ? null : index);
  };

  return (
    <>
      <div
        className={`${userInfo.loggedInWeb ? "sidebar-web" : "sidebar"}  ${
          toggleHamburger ? "wd_lg scrollbar" : "wd_sm"
        }`}
        style={
          !toggleHamburger && notifications.isFullPageNotification
            ? { width: "1px" }
            : {}
        }
        id="uniqueSideBarId"
        role="complementary"
        aria-label="Sidebar"
      >
        <div className="allSideMenus">
          {sideNavbarData.map((section, index) => {
            const isActive = index === activeSectionIndex;
            const panelId = `panel-${index}`;
            const headingId = `heading-${index}`;

            return (
              <div
                className="menu"
                key={section.id}
                style={
                  !toggleHamburger
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                role="menu"
              >
                <div
                  role="button"
                  id={headingId}
                  aria-expanded={isActive}
                  aria-controls={panelId}
                  onClick={() => toggleOpenSection(index)}
                >
                  <Section section={section} isActive={isActive} />
                </div>
                {isActive && (
                  <div
                    id={panelId}
                    aria-labelledby={headingId}
                    className="menu_component"
                  >
                    {section?.menu?.map((menu) => {
                      return (
                        <Menu
                          section={activeSectionIndex}
                          key={menu.id}
                          menu={menu}
                          setShowSection={setActiveSectionIndex}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
