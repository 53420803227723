import { app, pages } from "@microsoft/teams-js";
import Constant from "./constants";

export const navigateBackToHome = () => {
    app.getContext().then((item) => {

        let pageState = {
            from: "SearchTabSidebar",
            to: Constant.REDIRECTION_PATH,
        };
        let params = {
            // @ts-ignore
            tabName: "Home",
            appId: Constant.GetUserAppId(item.user.userPrincipalName),
            pageId: Constant.HomeTab,
            subPageId: pageState,
        };
        pages.navigateToApp(params);
    });
};