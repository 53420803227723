import React, { createContext, useState, useContext } from "react";

const TooltipContext = createContext();

export const TooltipProvider = ({ children }) => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  return (
    <TooltipContext.Provider value={{ activeTooltip, setActiveTooltip }}>
      {children}
    </TooltipContext.Provider>
  );
};

export const useTooltipContext = () => useContext(TooltipContext);
