import React from "react";
import "./noUserGroupsAvailable.css";


function NoUserGroupsAvailable() {
  return (
    <div className="nousergroups_container">
      <img src={require("../../assets/icon.png")} alt="" />
      <p className="nousergroups_containerHeader">
        You don't have any groups yet
      </p>
      <p className="nousergroups_containerSubtext">        
        Creating groups helps you to manage your interactions better. <br /> Click 'Add
        New' to start creating your first group. <br /> Let's get started!
      </p>
    </div>
  );
}

export default NoUserGroupsAvailable;
