import React from 'react';

const LinkComponent = ({ notification }) => {
    const renderHeaderWithButtons = () => {
        const { header, formatValues } = notification;

        // Split the header string into parts using the "{n}" placeholders
        const parts = header.split(/(\"{\d+}\")/);

        // Map through the parts and replace placeholders with buttons
        const renderedHeader = parts.map((part, index) => {
            const match = part.match(/\"{(\d+)}\"/);

            if (match) {
                const placeholderIndex = parseInt(match[1], 10);

                return (
                    <React.Fragment key={index}>
                        <span onClick={() => handleButtonClick(formatValues[placeholderIndex].Value)}
                            style={{ color: "#155cb4", textDecoration: "underline", cursor: "pointer" }}>
                            {formatValues[placeholderIndex].name}
                        </span>
                    </React.Fragment>
                );
            }

            return part;
        });

        return renderedHeader; // Return the array directly
    };

    const handleButtonClick = (value) => {
        // Your logic when a button is clicked, for example, opening a link or performing an action
        window.open(value, '_blank'); // Opens the link in a new tab
    };

    return (
        <div>
            <div>{renderHeaderWithButtons()}</div>
            {/* Render other notification details as needed */}
        </div>
    );
};

export default LinkComponent;
