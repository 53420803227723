import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  MenuOption,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  TextNode,
} from 'lexical';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';

class EmojiOption extends MenuOption {

  emoji: string;

  constructor(
    emoji: string,

  ) {
    super(emoji);

    this.emoji = emoji;
  }
}
function EmojiMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: EmojiOption;
}) {
  let className = 'item';
  if (isSelected) {
    className += ' selected';
  }
  return (
    <li
      key={option.key}
      tabIndex={0}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
      <span >
        {option.emoji}
      </span>
    </li>
  );
}

type Emoji = {
  emoji: string;
};


export default function EmojiPickerPlugin() {
  const [editor] = useLexicalComposerContext();
  const [emojis, setEmojis] = useState<Array<Emoji>>([]);

  useEffect(() => {
    // @ts-ignore

    const emojiArray: Emoji[] = [];

    for (let i = 0x1F600; i <= 0x1F64F; i++) {
      emojiArray.push({
        emoji: String.fromCodePoint(i)
      });
    }

    // Hand emojis
    for (let i = 0x1F440; i <= 0x1F5FF; i++) {
      emojiArray.push({
        emoji: String.fromCodePoint(i)
      });
    }
    setEmojis(emojiArray);
  }, []);

  const emojiOptions = useMemo(
    () =>
      emojis != null
        ? emojis.map(
          ({ emoji }) =>
            new EmojiOption(emoji),
        )
        : [],
    [emojis],
  );



  const options: Array<EmojiOption> = useMemo(() => {
    return emojiOptions;
    // .slice(0, MAX_EMOJI_SUGGESTION_COUNT);
  }, [emojiOptions]);

  const onSelectOption = useCallback(
    (
      selectedOption: EmojiOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void,
    ) => {
      editor.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection) || selectedOption == null) {
          return;
        }

        if (nodeToRemove) {
          nodeToRemove.remove();
        }

        selection.insertNodes([$createTextNode(selectedOption.emoji)]);

        closeMenu();
      });
    },
    [editor],
  );


  return (<>
    {<div className=" emoji-menu">
      <ul>
        {options.map((option: EmojiOption, index) => (
          <div key={option.key} tabIndex={0} onKeyDown={(e) => {if(e.key === "Enter"){onSelectOption(option, null, () => { })}}}>
            <EmojiMenuItem
              index={index}
              isSelected={false}
              onClick={() => {
                onSelectOption(option, null, () => { });
              }}
              onMouseEnter={() => {
              }}
              option={option}
            />
          </div>
        ))}
      </ul>
    </div>}

  </>
  );
}
