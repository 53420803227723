import { createContext, useContext, useState } from 'react';
const librarySearchFormObj = {
    jsonStructure:{},
    resetLibrarySearchFormJson:{},
    isBackToSearchClicked:false,
    powerKLibrarySearchGridData:[],
    powerKLibrarySearchGridDataLastRow: {},
    powerKBooleanExpression:"",
    columnFilteringApplied: false,
    appliedFilteredValues:{},
    searchQuery:"",
    totalRecordsCount:0,
    specialCharacters:"",
    prevPath: "/",
};
const librarySearchForm = createContext({
    librarySearchFormJsonData: librarySearchFormObj,
    handleLibrarySearchFormJsonData: (props) => { },
});

export const LibrarySearchFormContext = ({ children }) => {
  const [librarySearchFormJsonData, setLibrarySearchFormJsonData] = useState(librarySearchFormObj);
  const handleLibrarySearchFormJsonData = (props) => {
    setLibrarySearchFormJsonData((prevData) => {
      return { ...prevData, ...props };
    });
  };
  return (
    <librarySearchForm.Provider value={{ librarySearchFormJsonData, handleLibrarySearchFormJsonData }}>
      {children}
    </librarySearchForm.Provider>
  );
};

export const UseLibrarySearchFormContext = () => useContext(librarySearchForm);
export default LibrarySearchFormContext;
