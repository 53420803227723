/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { BreadCrumbContext } from "../../../context/breadcrumbcontext/BreadCrumbContext";
import { useMenuContext } from "../../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
//import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { TrackEvent, TrackPageView } from "../../../services/appInsights";
import TelemetryEventNames from "../../../data/apptelemetry/appTelemetry.const";
import Badge from "../badge/badge";
import SubMenu from "../submenu/subMenu";
import { useHamburgerMenuContext } from "../../../context/menucontext/menuContext";

import "./menu.css";
import { capitalizeWords } from "../../../utility/utility";
import Constant from "../../../utility/constants";
import { app, pages } from "@microsoft/teams-js";
import { UseContactUsContext } from "../../../context/contactuscontext/contactUsContext";
import { scrollIntoViewWithOffset } from "../../../utility/menuscroll";

const Menu = (props) => {
  const { menuInfo, handleMenuInfo } = UseContactUsContext();
  const { menu, section, setShowSection } = props;
  const { ResetBreadCrumb3LevelTitle } = useContext(BreadCrumbContext);
  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  const [linkOffset, setLinkOffset] = useState(-100);
  const { menuState, handleChangeNav } = useMenuContext();
  const { userInfo } = UseUserInfoContext();
  const [showMenu, setShowMenu] = useState(false);
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  setShowSection(true); // for content author changes
  const showSubmenu = () => {
    setToggleSubMenu(!toggleSubMenu);
  };

  const handleMenuChange = (
    MenuId,
    MenuName,
    subMenuId,
    subMenuName,
    data = "",
    to
  ) => {
    handleChangeNav({
      section: { id: section.id, name: section.title },
      menu: { id: MenuId, name: MenuName },
      submenu: { id: subMenuId, name: subMenuName },
      data: data,
      to: to,
    });

    if (menuInfo.menuName) {
      handleMenuInfo({
        menuName: "",
        subMenuName: "",
        originTab: "",
        resourceType: {
          contentId: 5,
          label: "Conferences",
          value: "Conferences",
        },
        isClickedClose: false,
        sourceComponent: "",
      });
    }
    setToggleSubMenu(true);
    //TESTED
    TrackPageView(TelemetryEventNames.UCC_PAGE_VIEW, userInfo, {
      NavigateToPage: to ?? "",
      PageName: to ?? "",
      SectionName: section?.title ?? "",
      MenuName: MenuName ?? "",
      subMenuName: subMenuName ?? "",
    });
    ResetBreadCrumb3LevelTitle();
    if (window.innerWidth <= 900) {
      if (toggleHamburger) {
        (subMenuName !== menuState.submenu.name ||
          window.location.pathname.lastIndexOf("settings") > -1) &&
          handleHamburgerChange();
      }
    }
    if (!window.location.search.includes("backButton") && to !== undefined) {
      window.history.replaceState(null, "", to + window.location.search);
    }
  };

  let location = useLocation();
  useEffect(() => {
    location.pathname !== "/" && ChangeTabs(menu, section);

    // scrollIntoViewWithOffset(location.pathname, -57);
    if (location.pathname.toLowerCase().indexOf("eycatalyst") > -1) {
      try {
        let topbarHeight = document
          .getElementById("topbar")
          .getBoundingClientRect().height;

        // let height1 = document
        //   .getElementById("common_container_header")
        //   .getBoundingClientRect().height;
        setLinkOffset(-(topbarHeight + 40));
      } catch (e) {}
    }
  }, []);

  useEffect(() => {
    let count = 0;
    if (menu?.submenu?.length > 0) {
      menu.submenu?.map((submenu) => {
        if (userInfo?.teams?.featureFlagsObj[submenu?.title]?.isEnabled)
          count++;
      });
      setShowMenu(count > 0);
      // setShowSection((prev) => (prev == true ? true : count > 0));
    }
  }, [userInfo?.teams?.featureFlagsObj]);

  useEffect(() => {
    // setShowSection((prevVal) => {
    //   return prevVal === true ? true : showMenu;
    // });

    menu?.to &&
      setShowMenu(userInfo?.teams?.featureFlagsObj[menu?.title]?.isEnabled);
  }, [showMenu, userInfo?.teams?.featureFlagsObj]);

  const ChangeTabs = (menu, section) => {
    const path = window.location.pathname.split("/")[1].toLowerCase();
    switch (path) {
      // case "eycatalyst":
      //   menu?.submenu?.length
      //     ? menu?.submenu?.filter((submenuitem) => {
      //         if (window.location.pathname.toLowerCase() !== "") {
      //           submenuitem.to
      //             .toLowerCase()
      //             .indexOf(window.location.pathname.toLowerCase()) > -1 &&
      //             handleMenuChange(
      //               menu.id,
      //               menu.title,
      //               submenuitem.id,
      //               submenuitem.title,
      //               menu?.data,
      //               submenuitem.to
      //             );
      //         }
      //         return "";
      //       })
      //     : menu?.to
      //         .toLowerCase()
      //         .indexOf(window.location.pathname.toLowerCase()) > -1 &&
      //       handleMenuChange(menu.id, menu.title, "", "", menu?.data, menu.to);

      //   break;
      case "settings":
        break;
      default:
        menu?.submenu?.length
          ? menu?.submenu?.filter((submenuitem) => {
              if (window.location.pathname.split("/")[1].toLowerCase() !== "") {
                submenuitem.to.toLowerCase().split("/")[1] ===
                  window.location.pathname.split("/")[1].toLowerCase() &&
                  handleMenuChange(
                    menu.id,
                    menu.title,
                    submenuitem.id,
                    submenuitem.title,
                    "",
                    submenuitem.to
                  );
              }
              return "";
            })
          : menu?.to
              .toLowerCase()
              .indexOf(window.location.pathname.split("/")[1].toLowerCase()) >
              -1 && handleMenuChange(menu.id, menu.title, "", "", menu.to);
        break;
    }
  };

  const getStyleClasses = () => {
    let classes = `menu_component__container title_link  
    ${
      menu?.id === menuState.menu.id &&
      (!toggleSubMenu || menu?.submenu?.length < 1) &&
      " active_menu"
    }  
    ${menu?.id === menuState.menu.id && " active_menu_indicator"}`;
    return classes;
  };

  const handleKeyDown = (event, to) => {
    if (event.keyCode === 13) {
      handleTabChange();
      if (to !== "/") {
        event.preventDefault();
      }
    }
  };
  const handleTabChange = () => {
    // if (location.pathname.includes("search") && menu.to !== undefined) {
    //   console.log(menu.to);
    //   app.getContext().then((item) => {
    //     let pageState = {
    //       from: "SearchTabSidebar",
    //       to: menu.to,
    //     };
    //     let parms = {
    //       // @ts-ignore
    //       tabName: "Home",
    //       appId: Constant.GetUserAppId(item.user.userPrincipalName),
    //       pageId: Constant.HomeTab,
    //       subPageId: pageState,
    //     };
    //     pages.navigateToApp(parms);
    //   });
    // } else
    if (menu.to !== undefined) {
      handleMenuChange(menu.id, menu.title, "", "", "", menu.to);
      if (menu?.id === menuState?.menu?.id) {
        scrollIntoViewWithOffset(menu.to, -60);
      }
    } else {
      showSubmenu();
    }
  };
  return (
    <div key={menu?.id}>
      {
      
      // location.pathname.toLowerCase().indexOf("eycatalyst") > -1
      //   ? userInfo?.teams?.featureFlagsObj[capitalizeWords(menu?.title)]
      //       ?.isEnabled && (
      //       <ScrollLink
      //         to={menu.to}
      //         className={getStyleClasses()}
      //         spy={true}
      //         // isDynamic={true}
      //         offset={linkOffset}
      //         onSetActive={() =>
      //           handleMenuChange(
      //             menu.id,
      //             menu.title,
      //             "",
      //             "",
      //             menu?.data,
      //             menu.to
      //           )
      //         }
      //       >
      //         <div className="menu_component__container_item">
      //           <div className="icon">{menu?.icon}</div>
      //           <div className="title ">
      //             {menu?.title === "Overview"
      //               ? `Technology: ${Constant.APPLICATION_NAME}`
      //               : menu?.title}
      //           </div>
      //         </div>

      //         <div className={`toggle_submenu_btn`}>
      //           {menu?.badge && <Badge badge={menu.badge} />}
      //           {menu?.submenu?.length > 0 ? (
      //             <MdKeyboardArrowRight
      //               className={` ${toggleSubMenu && "rotate_180"}`}
      //             />
      //           ) : null}
      //         </div>
      //       </ScrollLink>
      //     )
      //   :
        
        
        showMenu && (
            <Link
              to={menu.to ? menu.to : "#"}
              className={getStyleClasses()}
              onClick={handleTabChange}
              onKeyDown={(event) => handleKeyDown(event, menu.to)}
            >
              <div className="menu_component__container_item">
                <div className="icon">{menu?.icon}</div>
                <div className="title ">{menu?.title}</div>
              </div>

              <div className={`toggle_submenu_btn`}>
                {menu?.badge && <Badge badge={menu.badge} />}
                {menu?.submenu?.length > 0 ? (
                  <MdKeyboardArrowRight
                    className={` ${toggleSubMenu && "rotate_180"}`}
                  />
                ) : null}
              </div>
            </Link>
          )}

      <div
        className="menu_component__submenu_container"
        style={toggleSubMenu ? { height: "100%" } : { height: "0" }}
      >
        {/* {menu?.submenu?.map((subMenu) => {
          return userInfo?.teams?.companyFeatureFlags?.filter(
            (sub) =>
              sub?.featureFlagName === subMenu?.title &&
              sub?.isEnabled === false
          ).length > 0 ? (
            ""
          ) : (
            <SubMenu
              key={subMenu?.id}
              subMenu={subMenu}
              menuState={menuState}
              handleMenuChange={handleMenuChange}
              menuId={menu?.id}
              menuTitle={menu?.title}
            />
          );
        })} */}
        {menu?.submenu?.map((subMenu) => {
          return userInfo?.teams?.featureFlagsObj[subMenu?.title]?.isEnabled ||
            subMenu?.title == "Data Drop" ||
            subMenu?.title == "Create Report" ||
            subMenu?.title == "Data Library" ||
            subMenu?.title == "Report Library" ||
            subMenu?.title == "Visualization Library" ? (
            <SubMenu
              key={subMenu?.id}
              subMenu={subMenu}
              menuState={menuState}
              handleMenuChange={handleMenuChange}
              menuId={menu?.id}
              menuTitle={menu?.title}
              setShowMenu={setShowMenu}
              toggleSubMenu={toggleSubMenu}
            />
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
};

export default Menu;
