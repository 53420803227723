import { LogLevel } from "@azure/msal-browser";
import Constant from "./utility/constants";

export const msalConfig = {
  auth: {
    clientId: Constant.GetClientID(),
    authority: "https://login.microsoftonline.com/" + Constant.TENANT_ID,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin + "/logout",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
