export const scrollIntoViewWithOffset = (selector, offset) => {
  try {
    let element = document.getElementById(selector) as HTMLInputElement;
    window.scrollTo({
      behavior: "smooth",
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        -offset,
    });
    setTimeout(() => {
      window.scrollBy(0, 1);
    }, 1000);
  } catch (e) {
    setTimeout(() => {
      try {
        let element = document.getElementById(selector) as HTMLInputElement;

        window.scrollTo({
          behavior: "smooth",
          top:
            element.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            -offset,
        });
        setTimeout(() => {
          window.scrollBy(0, 1);
        }, 1000);
      } catch {}
    }, 1000);
  }
};

export const isVisible = (selector) => {
  try {
    const element = document.getElementById(selector) as HTMLInputElement;
    const rect = element.getBoundingClientRect();
    // console.log(rect.top);
    // return rect.top > 100;
    return (
      (rect.top > 0 && rect.top < window.innerHeight / 2) ||
      (rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom > 121)
    );
    // return (rect.top > 0 && rect.bottom < (element.offsetHeight * 0.832));
    // console.log(selector);

    // // const rect = element.getBoundingClientRect();
    // return (
    //   rect.top >= 0 &&
    //   rect.left >= 0 &&
    //   rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //   rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    // );
  } catch (e) {}
};

export const isVisibleOnScroll = (selector) => {
  try {
    const element = document.getElementById(selector) as HTMLInputElement;
    const rect = element.getBoundingClientRect();
    // console.log(rect.top);
    // return rect.top > 100;
    return (
      (rect.top > 0 && rect.top < window.innerHeight / 2) ||
      (rect.bottom >=
        (window.innerHeight / 2 || document.documentElement.clientHeight / 2) &&
        rect.bottom > 121)
    );
    // return (rect.top > 0 && rect.bottom < (element.offsetHeight * 0.832));
    // console.log(selector);
    // // const rect = element.getBoundingClientRect();
    // return (
    //   rect.top >= 0 &&
    //   rect.left >= 0 &&
    //   rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //   rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    // );
  } catch (e) {}
};
