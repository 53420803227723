import React from "react";
import Dropzone from "react-dropzone";
import "./UploadData.css";

const UploadData = (props) => {
  return (
    <div className="DropzoneContainer" style={{ position: "relative" }}>
      <Dropzone
        onDrop={(acceptedFiles) =>
          props?.handleDropFile(acceptedFiles, props?.fileTypes)
        }
        multiple={props?.multiple}
        disabled={!props?.isDropzoneEnabled}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps(props?.rootProps)}>
              <input
                data-testid="drop-input"
                {...getInputProps()}
                id="datadropinputbox"
              />
              <div>{props?.dropzoneContent()}</div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadData;
