/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { getData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { useLocation } from "react-router-dom";
import "./companySelector.css";
import { useNavigate } from "react-router-dom";
import { app, pages } from "@microsoft/teams-js";
import Constant from "../../utility/constants";
import { useMenuContext } from "../../context/menucontext/menuContext";
import { findMenuItem } from "../../utility/utility";
import sideNavMainMenuData from "../../data/menu/sideNavMainMenuData";
import { BreadCrumbContext } from "../../context/breadcrumbcontext/BreadCrumbContext";
import { UseUserManagementContext } from "../../context/usermanagementcontext/userManagementContext";
import CompanyDropdown from "./CompanyDropdown";

initializeIcons();
// registerIcons({
//   icons: {
//     ChevronDown: <ChevronDownIcon />,
//   },
// });

const dropdownStyles = {
  dropdown: { minWidth: 120, fontWeight: 700 },
  title: { border: "none", outline: 0, backgroundColor: "#f5f5f5" },
};

const CompanySelector = () => {
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const { usersData } = UseUserManagementContext();

  const [companyList, setCompanyList] = useState(userInfo.allCompanies);
  // const [selectedItem, setSelectedItem] = useState();
  const [focused, setFocused] = useState(false);
  const location = useLocation();
  const { ResetBreadCrumb3LevelTitle } = useContext(BreadCrumbContext);
  const nav = useNavigate();
  const { menuState, handleChangeNav } = useMenuContext();

  useEffect(() => {
    app.initialize();
  }, []);

  const onChange = (event, IDropdownOption) => {
    // setSelectedItem(IDropdownOption);
    setFocused(false);
    handleUserInfoChange({ teams: IDropdownOption });
    handleUserInfoChange({ showPopup: !userInfo.showPopup });
    setCompanyInLocalStorage(IDropdownOption);
    window.scrollBy(0, 5);
    //console.log(menuState);

    userInfo.loggedInWeb === true
      ? nav(Constant.REDIRECTION_PATH)
      : app.getContext().then((item) => {
          ResetBreadCrumb3LevelTitle();
          if (item.page.id != Constant.HomeTab) {
            let parms = {
              appId: Constant.GetUserAppId(item.user.userPrincipalName),
              pageId: Constant.HomeTab,
            };
            userInfo?.teams?.companyId !== IDropdownOption?.companyId &&
              pages.navigateToApp(parms);
          } else {
            //window.location.href = Constant.REDIRECTION_PATH;
            nav(Constant.REDIRECTION_PATH);
            // handleChangeNav(
            //   findMenuItem(sideNavMainMenuData, Constant.REDIRECTION_PATH)
            // );
          }
        });
  };

  useEffect(() => {
    setCompanyList(userInfo.allCompanies);
    if (
      (userInfo?.teams?.text === "" || userInfo?.teams?.text === undefined) &&
      userInfo.allCompanies.length !== 0
    ) {
      if (location.state != null) {
        let companyId = location.state.companyId;
        let filterCompany = userInfo.allCompanies.filter((item) => {
          return item.companyId === parseInt(companyId);
        });
        handleUserInfoChange({
          teams: filterCompany[0],
        });
        setCompanyInLocalStorage(filterCompany[0]);
      } else {
        let company = JSON.parse(getCompanyInLocalStorage());
        if (company) {
          let checkIfExists = userInfo.allCompanies.filter(
            (item) => item.key == company.key
          );
          if (checkIfExists.length > 0) {
            if (checkIfExists[0]?.companyFeatureFlags) {
              handleUserInfoChange({
                teams: {
                  ...company,
                  companyFeatureFlags: checkIfExists[0]?.companyFeatureFlags,
                },
              });
            }
          } else {
            handleUserInfoChange({
              teams: userInfo.allCompanies[0],
            });
          }
        } else {
          handleUserInfoChange({
            teams: userInfo.allCompanies[0],
          });
          setCompanyInLocalStorage(userInfo.allCompanies[0]);
        }
      }
    }
  }, [userInfo.allCompanies.length, usersData?.resetCompanies]);

  const setCompanyInLocalStorage = (company) => {
    window.localStorage.setItem(
      "curUserSelectedCompany",
      JSON.stringify(company)
    );
  };

  const getCompanyInLocalStorage = () => {
    let val = window.localStorage.getItem("curUserSelectedCompany");
    if (val == "undefined" || val == null || val == undefined || val == "") {
      return null;
    }
    return val;
  };

  return (
    <div className="drpContainer">
      {/* <Dropdown
        selectedKey={userInfo.teams ? userInfo.teams.key : undefined}
        onChange={onChange}
        options={companyList}
        styles={dropdownStyles}
        data-testid="company-dropdown"
        onBlur={() => setFocused(false)}
        className={`${focused ? "companyDropdownFocused" : ""}`}
        onClick={() => setFocused(true)}
      /> */}
      <CompanyDropdown
        onChange={onChange}
        selectedKey={userInfo.teams.key ? userInfo.teams : undefined}
        allOptions={companyList}
      />
    </div>
  );
};

export default CompanySelector;
