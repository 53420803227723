import "./toolTip.css";
import { useState, useRef, useEffect, forwardRef } from "react";
import { Tooltip } from "@fluentui/react-northstar";
import React from "react";
import { useTooltipContext } from "../../context/tooltipcontext/tooltipContext";
import { v4 as uuidv4 } from "uuid";

const CustomizedToolTip = (props) => {
  const { children, content, pointing, closeafterclick = false } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMenuTooltip, setMenuTooltip] = useState(false);
  const { activeTooltip, setActiveTooltip } = useTooltipContext();
  const tooltipId = "tooltip_" + uuidv4();

  const triggerRef = useRef(null);

  const handleMouseLeave = () => {
    setActiveTooltip(null);
    if (isMenuTooltip === true) {
      setShowTooltip(false);
      setMenuTooltip(false);
    } else {
      setMenuTooltip(true);
    }
    if (typeof props.resetShowAll === 'function') {
      props.resetShowAll();
    }
  };

  const handleMouseEnter = () => {
    setActiveTooltip(tooltipId);
    if (props?.normalTooltip) {
      setShowTooltip(true);
    } else if (triggerRef.current) {
      // setShowTooltip(triggerRef.current.scrollWidth > triggerRef.current.clientWidth);
      //Use it for 2 liner display of data

      // else {
      if (!props.lineNumber) {
        setShowTooltip(
          triggerRef.current.scrollHeight > triggerRef.current.clientHeight
        );
        if (props?.width) {
          setShowTooltip(
            triggerRef.current.scrollWidth > triggerRef.current.clientWidth
          );
        }
      } else if (props.lineNumber) {
        setShowTooltip(
          triggerRef.current.scrollHeight - 1 > triggerRef.current.clientHeight
        );
      }
      // }
    }
  };

  useEffect(() => {
    if (props?.groupMember) {
      props?.setOneLineGroupMemberVal(
        triggerRef.current.scrollHeight - 1 > triggerRef.current.clientHeight
      );
    }
  }, []);

  return (
    <>
      {
        <div
          onClick={() => {
            setMenuTooltip(!isMenuTooltip);
            setActiveTooltip(tooltipId);
            setShowTooltip(true);
          }}
        >
          <Tooltip
            content={content}
            subtle={false}
            trigger={React.cloneElement(children, {
              ref: triggerRef,
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave,
              ...(props?.onDesktopClick && {
                onClick: props?.onDesktopClick,
                onTouchStart: props?.onTouchStart,
                onTouchEnd: props?.onTouchEnd,
              }),
            })}
            position={props?.position ? props?.position : "below"}
            align={props?.align ? props?.align : "start"}
            className="custom-tooltip"
            pointing={pointing ?? true}
            unstable_pinned
            pen={isMenuTooltip && activeTooltip === tooltipId && showTooltip}
            data-testid="tooltipTest"
            offset={pointing ? [0, 0] : [0, -2]}
          />
        </div>
      }
    </>
  );
};

export default CustomizedToolTip;
