import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineDown, AiOutlineClose } from 'react-icons/ai';
import { ChevronDownIcon, Loader } from '@fluentui/react-northstar';
import Constant from '../../utility/constants';
import CustomizedToolTip from "../../commonui/tooltip/toolTip";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import './CompanyDropdown.css';


const CompanyDropdown = ({ onChange, selectedKey, allOptions }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedKey);
    const [searchText, setSearchText] = useState('');
    const [filterText, setFilterText] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(false);
    const [loadedOptions, setLoadedOptions] = useState(5);
    const dropdownRef = useRef(null);
    const { userInfo, handleUserInfoChange } = UseUserInfoContext();
    const [pinOptions, setPinOptions] = useState(['']);



    const tooltipContent = (
        <div>
            <p><strong>Client Free Edition</strong></p>
            <p><span className="status-indicator"></span>Active</p>
            <ul className="arrow-list">
                <li>Full access to content section.</li>
                <li>Cannot access My Technology section.</li>
                <li>View access to Apps.</li>
            </ul>
        </div>
    );

    useEffect(() => {
        setSelectedOption(selectedKey);
    }, [selectedKey, allOptions]);

    useEffect(() => {
        const handleScroll = () => {
            const dropdownOptions = document?.getElementById('cdropdown-options');
            if ((dropdownOptions.scrollHeight - dropdownOptions.scrollTop) <= (dropdownOptions.clientHeight + 10)) {
                if ((allOptions.length) > loadedOptions) {
                    setLoading(true);
                    setTimeout(() => {
                        setLoadedOptions(prevLoadedOptions => prevLoadedOptions + 5);
                        setLoading(false);
                    }, 1000);
                }
            }
        };

        document?.getElementById('cdropdown-options')?.addEventListener('scroll', handleScroll);
        return () => document?.getElementById('cdropdown-options')?.removeEventListener('scroll', handleScroll);
    }, [loadedOptions, options]);




    useEffect(() => {
        const newPinOptions = allOptions
            .filter(option => option.isInternalCompany)
            .map(option => option.companyName.trim());

        setPinOptions(newPinOptions);
    }, [userInfo, allOptions]);

    useEffect(() => {
        // Sort options to pin internal companies to the top
        const sortedOptions = allOptions.sort((a, b) => {
            const isPinnedA = pinOptions.includes(a.companyName.trim());
            const isPinnedB = pinOptions.includes(b.companyName.trim());
            if (isPinnedA && !isPinnedB) {
                return -1; // A is pinned and should come before B
            } else if (!isPinnedA && isPinnedB) {
                return 1; // B is pinned and should come before A
            }
            return 0; // Neither or both are pinned, maintain original order
        });

        // Then, filter based on the search text and slice to the loaded options
        const filteredAndSlicedOptions = sortedOptions
            .filter(option => filterText.length > 0 ? option.companyName.toLowerCase().includes(filterText.toLowerCase()) : true)
            .slice(0, loadedOptions);

        setOptions(filteredAndSlicedOptions);
    }, [loadedOptions, filterText, allOptions, pinOptions]);


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
                clearSearch();
            }
        };

        document?.addEventListener('click', handleClickOutside);
        return () => document?.removeEventListener('click', handleClickOutside);
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        clearSearch();
    };

    const handleOptionClick = (option) => {
        onChange(null, option);
        setSelectedOption(option);
        setIsOpen(false);
        // console.log('Selected Option:', option);
        clearSearch();
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        if (e.target.value.length > 2 || e.target.value.length == 0) {
            if (filterText.length < 2) {
                setLoadingText(true);
                setLoading(true);
                setTimeout(() => {
                    setFilterText(e.target.value);
                    setLoadingFalse();
                }, 1000);
            } else {
                setFilterText(e.target.value);
            }
        }
        else if (e.target.value.length == 1 || e.target.value.length == 2) {
            setFilterText('');
        }
    };

    const clearSearch = (e = null) => {
        e?.stopPropagation();
        setLoadingFalse();
        setSearchText('');
        setFilterText('');
    };

    const setLoadingFalse = () => {
        setLoadingText(false);
        setLoading(false);
    };

    useEffect(() => {
        setOptions(allOptions
            ?.filter(
                option => filterText.length > 0 ? option?.text?.toLowerCase()
                    .includes(filterText?.toLowerCase()) : true
            )
            ?.slice(0, loadedOptions)
        );
    }, [loadedOptions, filterText, allOptions]);


     // Separate pinned and non-pinned options
     const pinnedOptions = options.filter(option => pinOptions.includes(option.companyName.trim()));
     const nonPinnedOptions = options.filter(option => !pinOptions.includes(option.companyName.trim()));
    
    return (
        <div className="company-dropdown" ref={dropdownRef}>
            <div className="cdropdown-header" onClick={toggleDropdown}>
                {!(userInfo?.teams?.isInternalCompany) ? (
                    <div className="subscription_image_wrapper">
                        <CustomizedToolTip content={tooltipContent}>
                            <div className="subscription_image" onClick={(e) => e.stopPropagation()}>
                                <img
                                    className="subscription_image1"
                                    src={require("../../assets/Subscriptionicon.svg").default}
                                    alt="Subscription Icon img"
                                />
                            </div>
                        </CustomizedToolTip>
                    </div>
                ) : ""}
                <span title={selectedOption?.companyName}>{selectedKey ? selectedOption?.companyName : ''}</span>
                <ChevronDownIcon className={`cdropdown-icon ${isOpen ? 'open' : ''}`} />
            </div>

            <div style={{ position: 'absolute', width: "100%" }}>
                <div className="csearch-container" style={!isOpen ? { display: "none" } : {}}>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={handleSearchChange}
                        spellCheck={false}
                    />
                    {searchText && (
                        <AiOutlineClose className="cclear-search-icon" onClick={(e) => clearSearch(e)} />
                    )}
                </div>
                {searchText.length < 3 && searchText.length > 0 && isOpen && <div className='cHintMsg'>{Constant.HINT_MESSAGE}</div>}
                {searchText.length > 2 && options.length < 1 && isOpen && <div className='cHintMsg'>{Constant.NO_RESULT_MESSAGE}</div>}
                {options?.length > 0 && (
                    <div className="cdropdown-options-container" style={!isOpen ? { display: "none" } : {}}>
                        {/* Fixed container for pinned options */}
                        <div className="cdropdown-pinned-options">
                            {pinnedOptions.map(option => (
                                <div
                                    key={option.key}
                                    className={`cdropdown-option ${selectedOption && selectedOption.key === option.key ? 'selected' : ''}`}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option.text}
                                </div>
                            ))}
                            {pinnedOptions.length > 0 && <hr className="separator" />}
                        </div>
                        {/* Scrollable container for non-pinned options */}
                        <div className="cdropdown-options" id="cdropdown-options">
                            {!loadingText && (
                                <>
                                    {nonPinnedOptions.map(option => (
                                        <div
                                            key={option.key}
                                            className={`cdropdown-option ${selectedOption && selectedOption.key === option.key ? 'selected' : ''}`}
                                            onClick={() => handleOptionClick(option)}
                                        >
                                            {option.text}
                                        </div>
                                    ))}
                                </>
                            )}
                            {loading && (
                                <div className={`cdropdown-loader`}>
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyDropdown;
