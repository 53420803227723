import React from "react";
import PopupBox from "../../../commonui/popup/popup";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import PeoplePicker from "../../../commonui/commonformcomponents/peoplepicker/peoplePicker";
import TextArea from "../../textarea/textArea";
import { AiOutlineWarning } from "react-icons/ai";
import Constant from "../../../utility/constants"; 

export const ChatPopup = (props) => {
  return (
    <>
      <PopupBox
        title="Chat Groups"
        isFooter={true}
        isBorderNeeded={true}
        isModalOpen={props?.isModalOpen}
        sendButtonDisabled={props.formValidation()}
        hideModal={props?.hideModal}
        onClick={props?.onSendClick}
        className="calendarPopupStyle"
      >
        <div className="chatContent">
          {props?.isLoading && <ReusableLoader isOverlay={true} isChat={true} />}
          <div className="tocontainer">
            <span className="popuplabel">To:</span>
            {/* <PeoplePicker /> */}
            <div className="chatPeoplePickerStyles">
              <PeoplePicker
                about="OK"
                search={true}
                multiple={true}
                placeholder={"Type the name of person, email id or user group"}
                checkable={true}
                fluid={true}
                contentTypeId={props?.contentType}
                contentTypeName={props?.contentTypeName}
                userType={0}
                fromChatCalenderSendMail={true}
                searchAd={false}
                searchUserGroup={true}
              />
            </div>
          </div>
          <div className="adaptiveCardcontainer">
            <span className="popuplabel">Say something about this</span>
            <TextArea {...props} showPreviewLink={props?.showDeepLink} />
          </div>
          {!props?.showDeepLink && (
            <div className="chatValidationError">
              <p>
                <AiOutlineWarning />
                {Constant.CHAT_VALIDATION_MESSAGE}
              </p>
            </div>
          )}
          {props?.error && (
            <div className="chatServerError">
              <p>
                <AiOutlineWarning />
                {props?.errorMsg}
              </p>
            </div>
          )}
        </div>
      </PopupBox>
    </>
  );
};
