import React, { useEffect, useState, useMemo } from "react";
import { Input, Dropdown, Image } from "@fluentui/react-northstar";
import { getCountry } from "../../utility/utility";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import ConfirmationPage from "../../commonui/confirmationpage/confirmationpage";
import APIEndPoints from "../../utility/apiendpoints";
import { postData } from "../../services/customApis";
import Constant from "../../utility/constants";
import "./registerPage.css";

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const RegisterPage = () => {
  const [isRegisterBtnDisabled, setRegisterBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegisteredUser, setRegisteredUser] = useState(false);
  // const countryObject = useMemo(() => getCountry(), []);
  const [countries, setCountries] = useState(["United States"]);
  const [emailError, setEmailError] = useState("");
  const [formState, setFormState] = useState({
    RegisterScreen: true,
    MessageScreen: false,
  });
  const [messageState, setMessageState] = useState({
    Header: "",
    Image: "",
    Content: "",
    Content2: "",
  });
  const [registerFormState, setRegisterFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: countries[0],
    // organization: "",
  });

  useEffect(() => {
    setRegisterBtnDisabled(
      registerFormState.firstName === "" ||
        registerFormState.lastName === "" ||
        registerFormState.email === "" ||
        registerFormState.country === "" ||
        emailError !== ""
    );
  }, [registerFormState, emailError]);

  const handleInputChange = (e, data) => {
    const value = data.value;
    setRegisterFormState((prevState) => ({
      ...prevState,
      [data.name]: value,
    }));

    if (data.name === "email") {
      setEmailError(validateEmail(value) ? "" : "Invalid email format");
    }
  };

  const handleDropdownChange = (e, data) => {
    setRegisterFormState((prevState) => ({
      ...prevState,
      [data.name]: data.value,
    }));
  };

  const onSignInClick = () => {
    window.location.href = Constant.REDIRECTION_PATH;
  };

  const onRegisterClick = async () => {
    let payload = {
      userActionTypeId: 1,
    };
    setRegisteredUser(false);
    setIsLoading(true);
   // console.log(registerFormState);
    payload = {
      ...payload,
      firstName: registerFormState.firstName,
      lastName: registerFormState.lastName,
      email: registerFormState.email,
      country: registerFormState.country,
    };

    await postData(
      payload,
      APIEndPoints.REGISTER_USERDETAILS,
      false,
      false,
      false,
      false
    )
      .then((res) => {
        setIsLoading(false);
        setFormState({
          RegisterScreen: false,
          MessageScreen: true,
        });
        if (res?.status === 200) {
          if (
            res?.data?.upeOrganization == null &&
            res?.data?.isUserExist === false
          ) {
            userOrganizationNotFound();
          } else {
            if (
              res?.data?.upeOrganization !== null &&
              res?.data?.isAccessRequested
            ) {
              waitingForApproval();
            } else {
              if (
                res?.data?.upeOrganization !== null &&
                res?.data?.isAccessRequested === false &&
                res?.data?.isUserExist === false
              ) {
                userRegistered();
              } else {
                if (res?.data?.isUserExist) {
                  userAlreadyRegistered();
                }
              }
            }
          }
        } else {
          genericError();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        catchError(err?.response?.data?.Errors[0]);
      });
  };

  const waitingForApproval = async () => {
    setMessageState({
      Header: "Almost there!",
      Image: require("../../assets/ApprovaImage.png"),
      Content:
        "Your access is pending approval. Thank you for your patience while we review your request!",
      Content2: "Need help now? Contact us at eytaxcatalystsupport@ey.com",
    });
  };

  const userOrganizationNotFound = async () => {
    setMessageState({
      Header: "Thank you for your interest!",
      Image: require("../../assets/CompNotFound.png"),
      Content:
        "We will send you an email with next steps. We look forward to seeing you on EY Catalyst Connect!",
    });
  };

  const userAlreadyRegistered = async () => {
    setFormState({
      RegisterScreen: true,
      MessageScreen: false,
    });
    setRegisteredUser(true);
  };

  const userRegistered = async () => {
    setMessageState({
      Header: "Thanks for confirming your information!",
      Image: require("../../assets/ConfirmImage.png"),
      Content:
        "We will send you an email with next steps. We look forward to seeing you on EY Catalyst Connect!",
    });
  };

  const catchError = async (err) => {
    setFormState({
      RegisterScreen: false,
      MessageScreen: true,
    });
    setMessageState({
      Header: "Something went wrong!",
      Image: require("../../assets/GenericError.png"),
      Content: err,
    });
  };

  const genericError = async () => {
    setMessageState({
      Header: "Oops..",
      Image: require("../../assets/pagenotfoundturtle.png"),
      Content:
        "Something went wrong. Please try again later. For further assistance please reach out to us at eytaxcatalystsupport@ey.com",
    });
  };

  return (
    <>
      {/* <div className="navBarTabs">
        <div className="navBarTabs__right-section">
          <div className="navBarTabs__right-section__logo">
            <Image
              alt=""
              src={require("../../assets/EY_Logo.png")}
              fit="cover"
              className="navBarTabs__right-section__logo__image"
            />
            <p className="navBarTabs__right-section__logo__heading">
              {window.location.origin.indexOf("https://catalyst.ey.com") > -1
                ? "EY Catalyst Connect"
                : "EY Catalyst Connect (Beta)"}
            </p>
          </div>
        </div>
      </div> */}
      {formState.RegisterScreen ? (
        <>
          {isLoading && <ReusableLoader isOverlay={true} isAppBody={true} />}
          <div class="register-container">
            <div class="register-form">
              <div class="form-header-register">
                <div class="form-header-image-register">
                  <Image
                    alt=""
                    class="header-image"
                    src={require("../../assets/EY_Disc_Logo.png")}
                  />
                </div>
                <div className="form-header-heading-register">
                  Welcome to
                  <br /> EY Catalyst Connect
                </div>
              </div>

              <div className="form-group-label-register">
                To ensure you have a seamless experience please fill in the
                below details to make your first step towards joining our
                community and accessing all the features we have to offer.
              </div>
              <div class="form-group">
                <Input
                  label="Enter First Name (Required)"
                  class="form-input additional-class"
                  name="firstName"
                  value={registerFormState.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div class="form-group">
                <Input
                  label="Enter Last Name (Required)"
                  class="form-input additional-class"
                  name="lastName"
                  value={registerFormState.lastName}
                  onChange={handleInputChange}
                />
              </div>
              <div class="form-group">
                <Input
                  label="Enter Email ID (Required)"
                  class="form-input additional-class"
                  name="email"
                  value={registerFormState.email}
                  onChange={handleInputChange}
                />
                <p
                  style={{
                    fontWeight: "600",
                    color: "rgb(196, 49, 75)",
                  }}
                >
                  {emailError}
                </p>
              </div>
              <div class="form-group">
                <Dropdown
                  // items={Object.values(countryObject.allCountries).sort(
                  //   (a, b) => a.localeCompare(b)
                  // )}
                  items={countries}
                  multiple={false}
                  className="form-input additional-class country-dropdown"
                  name="country"
                  placeholder="Select Country"
                  fluid
                  onChange={handleDropdownChange}
                  // getA11ySelectionMessage={{
                  //   onAdd: (item) =>
                  //     `${countryObject.allCountries[item]} has been selected.`,
                  // }}
                  getA11ySelectionMessage={{
                    onAdd: (item) => `${item} has been selected.`,
                  }}
                  value={registerFormState.country}
                />
              </div>
              {/* <div class="form-group">
                <Input
                  label="Enter Organization Name"
                  class="form-input additional-class"
                  name="organization"
                  value={registerFormState.organization}
                  onChange={handleInputChange}
                />
              </div> */}

              <div className="form-group-label-signin">
                Already have an account?{" "}
                <a
                  class="form-link cursor-class"
                  onClick={() => {
                    onSignInClick();
                  }}
                >
                  Sign In
                </a>
              </div>

              <div class="form-group">
                <button
                  class={`form-button ${
                    isRegisterBtnDisabled ? "disabled-btn" : "yellow-btn"
                  }`}
                  disabled={isRegisterBtnDisabled}
                  onClick={() => onRegisterClick()}
                >
                  Register
                </button>
                <button class="form-button white-btn">Cancel</button>
              </div>
              {isRegisteredUser && (
                <div className="form-group">
                  <p className="p-text-already-registered">
                    {
                      "This email-id is already registered with us. Please sign in to proceed."
                    }
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <ConfirmationPage
          heading={messageState.Header}
          image={messageState.Image}
          content={messageState.Content}
          content2={messageState.Content2}
        />
      )}
      ;
    </>
  );
};

export default RegisterPage;
