import React, { useState, useEffect } from "react";
import { TextArea as FormTextArea } from "@fluentui/react-northstar";
import "./TextArea.css";
import { MdError } from "react-icons/md";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";

function TextArea(props) {
  const [currentValue, setCurrentValue] = useState("");
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const allValidations = props?.validations;

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  const handleInputChange = (e, fieldName, isCustomField) => {
    if (allValidations != undefined && allValidations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        allValidations,
        e.target.value
      );
      setError(hasError);
      setErrorMessage(errMessage);
      props?.onChange(
        e,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.isContentField,
        props?.parentName,
        hasError,
        errMessage
      );
    } else {
      props?.onChange(
        e,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.isContentField,
        props?.parentName,
        false,
        ""
      );
    }
    setCurrentValue(e.target.value);
  };

  useEffect(() => {
    if (props?.editMode == true) {
      setCurrentValue(props?.value);
      props.updateValidationState(
        props?.name,
        props?.value,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    }
    else if (props?.isPolls == true || props?.promptPlayGround == true || props?.isContactUs == true) {
      setCurrentValue(props?.value);
    }
  }, [props?.value]);

  return (
    <>
      <div className="formTextAreaStyles">
        <FormTextArea
          placeholder={props?.placeholder}
          inverted={false}
          fluid={true}
          value={currentValue}
          maxLength={props?.maxLength}
          resize={props?.resize}
          className={`textAreaStyles ${props?.className} ${hasAnyError ? "textbox_error" : ""
            }`}
          defaultValue={props?.defaultValue}
          error={props?.error}
          onChange={(e) => {
            handleInputChange(e, props?.fieldsName, props?.isCustomField);
          }}
          required={props?.required}
          variables={{ height: props?.height }}
          disabled={props?.disabled}
          id={props?.id}
        />
      </div>
      <div>
        {hasAnyError && (
          <>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </>
        )}
      </div>
    </>
  );
}

export default TextArea;
