import React from "react";
import "./sendLink.css";
// import PeoplePicker from "../../../../commonui/peoplepicker/peoplePicker";
import TextArea from "../../../textarea/textArea";
import PeoplePicker from "../../../../commonui/commonformcomponents/peoplepicker/peoplePicker";
import { formatDecodedURLs } from "../../../../utility/utility";
const SendLink = (props) => {
  return (
    <div className="popUpContent">
      <div>
        <span className="cc">Title: </span>
        <span className="sendLink_component_headerBold">
          {props.contentType == 7
            ? formatDecodedURLs(props?.item?.title)
            : props.item.title}
        </span>
      </div>
      <div className="tocontainer">
        {/* <PeoplePicker /> */}
        <div className="sendLinkPeoplePickerStyles">
          <PeoplePicker
            about="OK"
            search={true}
            multiple={true}
            placeholder={"Type the name of person, email id or user group"}
            checkable={true}
            fluid={true}
            freeFormEmail={true}
            userType={0}
            fromChatCalenderSendMail={true}
            searchAd={true}
            searchUserGroup={true}
          />
        </div>
      </div>
      <div className="adaptiveCardcontainer">
        <TextArea
          link={props.link}
          image={props.image}
          title={props.title}
          description={props.description}
          showPreviewLink={props?.showPreviewLink}
          {...props}
        />
      </div>
    </div>
  );
};

export default SendLink;
