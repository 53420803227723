import React, { useEffect, useState } from "react";
import { Checkbox, Loader, Alert } from "@fluentui/react-northstar";
import { AiFillCheckCircle, AiFillExclamationCircle, AiFillBell } from "react-icons/ai";
import { MdInfo } from "react-icons/md";
import Notification from "./notification";
import { UseNotificationContext } from "../../context/notificationcontext/notificationContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import APIEndPoints from "../../utility/apiendpoints";
import { getData, postData } from "../../services/customApis";
import Constant from "../../utility/constants";
import "./notificationSettings.css";

const NotificationSettings = (props) => {
  const { userInfo } = UseUserInfoContext();
  const { handleNotifications } = UseNotificationContext();
  const [settings, setSettings] = useState(null);
  // const [type, setAlertMessageType] = useState("");
  // const [alertMessage, setAlertMessage] = useState("");
  const [curUserNotificationFlag, setNotificationFlag] = useState(null);
  const [loader, setLoader] = useState(true);
  const [errorApiCall, setErrorApiCall] = useState(false);
  const { alertMessage, setAlertMessage, type, setAlertMessageType } = props;
  useEffect(() => {
    if (userInfo?.teams.companyId !== null) {
      let subMenuItemId =
        userInfo?.teams?.featureFlagsObj.Notification.subMenuItemId;

      getData(
        APIEndPoints.GET_PAGE_LEVEL_FLAGS(
          userInfo.teams.companyId,
          subMenuItemId)
      )
        .then((item) => {
         // console.log(item);
          setSettings(item?.data?.contentType);
        })
        .catch((error) => {
          setErrorApiCall(true);
          handleNotifications(error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied." ? {
            accessDenied: true
          } : {
            errorApiCall: true
          });
        }).finally(() => setLoader(false));
      getData(APIEndPoints.GET_NOTIFICATION_FLAG_STATE(
        userInfo.teams.companyId
      ))
        .then((item) => {
          setNotificationFlag(item?.data);
        })
        .catch((e) => {
          console.log(e);
          setErrorApiCall(true);
        }).finally(() => setLoader(false));
    }
  }, [userInfo.teams]);

  useEffect(() => {
    if (type != "") {
      setTimeout(() => {
        setAlertMessageType("");
      }, 4000);
    }
  }, [type]);

  const updateSettings = (checked, source) => {
    let endPoint = "";
    if (source == "channel") {
      endPoint = APIEndPoints.UPDATE_CHANNEL_ACTIVITYFEED_SETTING(
        userInfo?.teams?.companyId,
        checked.checked
      );
    } else {
      endPoint = APIEndPoints.UPDATE_USER_ACTIVITYFEED_SETTING(checked.checked);
    }
    postData({}, endPoint)
      .then((item) => {
        setAlertMessageType("success");
        setAlertMessage(Constant.SETTINGS_UPDATED_MESSAGE);
      })
      .catch(() => {
        setAlertMessageType("fail");
        setAlertMessage(Constant.SETTINGS_UPDATE_ERROR_MESSAGE);
      });
  };

  const onClear = () => {
    setAlertMessageType("");
  };

  const checkCompanySettings = () => {
    let chanelObj = {
      channelNotificationDisplay: false,
      channelNotificationEnabled: false,
    };

    if (
      settings[0]?.contentCategories[1]?.featureFlagsDetails
        ?.bulletinBoardUserActivityFeed?.bulletinBoardUserActivityFeedDisplay ||
      settings[0]?.contentCategories[1]?.featureFlagsDetails?.efileActivityFeed
        ?.efileActivityFeedDisplay ||
      settings[0]?.contentCategories[1]?.featureFlagsDetails?.excelActivityFeed
        ?.excelActivityFeedDisplay ||
      settings[0]?.contentCategories[1]?.featureFlagsDetails
        ?.visualizationActivityFeed?.visualizationActivityFeedDisplay
    ) {
      chanelObj.channelNotificationDisplay = true;
    }

    if (
      settings[0]?.contentCategories[1]?.featureFlagsDetails
        ?.bulletinBoardUserActivityFeed?.bulletinBoardUserActivityFeedEnabled ||
      settings[0]?.contentCategories[1]?.featureFlagsDetails?.efileActivityFeed
        ?.efileActivityFeedEnabled ||
      settings[0]?.contentCategories[1]?.featureFlagsDetails?.excelActivityFeed
        ?.excelActivityFeedEnabled ||
      settings[0]?.contentCategories[1]?.featureFlagsDetails
        ?.visualizationActivityFeed?.visualizationActivityFeedEnabled
    ) {
      chanelObj.channelNotificationEnabled = true;
    }
    return chanelObj;
  };

  return (
    !errorApiCall ? <>
      <div className="notification-settings-container">
        {loader ? (
          <div className="notification-settings-loader">
            <Loader />
          </div>
        ) : (
          settings != null && curUserNotificationFlag != null ? <>
            {type != "" ? (
              <div className="notification-alert">
                <Alert
                  content={
                    <>
                      <div
                        className="notitication-alert-iconclass"
                        style={{ display: "flex" }}
                      >
                        {type.toLocaleLowerCase() === "success" ? (
                          <AiFillCheckCircle />
                        ) : type.toLocaleLowerCase() === "fail" ? (
                          <AiFillExclamationCircle />
                        ) : type.toLocaleLowerCase() === "info" ? (
                          <MdInfo />
                        ) : null}
                      </div>
                      <div className="notification-alert-messageclass">
                        {alertMessage}
                      </div>
                    </>
                  }
                  visible={type != ""}
                  success={
                    type.toLocaleLowerCase() === "success" ? true : false
                  }
                  danger={type.toLocaleLowerCase() === "fail" ? true : false}
                  warning={type.toLocaleLowerCase() === "info" ? true : false}
                  dismissible
                  dismissAction={{
                    onClick: onClear,
                  }}
                />
              </div>
            ) : null}
            {settings[0]?.contentCategories[0]?.featureFlagsDetails
              ?.userActivityFeed?.userActivityFeedDisplay && (
                <div className="notification-user-toggleContainer">
                  <div className="text-container">
                    <p>
                      <strong>User Level Notifications</strong>{" "}
                    </p>
                    <p>Teams Notifications: For Me</p>
                  </div>
                  <div className="checkbox-containers">
                    <Checkbox
                      toggle
                      defaultChecked={
                        curUserNotificationFlag?.userLevelNotificationEnabled
                      }
                      disabled={
                        !settings[0]?.contentCategories[0].featureFlagsDetails
                          .userActivityFeed.userActivityFeedEnabled
                      }
                      onChange={(ev, checked) => updateSettings(checked, "user")}
                    />
                  </div>
                </div>
              )}
            {checkCompanySettings().channelNotificationDisplay && (
              <div className="notification-company-toggleContainer">
                <div className="text-container">
                  <p>
                    <strong>Channel Notifications</strong>{" "}
                  </p>
                  <p>Teams Notifications: All Users</p>
                </div>
                <div className="checkbox-containers">
                  <Checkbox
                    toggle
                    defaultChecked={
                      curUserNotificationFlag?.companyLevelNotificationEnabled
                    }
                    disabled={
                      !checkCompanySettings().channelNotificationEnabled
                    }
                    onChange={(ev, checked) =>
                      updateSettings(checked, "channel")
                    }
                  />
                </div>
              </div>
            )}
          </> : <></>
        )}
      </div>
    </> : <></>
  );
};

export default NotificationSettings;
