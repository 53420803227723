export const timeAgo = (timestamps) => {
    const now = new Date();
    const timestamp = new Date(timestamps);
    const curUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(),
        now.getUTCDate(), now.getUTCHours(),
        now.getUTCMinutes(), now.getUTCSeconds());
    const secondsPast = (curUTC.getTime() - timestamp.getTime()) / 1000;

    if (secondsPast < 60) {
        return `${Math.round(secondsPast)} second${Math.round(secondsPast) !== 1 ? 's' : ''} ago`;
    }

    if (secondsPast < 3600) {
        return `${Math.floor(secondsPast / 60)} minute${Math.floor(secondsPast / 60) !== 1 ? 's' : ''} ago`;
    }

    if (secondsPast <= 86400) {
        return `${Math.floor(secondsPast / 3600)} hour${Math.floor(secondsPast / 3600) !== 1 ? 's' : ''} ago`;
    }

    if (secondsPast <= 604800) {
        return `${Math.floor(secondsPast / 86400)} day${Math.floor(secondsPast / 86400) !== 1 ? 's' : ''} ago`;
    }

    if (secondsPast <= 2629743.83) {
        return `${Math.floor(secondsPast / 604800)} week${Math.floor(secondsPast / 604800) !== 1 ? 's' : ''} ago`;
    }

    if (secondsPast <= 31556926) {
        return `${Math.floor(secondsPast / 2629743.83)} month${Math.floor(secondsPast / 2629743.83) !== 1 ? 's' : ''} ago`;
    }

    return `${Math.floor(secondsPast / 31556926)} year${Math.floor(secondsPast / 31556926) !== 1 ? 's' : ''} ago`;
};


function isNotValidTimestamp(timestamp) {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{7}Z$/;
    return regex.test(timestamp);
}

export const changeFormat = (timestamp) => {

    // Convert the timestamp to a Date object
    const date = new Date(timestamp);

    // Extract the date, hours, minutes, and seconds
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    // Extract the milliseconds and round to one decimal place
    const milliseconds = Math.round(date.getUTCMilliseconds() / 100).toString();

    // Construct the new timestamp
    const newTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

    return newTimestamp;  // Outputs: 2023-08-21T12:13:52.4

};

export const getDateFromUTC = (timestamp) => {


    let utcDateString;
    if (isNotValidTimestamp(timestamp)) {
        utcDateString = changeFormat(timestamp);
    } else {
        utcDateString = timestamp;
    }
    // Convert UTC date string to Date object
    const utcDate = new Date(utcDateString);

    // Get the local timezone offset in minutes
    const timezoneOffsetMinutes = utcDate.getTimezoneOffset();

    // Calculate the local time by adding the offset
    const localTime = new Date(utcDate.getTime() - (timezoneOffsetMinutes * 60 * 1000));

    // Format the local time in "MM/DD/YY hh:mm AM/PM" format
    const formattedDate = (date) => {
        const pad = (num) => (num < 10 ? "0" : "") + num;
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const year = date.getFullYear().toString();//.slice(-2);
        let hours = date.getHours();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        const minutes = pad(date.getMinutes());

        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
    };

    return formattedDate(localTime);

};

export const getDateFromUTC2 = (utcDateString: string) => {
    // Convert UTC date string to Date object
    const utcDate = new Date(utcDateString);

    // Get the local timezone offset in minutes
    const timezoneOffsetMinutes = utcDate.getTimezoneOffset();

    // Calculate the local time by adding the offset
    const localTime = new Date(utcDate.getTime() - (timezoneOffsetMinutes * 60 * 1000));

    // Function to check if the date is today
    const isToday = (date: Date) => {
        const today = new Date();
        return date.toDateString() === today.toDateString();
    };

    // Function to check if the date is yesterday
    const isYesterday = (date: Date) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return date.toDateString() === yesterday.toDateString();
    };

    // Function to format the date in "MMMM DD, YYYY" format (e.g., "April 24, 2023")
    const formattedDate = (date: Date) => {
        const months = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month} ${day}, ${year}`;
    };

    // Get the current time in milliseconds
    const currentTime = new Date().getTime();

    // Get the local time in milliseconds
    const localTimeMilliseconds = localTime.getTime();

    // Calculate the time difference in minutes between current date and local time
    const timeDifferenceMinutes = Math.floor((currentTime - localTimeMilliseconds) / (60 * 1000));

    // Check if the time difference is within 1 minute, return "Just now"
    if (timeDifferenceMinutes < 5) {
        return "Just now";
    }

    // Check if the date is today, return "Today"
    if (isToday(localTime)) {
        return "Today";
    }

    // Check if the date is yesterday, return "Yesterday"
    if (isYesterday(localTime)) {
        return "Yesterday";
    }

    // If none of the above conditions are met, return the formatted date
    return formattedDate(localTime);
};

export const getDateFromUTC3 = (utcDateString) => {
    // Convert UTC date string to Date object
    const utcDate = new Date(utcDateString);

    // Get the local timezone offset in minutes
    const timezoneOffsetMinutes = utcDate.getTimezoneOffset();

    // Calculate the local time by adding the offset
    const localTime = new Date(utcDate.getTime() - (timezoneOffsetMinutes * 60 * 1000));

    // Format the local time to "MM/DD/YYYY" format (e.g., "07/19/2023")
    const formattedDate = (date) => {
        const pad = (num) => (num < 10 ? "0" : "") + num;
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const year = date.getFullYear().toString().slice(-2);

        return `${month}/${day}/${year}`;
    };

    return formattedDate(localTime);
};


