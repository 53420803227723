import React from 'react';
import { Dialog } from '@fluentui/react-northstar';
import './DialogBox.css';
function DialogBox(props) {
  return (
      <Dialog
        cancelButton={props?.cancelButton}
        onCancel={props?.onCancelMethod}
        closeOnOutsideClick={props?.closeOnOutsideClick}
        confirmButton={props?.confirmButton}
        onConfirm={props?.onConfirmMethod}
        content={props?.content}
        header={props?.headerName}
        headerAction={props?.headerActionName}
        trigger={props?.triggerAction}
        backdrop={props?.backdrop}
        open={props?.openDialog}
        className={props?.className}
        trapFocus={false}
        footer={props?.footer}
      />
  );
}

export default DialogBox;