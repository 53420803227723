import React, { useState, useEffect } from "react";
import { deleteData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import maintenanceImage from "../../assets/MaintenancePicture.svg";
import Constant from "../../utility/constants";
import "./downtimePage.css";

const DowntimePage = (props) => {
  const [downtimeOrNot, setDowntimeOrNot] = useState(
    props?.downTime ||
      (typeof localStorage !== "undefined" && localStorage?.getItem("downtime"))
  );

  useEffect(() => {
    if (
      downtimeOrNot &&
      typeof localStorage !== "undefined" &&
      Object.keys(localStorage).length > 0
    ) {
      deleteData("", APIEndPoints.Delete_EY_TOKEN).then(() => {
        const keyToKeep = "curUserSelectedCompany";
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
          if (key !== keyToKeep) {
            localStorage.removeItem(key);
          }
        });
      });
    }
  }, [downtimeOrNot]);

  return (
    <div className="downtime-page">
      <img src={maintenanceImage} alt="Maintenance" />
      <div className="downtime-page__end-session-msg">{Constant.DOWNTIME_SCREEN_LINE_1}</div>
      <div className="under-maintenance-msg">
      {Constant.DOWNTIME_SCREEN_LINE_2} <br /> {Constant.DOWNTIME_SCREEN_LINE_3}
      </div>
    </div>
  );
};

export default DowntimePage;
