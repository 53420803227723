export default class TelemetryEventNames {

    //PAGE VIEWS
    public static UCC_PAGE_VIEW = "Catalyst UI Page View";

    //SECTION TYPES
    public static SECTION_TYPE_MY_TECHNOLOGY = "MY TECHNOLOGY";
    public static SECTION_TYPE_CONTENT = "CONTENT";
    public static SECTION_TYPE_OTHER = "OTHER";
    public static SECTION_TYPE_BULLETIN_BOARD = "BULLETIN BOARD";


    //CONTENT EVENTS
    public static UCC_CONTENT_CREATE_CONTENT_EVENT = "Create Content";
    public static UCC_CONTENT_UPDATE_CONTENT_EVENT = "Update Content";
    public static UCC_CONTENT_DELETE_CONTENT_EVENT = "Delete Content";
    public static UCC_CONTENT_SEARCH_LIBRARY_CONTENT_EVENT = "Search Content";
    public static UCC_CONTENT_VIEW_DETAILS_PAGE_EVENT = "Content View Details Page";
    public static UCC_CONTENT_VIEW_EVENT = "Content Views";
    public static UCC_CONTENT_LIKE_EVENT = "Content Like";
    public static UCC_CONTENT_DISLIKES_EVENT = "Content Dislike";
    public static UCC_CONTENT_DOWNLOADS_EVENT = "Content Downloads";
    public static UCC_CONTENT_SHARE_LINK_EVENT = "Share Content Link";
    public static UCC_CONTENT_CALENDAR_INVITE_EVENT = "Content Calendar Invite";
    public static UCC_CONTENT_INITIATE_CHAT_EVENT = "Content Initiate Chat";
    public static UCC_CONTENT_INITIATE_SHARETOTEAMS_EVENT = "Share To Teams";
    public static UCC_CONTENT_BOOKMARK_EVENT = "Content Bookmark";
    public static UCC_CONTENT_BOOKMARK_REMOVE_EVENT = "Content Bookmark Removed";

    public static UCC_CONTENT_CREATE_POLL_CONTENT_EVENT = "Create Poll";
    public static UCC_CONTENT_CLOSE_POLL_CONTENT_EVENT = "Close Poll";
    public static UCC_CONTENT_EDIT_POLL_CONTENT_EVENT = "Edit Poll Response";
    public static UCC_CONTENT_SUBMIT_POLL_CONTENT_EVENT = "Submit Poll Response";
    public static UCC_CONTENT_EXTEND_END_DATE_CONTENT_EVENT = "Extend End Date";


    //MY TECHNOLOGY EVENTS
    //DATA TRANSFORMATION
    public static UCC_MYTECHNOLOGY_DOWNLOAD_EFILE_EVENT = "Download E-File";
    public static UCC_MYTECHNOLOGY_DELETE_EFILE_EVENT = "Delete E-File";
    public static UCC_MYTECHNOLOGY_FILE_UPLOAD_EVENT = "Data Drop/E-File Upload";

    //REPORTS
    public static UCC_MYTECHNOLOGY_DOWNLOAD_EXCEL_REPORT_EVENT = "Download Excel Report";
    public static UCC_MYTECHNOLOGY_DELETE_EXCEL_REPORT_EVENT = "Delete Excel Report";
    public static UCC_MYTECHNOLOGY_CREATE_REPORT_EVENT = "Create Report";

    //VISUALIZATIONS
    public static UCC_MYTECHNOLOGY_VIEW_VISUALIZATION_PBREPORT_EVENT = "View Visualization Power BI Report";

    //OTHER
    public static UCC_OTHER_CONTACT_US_EVENT = "Contact Us";
    public static UCC_UPDATE_USER_PROFILE_EVENT = "Update User Profile";
    public static UCC_UPDATE_USER_EEPERIENCE_EVENT = "Update User Experience";
    public static UCC_OPEN_PDF_IN_BROWSER_EVENT = "Open PDF In Browser";
    // public static UCC_SIGNALR_AUTHENTICATION_EVENT = "Authentication";
    // public static UCC_CONTENT_UPLOAD_EFILE_EVENT = "Upload E-File";    

    //bulletin board events
    public static UCC_BB_VIEW_EVENT = "Bulletin Board Views";
    public static UCC_BB_PIN_EVENT = "Bulletin Board Pin";
    public static UCC_BB_UNPIN_EVENT = "Bulletin Board Unpin";
    public static UCC_BB_REPLY_EVENT = "Bulletin Board Reply";
    public static UCC_BB_DELETE_REPLY_EVENT = "Bulletin Board Delete Reply";
    public static UCC_BB_UNDO_DELETE_REPLY_EVENT = "Bulletin Board Undo Delete Reply";

    //user management events
    public static UCC_UMM_UPDATE_ROLE = "Update User Role";
    public static UCC_UMM_ADD_USER = "Add User";
    public static UCC_UMM_INVITE_USER = "Invite User";
    public static UCC_UMM_LEAVE_COMPANY = "Leave Channel";
    public static UCC_UMM_DELETE_USER = "Delete User";
    public static UCC_UMM_COPY_TEAM_LINK = "Copy Team Link";

    //user onboarding events
    public static UCC_ONBOARD_CONFIRM = "Onboard - Access Request";
    public static UCC_ONBOARD_INCORRECT = "Onboarding - Reported Incorrect Information";
    public static UCC_ONBOARD_CMP_NOTFOUND = "Onboarding - Company Not Found";

    //Privacy Notice
    public static UCC_ACCEPT_PRIVACY_NOTICE = "Accept Privacy Notice";
}
