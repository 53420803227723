const emailTemplate = `<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:v="urn:schemas-microsoft-com:vml">

<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered medium)">
<!--[if !mso]><style>v\:* {behavior:url(#default#VML);}
o\:* {behavior:url(#default#VML);}
w\:* {behavior:url(#default#VML);}
.shape {behavior:url(#default#VML);}
</style><![endif]-->
<style>
    <!--
    /* Font Definitions */
    @font-face {
        font-family: "Cambria Math";
        panose-1: 2 4 5 3 5 4 6 3 2 4;
    }

    @font-face {
        font-family: Calibri;
        panose-1: 2 15 5 2 2 2 4 3 2 4;
    }

    /* Style Definitions */
    p.MsoNormal,
    li.MsoNormal,
    div.MsoNormal {
        margin: 0in;
        margin-bottom: .0001pt;
        font-size: 11.0pt;
        font-family: "Calibri", sans-serif;
    }

    a:link,
    span.MsoHyperlink {
        mso-style-priority: 99;
        color: #0563C1;
        text-decoration: underline;
    }

    .MsoChpDefault {
        mso-style-type: export-only;
        font-size: 10.0pt;
    }

    @page WordSection1 {
        size: 8.5in 11.0in;
        margin: 1.0in 1.0in 1.0in 1.0in;
    }

    div.WordSection1 {
        page: WordSection1;
    }

    .MsoNormalTable div.content-div {
        padding-bottom: 20px !important;
    }

    .MsoNormalTable p.header {
        text-align: left;
        padding: 0px 0px 15px 28px !important;
    }
    -->
</style>
<!--[if gte mso 9]><xml>
<o:shapedefaults v:ext="edit" spidmax="1026" />
</xml><![endif]-->
<!--[if gte mso 9]><xml>
<o:shapelayout v:ext="edit">
<o:idmap v:ext="edit" data="1" />
</o:shapelayout></xml><![endif]-->
</head>

<body lang="EN-US" link="#0563C1" vlink="#954F72" >
<div class="WordSection1" style="background:#f3f2f1">
    <div align="center">
        <table class="MsoNormalTable" border="0" cellspacing="0" cellpadding="0" style="border-collapse:collapse;background:#f3f2f1">
            <tbody style="background:#f3f2f1;width: 10in;">

                <tr>
                    <td style="width:400pt;border-top:none;padding:0.1in .01in .0.05in 0.1in;">
                        <a href="{Deeplink_URL}">{Deeplink_URL}</a>
                    </td>
                </tr>
                <tr>

                    <td width="" valign="top"
                        style="width:500pt;padding:.1in .1in .1in .1in;">

                        <table class="MsoNormalTable" border="0" cellspacing="0" cellpadding="0"
                            style="border-collapse:collapse">
                            <tbody style="background:#f3f2f1">
                                <tr>
                                    <td colspan="2" style="background:#f3f2f1">{text_content_to_be_replaced}</td>
                                </tr>                        
                                <br/>   
                                <br/>  
                                <tr style="background:white" class ="rowContentClass">

                                    <td width="" valign="middle"
                                        style="background:white;padding:.1in .1in .2in .1in;">
                                        <p><span
                                                style="font-size:10.0pt;font-family:&quot;Times New Roman&quot;,serif"><img
                                                    width="200" height="100" id="Picture_x0020_3" class="img_catalyst"
                                                    src="{image}"
                                                    <o:p></o:p>
                                            </span></p>
                                    </td>
                                    <td width="720" valign="top"
                                        style="width:7.5in;background:white;padding:.1in .1in .1in .1in">
                                        <h4 class="MsoNormal"><span style="color:black">{title}</span></h4>
                                        <p class="MsoNormal"><b>

                                            </b></p>
                                        <p class="MsoNormal"><span style="color:black">{summery} </span>
                                            <o:p></o:p>
                                        </p>
                                        <p class="MsoNormal"><b>
                                                <o:p>&nbsp;</o:p>
                                            </b></p>


                                        </b></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>




                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
</body></html>`;

export default emailTemplate;
