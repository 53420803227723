import React from "react";
import PopupBox from "../../../../commonui/popup/popup";
import CopyLink from "./copyLink";
import "./copyLink.css";

function CopyLinkPopUp(props) {
  return (
    <PopupBox
      title={false}
      isFooter={false}
      isBorderNeeded={false}
      isModalOpen={props.isModalOpen}
      hideModal={props.hideModal}
      className="copyLinkPopupStyle"
    >
      <CopyLink {...props} />
    </PopupBox>
  );
}

export default CopyLinkPopUp;
