import CommonChild from "../../commonui/commonlayout/commonChild";
import { UserGroupContainer } from "../usergroupscontainer/userGroupContainer";

function CustomUserGroups() {  
   
    let cardFetchObjTypeTwo = {
        //{"page":1,"pageSize":6,"filter":""}
        
          isFetching: false,
          hasMoreData: true,
          page: 1,
          pageSize: 6,
          filter:""      
        
      };
    return (
      
        <CommonChild id="/settings/customusergroups" subMenuName="User Groups">
          <UserGroupContainer id="/settings/customusergroups" name="User Groups" cardFetchObj={cardFetchObjTypeTwo} />
        </CommonChild>
       
      
    );
  }

export default CustomUserGroups;





