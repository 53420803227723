import React, { useEffect } from "react";
import { Image } from "@fluentui/react-northstar";
import { useNavigate } from "react-router-dom";
import { useSideNavbarDataContext } from "../../context/menucontext/menuContext";
import Constant from "../../utility/constants";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import "./logoutPage.css";

const LogoutPage = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { handleSideNavbarDataChange } = useSideNavbarDataContext();
  const nav = useNavigate();

  const handleLogin = () => {
    window.location.href = Constant.REDIRECTION_PATH;
  };

  return (
    <div className="logout-page">
      <Image
        alt=""
        src={require("../../assets/loggedout.png")}
        fit="cover"
        className="logout-page__image"
      />
      <div className="logout-page__end-session-msg">End Session</div>
      <div className="logout-page__logout-success-msg">
        You have successfully logged out of the application
      </div>
      <div
        className="logout-page__login-btn"
        onClick={() => {
          // nav(Constant.REDIRECTION_PATH);
          // handleSideNavbarDataChange("menu");
          handleLogin();
        }}
      >
        Go to login screen
      </div>
    </div>
  );
};

export default LogoutPage;
